import React from 'react';
import {Grid, Typography} from '@mui/material';
import { isMobile } from 'react-device-detect';


const Vision = () =>{

    return (
        <Grid item sx={{px: isMobile ? 2.5 : 12.5, py:'4em', backgroundColor:'#DAFAD8', width:'100%', display:'flex', justifyContent:'center'}}>
            <Grid item sx={{width:'100%', maxWidth:1440, px: !isMobile && 10}}>
                <Typography className='titleStyle title-color' sx={{}}>Our Vision</Typography>
                <Grid item className="expertise-column-reverse" xs={12} sx={{ pb: !isMobile && 3, display:'flex', mt: isMobile && '-4em'}}>
                    <Grid item xs={12} sm={10} sx={{ml:0, mt: isMobile && '-2em'}}>
                        <Typography className='normal-text subtitleStyle' sx={{ pt:'1.5em !important', pb: isMobile ? '1.5em !important' : '0px !important' }}>“Our vision is to become India’s leading single-window MEP (Mechanical, Electrical, and Plumbing) services provider, guided by a commitment to environmentally sustainable practices, business ethics, and reinforcement and strengthening of local sources and businesses.”</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{pt:'2em', textAlign: isMobile && 'right', mt: isMobile && '0em'}}>
                        <img src='/assets/images/visionbg.png' alt='' style={{width: isMobile &&'20%'}} />
                    </Grid>
                
                </Grid>
                
            </Grid>
        </Grid>
    )

}

export default Vision;