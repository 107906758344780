import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import Career from '../../components/career'

const CareerPage = () => {
    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])

    return (
        <Grid container style={{width:'100%'}}>
            <Banner img={'/assets/images/career-banner.jpg'} title='' />
            <Career />
        </Grid>
    )

}

export default CareerPage;