import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint'
import Testimonial from '../testimonial';
// import ProjectSlick from '../projects';
import FootPrint from '../../footprint';

const HvacService = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/goa-h.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/aiim-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>AIIMS, Jammu</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Vijaypur Samba Distt., Jammu & Kashmir</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/central-vista-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Central Vista, Common Central Secretariat</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>New Delhi</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/hq27-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>HQ27 Bharti Reality</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Gurugram, Haryana</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/iitbhilai-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>IIT, Bhilai</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Kutelabhata, Chhattisgarh</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/nalanda-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Nalanda University, Rajgir </Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Rajgir, Bihar</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/ssb-warangle-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>SSB Hospital,  Warangal</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Warangal, Telengana</Typography>
        </Grid>
    ]

    // const titleStyle = {fontSize:32, fontFamily:'Playfair Display', fontWeight:500, lineHeight:2}
   
return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item  className='title-div'
                // sx={{px:!isMobile ? 10 : 0, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px: !isMobile && 12.5, background:!isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography variant='body2' component={'p'} sx={{color:'#535353'}}>HVAC</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em', pb: !isMobile ? '8em' : '6em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2 }}>HVAC</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            At Unique Engineers, we stand as a beacon of expertise in the field of Air Conditioning. With two of our directors holding qualifications as HVAC specialists, our foundation is deeply rooted in knowledge and experience. Our journey has allowed us to master the intricacies of Air conditioning services across a spectrum of systems.
                            <br/><br/>
                            Our HVAC services have been implemented in diverse sectors, spanning industry segments like healthcare, hospitality, education, retail, and lifestyle. Our defining trait lies in our unwavering dedication to quality and our unwavering ability to meet project deadlines. Beyond being a service provider, we are committed to exceeding the expectations of our clients by designing innovative, energy-efficient solutions suitable to their individual needs.
                            <br/><br/>
                            What sets us apart
                        </Typography>
                        <ul>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Core strength in Air conditioning with our leaders being qualified HVAC specialists</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Expertise in various systems like water/air-cooled chillers, VRV systems, radiant systems, geothermal systems, and thermal storage</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Successful implementation in hospitals, hotels, airports, malls, luxury apartments, educational institutions</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Commitment to quality and on-time project completion, along with environmentally conscious practices.</li>
                        </ul> 
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px:!isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px:!isMobile ? 10 : 0, pt: '5em', py: !isMobile ? '5em' : '3em' }}>
                    <Grid item sx={{}}>
                        <Typography className='titleStyle title-color' sx={{ pb:'1em', textAlign: 'center'}}>Quality solutions across sectors</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb: !isMobile && '2em' }}>
                            <Grid item xs={4} sx={{}}>
                                
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="8642210061">9</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Airports <br/> ventilated</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="4612210038">4</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stadiums <br/> made comfortable </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="6642212862">6</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8641218062">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Hospitals <br/> air conditioned</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FootPrint title='Our projects' imageArray={slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            {/* <ProjectSlick /> */}
            {/* <Grid item sx={{width:'100%', backgroundColor:'#D8F2FF', ml:!isMobile ? 10 : 2.5, pr:0, py:'5em'}}> */}
                {/* <Grid item sx={{pl: !isMobile ? 12.5: 2}}> */}
                    {/* <Typography className='titleStyle title-color' sx={{ pb:3}}>Our projects</Typography> */}
                    
                    
                {/* </Grid> */}
            {/* </Grid> */}
            <Testimonial title={'Words of satisfaction'}  />
        </Grid>
    </Grid>
)

}

export default HvacService;