import { Grid, Typography } from '@mui/material';
import React, {useState} from 'react';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint';

const Leading = () => {

    const [ numberBool, setNumberBool ] = useState(false);
    const [ projectNum, setProjectNum ] = useState(false);
    const [ projectPeople, setProjectPeople ] = useState(false);

    return (
        <Grid item sx={{backgroundColor:'#F4F8FA', width:'100%', display:'flex', justifyContent:'center'}}>
            <Grid xs={12} item className='leading1-div' sx={{pt: !isMobile ? 12.5 : 6.25 , pb:!isMobile ? 10 : 5, width:'100%', 
                background: !isMobile ? 'url(/assets/images/leadingbg.png) no-repeat' : 'url(/assets/images/leadingbg-mobile.png) no-repeat', 
                backgroundPosition: !isMobile ? 'top right': 'bottom right'}}>
                <Grid item className='leading-div' sx={{maxWidth:1440, }}>
                    <Grid item xs={12} sm={4} md={5} lg={4} xl={4} className='leading-title' sx={{height: 135}}>
                        <Typography variant='h3' component={'h3'} className='titleStyle title-color'
                            sx={{mb:2}}>
                                Leading the way <br />in MEP Solutions</Typography>
                        {/* <Button variant='text' disableFocusRipple disableRipple sx={{pl:0}}>
                            <Typography variant='body2' component={'p'} className={'normal-text'} 
                                sx={{textTransform:'uppercase', lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                                Read More
                            </Typography>
                        </Button> 
                        <IconButton><img src='/assets/images/right-arrow.png' alt='right icon' /></IconButton>*/}
                    </Grid>
                    <Grid item xs={12} sm={8} md={7} lg={8} xl={8} sx={{ml:2, mr:2}} >
                        <Grid item xs={12} sm={6} sx={{display:'flex', height: !isMobile ? 135 : 115}}>
                            <Grid item xs={6}>
                                <Typography variant='body2' component='div' className='normal-text title-color leading-subtitle'>
                                    <Waypoint onEnter={()=> setNumberBool(true)} />
                                    { numberBool && <p className="numbers">
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--1" data-fake="8642519073">3</span>
                                        </span>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--2" data-fake="3252119170">3</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text'>Years</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='body2' component='div' className='normal-text title-color leading-subtitle'>
                                <Waypoint onEnter={()=> setProjectNum(true)} />
                                    { projectNum && <p className="numbers">
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--1" data-fake="3612549601">3</span>
                                        </span>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--2" data-fake="7253181772">7</span>
                                        </span>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--3" data-fake="7252119170">6</span>
                                        </span>
                                    </p>    
                                }
                                </Typography>
                                <Typography className='normal-text'>Projects</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{display:'flex', alignItems:'flex-start', height: !isMobile ? 135 : 125}}>
                            <Grid item xs={6}>
                                <Typography variant='body2' component='div' className='normal-text title-color leading-subtitle'>
                                <Waypoint onEnter={()=> setProjectPeople(true)} />
                                    { projectPeople && <p className="numbers">
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--1" data-fake="4016101918">5</span>
                                        </span>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--2" data-fake="0112827505">0</span>
                                        </span>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--3" data-fake="7252119170">0</span>
                                        </span>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--2" data-fake="+">+</span>
                                        </span>
                                        </p>    
                                    }
                                </Typography>
                                <Typography variant='body2' component={'p'} className='normal-text' sx={{}}>People</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='body2' component='p' className='normal-text numbers title-color leading-subtitle' sx={{padding: '0px 0px 8px'}}>1</Typography>
                                <Typography className='normal-text' sx={{padding: '5px 0', lineHeight:'1 !important '}}>Commitment to Deliver</Typography>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )

}

export default Leading;