import React from 'react';
import { Grid, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';


const Banner = () => {

return (
    <>{ !isMobile ?
    <Grid item 
        sx={{background:'url(/assets/images/banner-right.png) no-repeat', 
            width:'100%', backgroundPosition:'right center',}}>
        <Grid item xs={12} className='home-banner' sx={{}}>
            <Typography variant='h1' component={'h1'} 
                sx={{width:600, marginTop:'-2em', fontFamily:'Playfair Display', fontWeight:700, color:'#1D5A93'}} >Putting Life in Built-up Structures
            </Typography>
            
            {/* <Grid item sx={{mt:5, display:'flex', alignItems:'center'}}>
                <IconButton>
                <img src='/assets/images/right-arrow.png' alt='right icon' style={{transform:'rotate(180deg)'}} />
                </IconButton>
                <Typography className='normal-text' sx={{mx:3, fontSize:32, fontWeight:600, color:'#4F9A41'}}>/</Typography>
                <IconButton><img src='/assets/images/right-arrow.png' alt='right icon' /></IconButton>
            </Grid> */}
        </Grid>
    </Grid>
    :
    <Grid item sx={{ width:'100%', backgroundColor:'#F4F8FA', pt:'4em', pl:'1em'}}>
        <Grid item xs={12}>
            <Typography variant='h1' component={'h1'} 
                sx={{marginTop:'-1em', fontFamily:'Playfair Display', fontWeight:700, color:'#1D5A93'}} >Putting Life in Built-up Structures
            </Typography>
            
            {/* <Grid item sx={{mt:5, display:'flex', alignItems:'center'}}>
                <IconButton>
                <img src='/assets/images/right-arrow.png' alt='right icon' style={{transform:'rotate(180deg)'}} />
                </IconButton>
                <Typography className='normal-text' sx={{mx:3, fontSize:32, fontWeight:600, color:'#4F9A41'}}>/</Typography>
                <IconButton><img src='/assets/images/right-arrow.png' alt='right icon' /></IconButton>
            </Grid> */}
        </Grid>
        <Grid item xs={12}>
            <img src= "/assets/images/banner-right.png" alt="banner" style={{width:'100%'}} />
        </Grid>
    </Grid>
    }</>
)

}

export default Banner;