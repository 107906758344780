import React from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile} from 'react-device-detect';
import Slider from 'react-slick';

const SocialCommit = () => {

    const settings = {
        dots: true,
        arrows: false,
        className: 'center',
        centerMode: false,
        infinite: true,
        centerPadding: '50px',
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        swipeToSlide: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
              centerMode: false,
            },
          },
        ],
      };
    const slides = [
        <div>
            <Typography variant='body2' component={'p'} className='nomral-text what-text' sx={{pb:'1em'}} >
                Turning empathy into actions to create positive impact
            </Typography>
        </div>,
        <div>
            <Typography variant='body2' component={'p'} className='nomral-text what-text' sx={{pb:'1em'}} >
                Educating the girl child as a social mission
            </Typography>
        </div>,
        <div>
            <Typography variant='body2' component={'p'} className='nomral-text what-text' sx={{pb:'1em'}} >
                Committed to a green future
            </Typography>
        </div>,
        <div>
            <Typography variant='body2' component={'p'} className='nomral-text what-text' sx={{pb:'1em'}} >
                Support for our people beyond the workplace
            </Typography>
        </div>
    ]

    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
                <Grid item  className='title-div'
                    // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
                >
                    <Grid item sx={{px: !isMobile && 12.5, pt:'1em', pb: !isMobile ? '8em' : '4em'}}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                            <Link to={'/about'} className='normal-text title-color' style={{textDecoration:'none'}} >About Us</Link>
                            <Typography className="normal-text" sx={{color:'#535353'}}>Social Commitment</Typography>
                        </Breadcrumbs>
                        <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                            <Typography variant='body2' component={'h5'}  className='titleStyle title-color' sx={{pb:2}}>Social Commitment</Typography>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{}}>
                                <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:2, lineHeight:2}}>
                                    Compassion is the driving force behind our actions. It guides us to shape lives and make a meaningful change. Together, we forge a path of empowerment to create a more inclusive and prosperous tomorrow.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className='' sx={{}}>
                    
                </Grid>
                <Grid item className='what1-style' sx={{width:'100%', background: 'url(/assets/images/socialbg.jpg) no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}}>
                        <Grid item className='what-style' sx={{backgroundColor:'#DAFAD8', float:'right', pt:4.5, pb:10, px:5}}>
                            <Typography variant='h4' component={'h4'} className='titleStyle title-color' sx={{ mb:4}}>Empowering lives, shaping futures</Typography>
                            <Slider {...settings}> {slides} </Slider>
                        </Grid>
                </Grid>
                

                <Grid item sx={{width:'100%', pt:'2em', pb: !isMobile ? '4em' : '2em', display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{px:  !isMobile ? 22.5 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}>
                        <Grid item xs={12}>
                            <Typography className='titleStyle title-color' sx={{ pb:2, width:'80%'}}>Our CSR initiative</Typography>
                            <Typography className='normal-text' variant='body2' component={'p'} sx={{pb:'2em'}}>
                                Unique Engineers is dedicated to sustainable operations, minimizing environmental impact, and supporting staff and stakeholders' well-being and development.
                            </Typography>
                            <Typography className='normal-text' component={'p'} sx={{pb:0}}>
                                We are committed to implementing and promoting:
                            </Typography>
                            <ul style={{}}>
                                <li className='normal-text'>Use of energy-saving practices and energy-efficient lighting and appliances.</li>
                                <li className='normal-text'>Recycling and waste reduction programs within the company.</li>
                                <li className='normal-text'>Regular environmental impact assessments for continuous improvement.</li>
                                <li className='normal-text'>Partnerships with local community organisations and promotion of volunteer opportunities for our people.</li>
                                <li className='normal-text'>Support of education initiatives through internships, apprenticeships, and scholarships.</li>
                                <li className='normal-text'>Enforcement of a code of conduct for ethical manufacturing and fair labor practices.</li>
                                <li className='normal-text'>Maintaining transparency by communication of CSR initiatives and progress.</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{width:'100%', pb:'2em', display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{width:'100%', maxWidth:1440, px: !isMobile ? 10 : 2.5, py: !isMobile ? '1.5em' : '0em' }}>
                        <Grid item sx={{px: !isMobile ? 12.5 : 0}}>
                            <Grid item 
                                sx={{width:'100%', background:  !isMobile && 'url(/assets/images/social-line-patter.png) no-repeat', backgroundPosition:'bottom right', display:'flex', flexFlow:  !isMobile ? 'row': 'column-reverse', columnGap:'2em', py: !isMobile ? '2em' : 0, justifyContent:'space-between' }}>
                                <Grid item xs={12} sm={6} sx={{display:'flex', flexFlow:'column', mt: isMobile && '1em'}}>
                                    <Typography className='titleStyle title-color subtitleStyle' sx={{ pb: !isMobile ?  4 : 2}}>Beyond the workplace</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                        Beyond the workplace, we see our teams as part of our extended family and are committed to supporting them during significant life events. Whether it is a wedding or other milestones, we provide monetary assistance to ease their financial burdens. 
                                        <br />
                                        By fostering a close-knit community and providing personal support, we create a work environment that values each individual's well-being and happiness.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{marginLeft: !isMobile && '3em'}}>
                                    <Grid sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                                        <div style={{position:'relative', height: !isMobile ? '100%': 200, width:'100%'}}>
                                            <div className='img-wrapper'>
                                                <img src='/assets/images/social-content.png' alt='multifaced' style={{width:'100%'}} />
                                            </div>    
                                        </div>
                                    </Grid>
                                </Grid>    
                            </Grid>
                            <Grid item sx={{width:'100%', background:  !isMobile && 'url(/assets/images/social-line-patter.png) no-repeat', backgroundPosition:'bottom left', display:'flex', flexFlow: !isMobile ? 'row' : 'column', columnGap:'2em', py: !isMobile && '2em', pt: isMobile && '2em', justifyContent:'space-between' }}>
                                <Grid item xs={12} sm={6} sx={{marginRight:'3em'}}>
                                    <Grid sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                                        <div style={{position:'relative', height:!isMobile ? '100%': 200, width:'100%'}}>
                                            <div className='img-wrapper'>
                                                <img src='/assets/images/social-content1.png' alt='multifaced' style={{width:'100%'}} />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid> 
                                <Grid item xs={12} sm={6} sx={{display:'flex', flexFlow:'column', mt: isMobile && '1em'}}>
                                    <Typography className='titleStyle title-color subtitleStyle' sx={{ pb: isMobile ? 4 : 2}}>Investing in the girl child</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                        At Unique, we firmly believe that education is a key driver of progress and transformation. We recognize that education is a powerful tool in breaking the cycle of poverty and securing a brighter future. And thus, by investing in the girl child's education, we aim to create a more inclusive society. 
                                        <br />
                                        Through our endeavors, we aspire to create a society where every girl has the chance to thrive and realize her full potential.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sx={{width:'100%', background:  !isMobile && 'url(/assets/images/social-line-patter.png) no-repeat', backgroundPosition:'bottom right', display:'flex', flexFlow: !isMobile ?'row' : 'column-reverse', columnGap:'2em', pt: !isMobile ? '2em': '2em', pb: !isMobile ? '2em' : '1em' , justifyContent:'space-between' }}>
                                <Grid item xs={12} sm={6} sx={{display:'flex', flexFlow:'column', mt: isMobile && '1em'}}>
                                    <Typography className='titleStyle title-color subtitleStyle' sx={{ pb: isMobile ? 4 : 2}}>Strengthening local businesses</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                        We prioritize local sourcing to support and empower local businesses and communities. By leveraging state-of-the-art local manufacturing, we actively reinforce the country’s self-sufficiency and align with the Make in India vision. 
                                        <br />
                                        We also encourage community engagement through partnerships with local organizations, and offer volunteering opportunities to our employees.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{marginLeft: !isMobile && '3em'}}>
                                    <Grid sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                                        <div style={{position:'relative', height:!isMobile ? '100%': 200, width:'100%'}}>
                                            <div className='img-wrapper'>
                                                <img src='/assets/images/social-content2.png' alt='multifaced' style={{width:'100%'}} />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>    
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{width:'100%', backgroundColor:'#F4FAFA', py: !isMobile ? '5em' : '4em', display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{ width:'100%', maxWidth:1440, px: !isMobile ? 10 : 2.5,}}>
                        <Grid item sx={{ml:0, mr:0,px: !isMobile ? 12.5: 0,}}>
                            <Typography className='normal-text subtitleStyle' sx={{ pb:'1.5em' }}>Through empathy and compassion for our people, we aim to foster a positive and supportive working environment, both for clients and our people. We seek to create a workplace focused on people’s wellbeing and an ethical work culture, one that contributes to people and communities.</Typography>
                            <Typography className='quote-owner titleStyle text-color' > <span style={{fontWeight:'400 !important'}}> / </span>Pradeep Sharma</Typography>
                            <Typography className='quote-owner normal-text' sx={{ opacity:0.6 }}>Director</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SocialCommit;