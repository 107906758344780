import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Grid, Popper, Paper, Fade, MenuList, ClickAwayListener,  } from '@mui/material';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MobileHeaderMenu from './MobileHeaderMenu';

const Header = (props) => {
    // const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [aboutOpen, setAboutOpen] = useState(false);
    const [investorOpen, setInvestorOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const anchor1Ref = React.useRef(null);
    const anchor2Ref = React.useRef(null);

    const linkStyle={marginRight:'2em', textDecoration:'none'}
    
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    const prev1Open = React.useRef(aboutOpen);
    const prev2Open = React.useRef(investorOpen);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    useEffect(()=>{
        if (prev1Open.current === true && aboutOpen === false) {
            anchor1Ref.current.focus();
        }
        prev1Open.current = aboutOpen;
    }, [aboutOpen])
    useEffect(()=>{
        if (prev2Open.current === true && investorOpen === false) {
            anchor2Ref.current.focus();
        }
        prev2Open.current = investorOpen;
    }, [investorOpen])

    
    const callServiceMenu = () => {
        setOpen(true);
        setInvestorOpen(false);
        setAboutOpen(false);
    }
    const callAboutMenu = () => {
        setOpen(false);
        setInvestorOpen(false);
        setAboutOpen(true);
    }
    const callInvestorMenu = () => {
        setOpen(false);
        setAboutOpen(false);
        setInvestorOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        setAboutOpen(false);
        setInvestorOpen(false);
    }

    const aboutPopper = () => 
        <Popper
            open={aboutOpen}
            anchorEl={anchorRef.current}
            style={{zIndex:1}}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
            <Fade
                {...TransitionProps} timeout={350}
                style={{marginTop:'1.5em',
                zIndex:1,
                transformOrigin:
                    placement === 'bottom-start' ? 'center top' : 'left bottom',
                }}
            >
                <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <Grid item sx={{ width:'525px', maxWidth:'525px', 
                        display:'flex', justifyContent:'flex-start', 
                        padding:'2em 1em'}}
                        autoFocusItem={aboutOpen}
                        id="compositionabout-menu"
                        aria-labelledby="composition-button"
                    >
                        <Grid item xs={6} sx={{display:'flex', alignItems:'center', padding:'1.5em 1em'}}>
                            <img src='/assets/images/menuimg1.jpg' alt='menubg' style={{width:'185px'}} />
                        </Grid>
                        <Grid item xs={5} sx={{}}>
                            <MenuList>
                                <li style={{textAlign:'left', padding:'0.65em'}}>
                                    <Link to='/our-approach' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Our Approach</Link>
                                </li>
                                <li style={{textAlign:'left', padding:'0.65em'}}>
                                    <Link to='/leadership' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Leadership</Link>
                                </li>
                                <li style={{textAlign:'left', padding:'0.5em'}}>
                                    <Link to='/careers' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Careers</Link>
                                </li>
                                <li style={{textAlign:'left', padding:'0.65em'}}>
                                    <Link to='/social-commitment' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Social Commitment</Link>
                                </li>
                                
                            </MenuList>
                        </Grid>
                    </Grid>
                    
                </ClickAwayListener>
                </Paper>
            </Fade>
            )}
        </Popper>
    
    const investorPopper = () => 
        <Popper
            open={investorOpen}
            anchorEl={anchorRef.current}
            style={{zIndex:1}}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
            <Fade
                {...TransitionProps} timeout={350}
                style={{marginTop:'1.5em',
                zIndex:1,
                transformOrigin:
                    placement === 'bottom-start' ? 'center top' : 'left bottom',
                }}
            >
                <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <Grid item sx={{ width:'525px', maxWidth:'525px', 
                        display:'flex', justifyContent:'flex-start', 
                        padding:'2em 1em'}}
                        autoFocusItem={aboutOpen}
                        id="compositionabout-menu"
                        aria-labelledby="composition-button"
                    >
                        <Grid item xs={6} sx={{display:'flex', alignItems:'center', padding:'1.5em 1em'}}>
                            <img src='/assets/images/menuimg1.jpg' alt='menubg' style={{width:'185px'}} />
                        </Grid>
                        <Grid item xs={5} sx={{}}>
                            <MenuList>
                                <li style={{textAlign:'left', padding:'0.65em'}}>
                                    <Link to='/investors/name-change' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Name Change</Link>
                                </li>
                            </MenuList>
                        </Grid>
                    </Grid>
                    
                </ClickAwayListener>
                </Paper>
            </Fade>
            )}
        </Popper>

    const servicePopper = () => <Popper
    open={open}
    style={{zIndex:1}}
    anchorEl={anchorRef.current}
    role={undefined}
    placement="bottom-start"
    transition
    disablePortal
  >
    {({ TransitionProps, placement }) => (
      <Fade timeout={350}
        {...TransitionProps}
        style={{marginTop:'1.5em',
        zIndex:1,
          transformOrigin:
            placement === 'bottom-start' ? 'center top' : 'left bottom',
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose} style={{width:525}}>
            <Grid item sx={{ width:'525px', maxWidth:'525px', 
                display:'flex', justifyContent:'flex-start', 
                padding:'2em 1em'}}
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
            >
                <Grid item xs={6} sx={{display:'flex', alignItems:'center', padding:'1.5em 1em'}}>
                    <img src='/assets/images/menuimg.png' alt='menubg' style={{width:'185px'}} />
                </Grid>
                <Grid item xs={3} sx={{}}>
                    <MenuList>
                        <li style={{textAlign:'left', padding:'0.65em 0.5em'}}>
                            <Link to='/services/hvac' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>HVAC</Link>
                        </li>
                        <li style={{textAlign:'left', padding:'0.65em 0.5em'}}>
                            <Link to='/services/plumbing' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Plumbing</Link>
                        </li>
                        <li style={{textAlign:'left', padding:'0.65em 0.5em'}}>
                            <Link to='/services/ibms' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>IBMS / LV</Link>
                        </li>
                    </MenuList>
                </Grid>
                <Grid item xs={3} sx={{}}>
                    <MenuList>
                        <li style={{textAlign:'left', padding:'0.65em 0.5em'}}>
                            <Link to='/services/firefighting' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Firefighting</Link>
                        </li>
                        <li style={{textAlign:'left', padding:'0.65em 0.5em'}}>
                            <Link to='/services/electrical' className='link-color' style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Electrical</Link>
                        </li>
                </MenuList>
                </Grid>
            </Grid>
            
          </ClickAwayListener>
        </Paper>
      </Fade>
    )}
  </Popper>

    return (

        <Grid container sx={{backgroundColor:'#F4F8FA', justifyContent:'center'}} >
            <Grid item className='header-div' sx={{ maxWidth:1440, width:'100%'}} >
                <Grid item xs={12} className='header1-div'>
                    <Grid item xs={6} sm={2} md={2}>
                        <Link to={'/'}><img src='/assets/images/logo.png' alt='logo' /></Link>
                    </Grid>
                    <Grid item xs={6} sm={10} md={8} sx={{textAlign:'right'}}>
                      {isMobile ? (
                        <MobileHeaderMenu />
                        ) : <nav className='' style={{textAlign:'right', font:'Inter'}}>
                        <Link to={"/services"} ref={anchorRef}
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"                  
                            className='link-color' 
                            style={{...linkStyle, border:0, outline:'none'}} onMouseOver={callServiceMenu} >Services <ExpandMoreIcon style={{verticalAlign:'middle', transform: !open ? 'rotate(0deg)': 'rotate(180deg)', marginTop:2}} /> </Link>
                        {servicePopper()}
                        <Link to={"/projects"} className='link-color' style={linkStyle} onMouseOver={handleClose}>Projects</Link>
                        <Link to={"/products"} className='link-color' style={linkStyle} onMouseOver={handleClose}>Products</Link>
                        
                        <Link to={"/about"}
                            ref={anchor1Ref}
                            aria-controls={aboutOpen ? 'compositionabout-menu' : undefined}
                            aria-expanded={aboutOpen ? 'true' : undefined}
                            aria-haspopup="true"                  
                            className='link-color' style={{...linkStyle, border:0, outline:'none'}}
                            onMouseOver={callAboutMenu}
                            >About Us <ExpandMoreIcon style={{verticalAlign:'middle', transform: !aboutOpen ? 'rotate(0deg)': 'rotate(180deg)' , marginTop:2}} />
                        </Link>
                            {aboutPopper()}
                        
                        <Link to={"#"} 
                            ref={anchor2Ref}
                            aria-controls={investorOpen ? 'compositionabout-menu' : undefined}
                            aria-expanded={investorOpen ? 'true' : undefined}
                            aria-haspopup="true"                  
                            className='link-color' 
                            style={{...linkStyle, border:0, outline:'none'}}
                            onMouseOver={callInvestorMenu}
                        >Investors <ExpandMoreIcon style={{verticalAlign:'middle', transform: !aboutOpen ? 'rotate(0deg)': 'rotate(180deg)' , marginTop:2}} /></Link>
                        {investorPopper()}
                        <Link to={"/contact"} className='link-color' style={linkStyle} onMouseOver={handleClose}>Contact Us</Link>
                        </nav>
                    }
                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default Header;