import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import {useParams} from 'react-router-dom';
import Banner from '../../components/services/Banner';
import Service from '../../components/services'
import HvacService from '../../components/services/hvac';
import FireFighting from '../../components/services/firefighting';
import HtltService from '../../components/services/htlt';
import PlumbingService from '../../components/services/plumbing';
import IbmsService from '../../components/services/ibms';


const Services = () => {
    const {id} = useParams();
    const [serviceComponent, setServiceComponent] = useState(null);
    const [serviceBanner, setServiceBanner] = useState('servicebanner.png');
    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])

    useEffect(() => {
        let str = <Service />
        let bannerStr = 'servicebanner.jpg';
        switch (id) {
            case 'hvac':
                str = <HvacService />;
                bannerStr = 'hvac-banner.jpg';
                break;
            case 'firefighting':
                str = <FireFighting />
                bannerStr = 'firefighting-banner.jpg';
                break;
            case 'plumbing':
                str = <PlumbingService />
                bannerStr = 'plumbing-banner.jpg';
                break;
            case 'electrical':
                str = <HtltService />
                bannerStr = 'electrical-banner.jpg';
                break;
            case 'ibms':
                str = <IbmsService />
                bannerStr = 'ibms-banner.jpg';
                break;
            default:
                break;
        }
        setServiceComponent(str);
        setServiceBanner(bannerStr);
    }, [id])

    return (
        <Grid container style={{width:'100%', justifyContent:'center'}}>
            
            <Banner img={`/assets/images/${serviceBanner}`} title= 'React out and Connect' />
            {serviceComponent }
            
        </Grid>
    )

}

export default Services;