import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Grid, Typography, Button, Breadcrumbs, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';
import Axios from 'axios';


const Career = () => {

    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);
    const [careerData, setCareerData] = useState([]);
    const apiURL = 'https://beta.uniqueengineersltd.com/blog/wp-json/wp/v2';

    const settings = {
        dots: false,
        arrows: false,
        className: 'center',
        centerMode: false,
        infinite: true,
        centerPadding: '30px',
        slidesToShow: Math.min(2, 1),
        slidesToScroll: Math.min(1),
        swipeToSlide: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerMode: false,
            },
          },
        ],
      };

    const callCareer = useCallback(()=>{
        Axios.get(`${apiURL}/career`)
        .then(res => {
            const data = res.data;
            setCareerData(data);
            // console.log(data);
        })
    },[])
    useEffect(()=>{
        callCareer();
    },[callCareer])
    const qoutesArr = [
        'prateek-arora.jpg','madhur-malick.jpg', 'rupesh-yadav.jpg'
    ]
      const slides = [
            <>
                <Typography className='normal-text' sx={{pt:'0em !important', pb:'2em !important', fontSize: !isMobile? '1.50em !important': '1.25em !important' , lineHeight:'1.7em !important' }}>
                “I always wanted to work for an engineering company where I could get hands-on experience on multiple aspects of work, be able to serve various industry verticals. Unique Engineers provides me the opportunity to gain experience on multiple levels in various lines of services, through their many MEP projects spread all across the country.”
                </Typography>
                <Typography className='titleStyle text-color subtitleStyle' sx={{ fontFamily:'Inter !important', mt:'0.5em'}}> <span className='link-color' style={{fontWeight:'400 !important'}}> / </span>Prateek Arora</Typography>
                <Typography className='normal-text subtitleStyle' sx={{ opacity:0.6 }}>Sr. Project Manager (HVAC)</Typography>  
            </>,
            <>
                <Typography className='normal-text' sx={{pt:'0em !important', pb:'2em !important', fontSize: !isMobile? '1.50em !important': '1.25em !important' , lineHeight:'1.7em !important' }}>
                “Unique Engineers is a company where I get a good work experience and more importantly, I get a sense of family because of the support I have received in my personal life, as during my child’s education and during COVID-19 pandemic.”
                </Typography>
                <Typography className='titleStyle text-color subtitleStyle' sx={{ fontFamily:'Inter !important', mt:'0.5em'}}> <span className='link-color' style={{fontWeight:'400 !important'}}> / </span>Madhur Malik</Typography>
                <Typography className='normal-text subtitleStyle' sx={{ opacity:0.6 }}>GM Sales</Typography>  
            </>,
            <>
                <Typography className='normal-text' sx={{pt:'0em !important', pb:'2em !important', fontSize: !isMobile? '1.50em !important': '1.25em !important' , lineHeight:'1.7em !important' }}>
                    “Working with the Unique team reinforces the sense of family while giving me a professional edge in my engineering field. There is support and growth and a definite movement in the forward direction. I learn new things every day and it is a very satisfying experience.”
                </Typography>
                <Typography className='titleStyle text-color subtitleStyle' sx={{ fontFamily:'Inter !important', mt:'0.5em'}}> <span className='link-color' style={{fontWeight:'400 !important'}}> / </span>Rupesh Yadav</Typography>
                <Typography className='normal-text subtitleStyle' sx={{ opacity:0.6 }}>Sr. Project Coordinator</Typography>  
            </>
      ]

    //   ,
    //         <>
    //             <Typography className='normal-text' sx={{pt:'0em !important', pb:'2em !important', fontSize: !isMobile? '1.50em !important': '1.25em !important' , lineHeight:'1.7em !important' }}>
    //                 “The MEP industry is evolving at a fast rate today. Working with Unique Engineers helps me stay ahead of the rest and that is a great way to put me ahead in my career. Not just new technology and processes, I also get the opportunity to learn best industry practices as applicable to various projects in various verticals.”
    //             </Typography>
    //             <Typography className='titleStyle text-color subtitleStyle' sx={{ fontFamily:'Inter !important', mt:'0.5em'}}> <span className='link-color' style={{fontWeight:'400 !important'}}> / </span>Kishan Pandey</Typography>
    //             <Typography className='normal-text subtitleStyle' sx={{ opacity:0.6 }}>Sr. Project Manager (MEP)</Typography>  
    //         </>
    //   ,
    //         <>
    //             <Typography className='normal-text' sx={{pt:'0em !important', pb:'2em !important', fontSize: !isMobile? '1.50em !important': '1.25em !important' , lineHeight:'1.7em !important' }}>
    //                 “The atmosphere at Unique is friendly and inclusive, and I get plenty of opportunity to expand my field of knowledge, learn new things. Everyone is helpful and it’s a satisfying experience.”
    //             </Typography>
    //             <Typography className='titleStyle text-color subtitleStyle' sx={{ fontFamily:'Inter !important', mt:'0.5em'}}> <span className='link-color' style={{fontWeight:'400 !important'}}> / </span>Ashu Ahuja</Typography>
    //             <Typography className='normal-text subtitleStyle' sx={{ opacity:0.6 }}>Service Manager</Typography>  
    //         </>

    // const itemData = [
    //     {img:'careerimage1.jpg', title:'career image1', height:280},
    //     {img:'careerimage2.jpg', title:'career image2', height:150},
    //     {img:'careerimage3.jpg', title:'career image3', height:456}
    // ]
    
    const slidePrev = (e) => {
        console.log(sliderRef.current);
        e.preventDefault()
        let tempCurrentIndex = 0;
        if(currentIndex === 0){
            tempCurrentIndex = slides.length-1;
        } else {
            tempCurrentIndex = currentIndex-1;
        }
        setCurrentIndex(tempCurrentIndex);
        sliderRef.current.slickPrev();
    }
    const slideNext = (e) => {
        e.preventDefault();
        let tempCurrentIndex = 0;
        console.log('currentIndex', currentIndex, slides.length);
        if(currentIndex === slides.length-1){
            tempCurrentIndex = 0;
        } else {
            tempCurrentIndex = currentIndex+1;
        }
        setCurrentIndex(tempCurrentIndex);
        sliderRef.current.slickNext();
    }
    
    const handleNavigate = (pTitle) => {
        navigate(`/applyonline/${pTitle}`)
    }

    // const handleMail = () => {
    //     window.location = 'mailto:info@uniqueengineers.net';
    // }

    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
                <Grid item sx={{px: !isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}>
                    <Grid item sx={{px: !isMobile && 12.5, pt:'1em'}}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                            <Typography className="normal-text" sx={{color:'#535353'}}>Career</Typography>
                        </Breadcrumbs>
                        <Grid item xs={12} sx={{pt:'2em', pb: !isMobile && '2em'}}>
                            <Typography className='titleStyle title-color' sx={{fontSize:36, pb:2}}>Working with Unique Engineers</Typography>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{}}>
                                    <Typography className='normal-text' sx={{mb: !isMobile && 2, lineHeight:2}}>
                                        When you join us, you become part of a leading MEP services provider with over 35 years of industry experience. Here we value your ideas and listen carefully to encourage open communication, that empowers you to make meaningful decisions. Our company culture nurtures a sense of belonging and fosters collaboration and teamwork.
                                        <br/><br/>
                                        We are committed to investing in our employees' growth and development. We ensure that you have access to learning opportunities, training programs and resources to stay updated with the latest industry advancements. We also have initiatives to help you maintain a healthy work-life balance.
                                        <br/><br/>
                                        Join Unique Engineers and enjoy a fulfilling career in an empowering and inclusive setting. Nurture your well-being, and grow with us as we shape the future of engineering together.
                                    </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{width:'100%', px:!isMobile ? 10: 2.5, pt:'1em', pb:'1em', display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{px:!isMobile && 12.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt: !isMobile && '2em'}}>
                        <Grid item xs={12} sm={9} sx={{display:'flex', columnGap:'2em', rowGap:'2em', height: !isMobile && '456px', flexFlow: isMobile && 'column'}}>
                            <Grid item xs={12} sx={{}}>
                                <Grid item sx={{pt:'1em', pb:'1.5em' , px: !isMobile && '0em'}}>
                                    <Typography className='titleStyle title-color' sx={{ pb:2 }}>Our people</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:'1.8 !important'}}>At Unique Engineers, our people are the driving force behind our success and the embodiment of our values.</Typography>
                                </Grid>
                                <Grid item>
                                    <div style={{position:'relative', height:240, width: isMobile && 300}}>
                                        <div className='img-wrapper' style={{}}>
                                            <img src='/assets/images/ourpeople1.jpg' alt='people1' style={{width:'100%'}}/>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{display:'flex', flexFlow:'column', rowGap:'2em',maxWidth:!isMobile && '236px !important', width:236}}>
                                <Grid item > 
                                <div style={{position:'relative', height:280, width: isMobile && 300}}>
                                    <div className='img-wrapper' style={{}}>
                                    <img src='/assets/images/careerimage1.jpg' alt='' style={{width:'100%'}} />
                                    </div>
                                </div>
                                </Grid>
                                <Grid item > 
                                <div style={{position:'relative', height:150, width: isMobile && 300}}>
                                    <div className='img-wrapper' style={{}}>
                                    <img src='/assets/images/careerimage2.jpg' alt='' style={{width:'100%'}} />
                                    </div>
                                </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{maxWidth:!isMobile && '236px !important', width:236}}>
                                <Grid item >
                            <div style={{position:'relative', height:456, width: isMobile && 300}}>
                                    <div className='img-wrapper' style={{height:456}}>
                                <img src='/assets/images/careerimage3.jpg' alt='' style={{width:'100%', height:456}} />
                                </div>
                            </div>
                            </Grid>
                                {/* <ImageList variant="masonry" cols={2} gap={32} sx={{height: isMobile ? 320 : '100%'}}>
                                {itemData.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <div style={{position:'relative', height:item.height}}>
                                        <div className='img-wrapper'>
                                        <img
                                        src={`/assets/images/${item.img}`}
                                        srcSet={`/assets/images/${item.img}`}
                                        alt={item.title}
                                        loading="lazy"
                                        />
                                        </div>
                                        </div>
                                    </ImageListItem>
                                    ))
                                }
                                </ImageList> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{width:'100%', maxWidth:'100%', px: !isMobile ? 10: 2.5, pt:'1em', pb:'1em', display:'flex', justifyContent:'center' }}>
                    <Grid item sx={{px:!isMobile && 22.5, width:'100%', maxWidth:1440, pt:'2em'}}>
                        <Grid item xs={12}>
                            <Typography className='titleStyle title-color' sx={{ pb:2 }}>Our core values</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:'1em', lineHeight:2}} >Rooted in integrity, collaboration, and inclusivity, our values center around our people and their well-being, while inspiring us to continually raise the bar in engineering solutions. We have a collaborative and empowering work culture that gives you ownership in decision-making and opportunities for growth.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{px:!isMobile ? 10: 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'0.5em', pb: !isMobile ? '6em' : '4em'}}>
                    <Grid item xs={12} sx={{display:'flex', px:!isMobile && 12.5,columnGap:'1em', flexFlow: isMobile && 'column', rowGap:'1em'}}>
                        <Grid item xs={12} sm={4} sx={{backgroundColor:'#D8F2FF', padding:'2em'}}>
                            <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em !important', lineHeight:'1.4em !important', pb:'1em' }}>Fostering a community</Typography>
                            <Typography className='normal-text' sx={{mb:1, lineHeight:2}}>
                                We honour diverse backgrounds, experiences, and perspectives to create a healthy workplace where everyone feels valued.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{backgroundColor:'#D8F2FF', padding:'2em'}}>
                            <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em !important', lineHeight:'1.4em !important', pb:'1em' }}>Building trust & integrity</Typography>
                            <Typography className='normal-text' sx={{mb:1, lineHeight:2}}>
                                With honesty, transparency, and a strong ethical foundation, we build trust and forge enduring bonds with our teams, clients, and partners.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{backgroundColor:'#D8F2FF', padding:'2em'}}>
                            <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em !important', lineHeight:'1.4em !important', pb:'1em' }}>Nurturing people’s talent</Typography>
                            <Typography className='normal-text' sx={{mb:1, lineHeight:2}}>
                                We empower individuals to unlock their potential by pursuing continuous learning through mentorship and guidance.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{width:'100%', backgroundColor:'#F4FAFA', px:!isMobile ? 10: 2.5, py: !isMobile? '6em': '3em', display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{ px: !isMobile && 22.5, width:'100%', maxWidth:1440,  }}>
                        <Grid item sx={{display:'flex', columnGap:'2em', flexFlow: isMobile && 'column'}}>
                            <Grid item xs={12} sm={4} sx={{ml:0, mr:0,}}>
                                <div style={{position:'relative', height:'100%'}}>
                                    <div className='img-wrapper'>
                                        <img src={`/assets/images/${qoutesArr[currentIndex]}`} alt='' style={{width:'100%'}} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={{ml:0, mr:0,}}>
                                <Slider ref={sliderRef} {...settings}>{slides}</Slider>
                                <Grid item sx={{mt:2, display:'flex', alignItems:'center'}}>
                                    <IconButton onClick={slidePrev}>
                                        <img src='/assets/images/right-arrow.png' alt='right icon' style={{transform:'rotate(180deg)'}} />
                                    </IconButton>
                                    <Typography className='normal-text' sx={{mx:3, fontSize:32, fontWeight:600, color:'#4F9A41'}}>/</Typography>
                                    <IconButton onClick={slideNext}>
                                        <img src='/assets/images/right-arrow.png' alt='right icon' />
                                    </IconButton>
                                </Grid> 
                            
                            </Grid>
                            
                        </Grid>
                        
                    </Grid>
                </Grid>

                <Grid item sx={{width:'100%', height: !isMobile && '400px', background: !isMobile && 'url(/assets/images/careerbg.jpg) no-repeat', backgroundSize:'cover' , backgroundPosition:'center', py: isMobile ? '2em' : '4em' }}>
                    <Grid item xs={12} sm={8} xl={7} sx={{backgroundColor:'#FFFFFF', py: !isMobile ? 8: 4, px:!isMobile ? 5 : 2.5, textAlign: !isMobile && 'center', margin:'0 auto', transform: !isMobile && 'translateY(90%)'}}>
                        <Typography variant='h4' component={'h4'} className='titleStyle title-color' sx={{ lineHeight:1.7, mb:5}}>Come, join us on our journey</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text'>Discover a rewarding career with Unique Engineers. Our ongoing journey of delivering mega-sized projects across various sectors offers an exciting platform for talented professionals.</Typography>
                    </Grid>
                </Grid>
                {/** career data */}
                <Grid item sx={{width:'100%', maxWidth:1440, px: !isMobile ? 10: 2.5, py:!isMobile ? '6em':'0em', marginTop:!isMobile ? '7em' : 0,display:'flex', justifyContent:'center' }}>
                    <Grid item xs={12} sm={9} sx={{}}>
                        {careerData.map((career)=> {
                            
                            return (<Grid item sx={{border:'1px solid #DAE6EF', mb:'2em', p: !isMobile ? '2em' : '1em'}}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{fontSize:'1.25em !important', pb:'1em'}}>{career.title.rendered}</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:'2em'}} dangerouslySetInnerHTML={{ __html:career.content.rendered !== '' ? career.content.rendered : "No Data" }} ></Typography>
                            <Grid item sx={{display:'flex'}}>
                                <Button variant='text' className='btn' disableFocusRipple disableRipple 
                                    sx={{pl:0, width:'fit-content'}}
                                    onClick = {() => handleNavigate(career.slug)}
                                >
                                    <Typography variant='body2' component={'p'} className={'text-color'} 
                                        sx={{textTransform:'capitalize', fontFamily:'Inter',lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                                        Apply Now
                                    </Typography>
                                    <img src='/assets/images/right-arrow.png' alt='right icon' />
                                </Button>
                                
                            </Grid>
                        </Grid>
                        )})}
                        {/* <Grid item sx={{border:'1px solid #DAE6EF', mb:'2em', p: !isMobile ? '2em' : '1em'}}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{fontSize:'1.25em !important', pb:'1em'}}>Planning Engineer</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:'2em'}}>Minimum 4-5 years of experience required in MEP services planning. Familiarity with project scheduling and coordination is essential.</Typography>
                            <Grid item sx={{display:'flex'}}>
                                <Button variant='text' className='btn' disableFocusRipple disableRipple 
                                    sx={{pl:0, width:'fit-content'}}
                                    onClick = {() => handleNavigate('planning-engineer')}
                                >
                                    <Typography variant='body2' component={'p'} className={'text-color'} 
                                        sx={{textTransform:'capitalize', fontFamily:'Inter',lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                                        Apply Now
                                    </Typography>
                                    <img src='/assets/images/right-arrow.png' alt='right icon' />
                                </Button>
                                
                            </Grid>
                        </Grid>
                        <Grid item sx={{border:'1px solid #DAE6EF', mt:'2em', p: !isMobile ? '2em' : '1em'}}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{fontSize:'1.25em !important', pb:'1em'}}>Sales Engineer</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:'2em'}}>Minimum 4-5 years of experience in quoting for projects across all services. Exposure to design and build projects will be an added advantage. Proficiency in the latest relevant software is a must.</Typography>
                            <Grid item sx={{display:'flex'}}>
                                <Button variant='text' className='btn' disableFocusRipple disableRipple 
                                    sx={{pl:0, width:'fit-content'}} 
                                    onClick = {() => handleNavigate('sales-engineer')}
                                >
                                    <Typography variant='body2' component={'p'} className={'text-color'} 
                                        sx={{textTransform:'capitalize', fontFamily:'Inter',lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                                        Apply Now
                                    </Typography>
                                    <img src='/assets/images/right-arrow.png' alt='right icon' />
                                </Button>
                                
                            </Grid>
                        </Grid>
                        <Grid item sx={{border:'1px solid #DAE6EF', mt:'2em', p: !isMobile ? '2em' : '1em'}}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{fontSize:'1.25em !important', pb:'1em'}}>Project Engineer</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:'2em'}}>Seeking young and dynamic individuals with 4-5 years of experience in project management. Familiarity with Microsoft Projects will be an added advantage.</Typography>
                            <Grid item sx={{display:'flex'}}>
                                <Button variant='text' className='btn' disableFocusRipple disableRipple 
                                    sx={{pl:0, width:'fit-content'}}
                                    onClick = {() => handleNavigate('project-engineer')} 
                                >
                                    <Typography variant='body2' component={'p'} className={'text-color'} 
                                        sx={{textTransform:'capitalize', fontFamily:'Inter',lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                                        Apply Now
                                    </Typography>
                                    <img src='/assets/images/right-arrow.png' alt='right icon' />
                                </Button>
                                
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>

                <Grid item sx={{width:'100%', backgroundColor:'#F4F8FA',px:!isMobile ? 10: 2.5, py: !isMobile ? '4em': '3em', marginTop:'1em',display:'flex', justifyContent:'center' }}>
                    <Grid item sm={9} sx={{width:'100%', maxWidth:1440,display:'flex', justifyContent:'center'}}>
                        <Grid item sx={{p:'2em 0'}}>
                            <Grid item xs={12} sm={10}>
                                <Typography className='titleStyle title-color' sx={{ pb:'0.5em', ml:'5px'}}>Join us</Typography>
                                <Typography variant='body2' component={'p'} className='normal-text' sx={{ml:'5px',pb:'1em'}}>Submit your resume that showcases your qualifications and relevant experience. We look forward to hearing from you.</Typography>
                                <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{ mt: '1em', width:'fit-content', minWidth:0}} 
                                    onClick={()=> window.location = 'mailto:career@uelimited.com'} >
                                    <Typography variant='body2' component={'p'} className={'text-color'} 
                                        sx={{textTransform:'capitalize', fontFamily:'Inter',lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                                        Send Email
                                    </Typography>
                                    <img src='/assets/images/right-arrow.png' alt='right icon' />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Career;