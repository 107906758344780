import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint'
// import ProjectSlick from '../projects';
import FootPrint from '../../footprint';


import Testimonial from '../testimonial';


const PlumbingService = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/plumbing/camellia-p.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>DLF Camellias, Gurugram </Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Gurugram, Haryana </Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/plumbing/bits-p.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>BITSoM, Mumbai </Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Mumbai, Maharashtra </Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/plumbing/central-p.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Central Vista, Common Central Secretariat</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>New Delhi</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/plumbing/goa-p.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>
        
    ]
    
return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className='title-div'
                // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px:!isMobile && 12.5, background: !isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography sx={{color:'#535353'}}>Plumbing</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em',pb:'8em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{pb:2}}>Plumbing</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            Plumbing has largely been provided by the unorganized sector and can thus be of poor quality. Recognizing the challenges of this sector and its importance to customers, we envisioned instilling into it, our professionalism and distinctiveness. Our dedicated team of plumbing experts forms the backbone of our high-quality plumbing services.
                        <br/><br/>
                            With attention to detail and adherence to industry benchmarks, our experts ensure the flawless installation, maintenance and repair of plumbing systems within buildings. We acknowledge the pivotal role that efficient plumbing plays in the smooth operation of any project. We therefore prioritize precision in every aspect of our plumbing services. By offering a comprehensive suite of plumbing solutions including water supply systems, drainage systems, sanitary fixtures and water heating solutions, we eliminate the need for clients to engage multiple contractors or rely on unorganized service providers.
                            <br/><br/>
                            What sets us apart
                        </Typography>
                        <ul>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Dedicated team of plumbing experts ensuring precision and adherence to industry standards</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Eliminating the need for multiple contractors with a comprehensive plumbing solutions</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Incorporating modern techniques, high-quality materials, and advanced equipment for durability</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Commitment to professionalism, expertise, and client satisfaction in the plumbing sector.</li>
                        </ul> 
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px: !isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px: !isMobile ? 10 : 0, pt: '5em', py: !isMobile ? '5em' : '3em', }}>
                    <Grid item sx={{}}>
                        <Typography className='titleStyle title-color' sx={{ pb:'1em', textAlign: 'center'}}>Solutions for comfort</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb:'2em' }}>
                            <Grid item xs={6} sm={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color ' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="9642210061">9</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Airports provided <br/> with clean air</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="8642519073">2</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="5252119170">5</span>
                                        </span>
                                        
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Luxurious <br/> 5-star hotel spaces </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="4642519073">4</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stadiums made <br/> eminently comfortable</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <ProjectSlick /> */}
            <FootPrint title='Our projects' imageArray = {slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            <Testimonial title={'Words of satisfaction'}  />
        </Grid>
    </Grid>
)

}

export default PlumbingService;