import { Grid } from '@mui/material';
import React, { useEffect } from 'react';

// import Banner from '../../components/contactcomponents/banner';
import Banner from '../../components/products/Banner';
import ContactUs from '../../components/contactcomponents/';

const Contact = () => {

    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])


    return (
        <Grid container style={{width:'100%', justifyContent:'center'}}>
            <Banner img={'/assets/images/contactbannerimage.jpg'} title={'Bridging Technical Excellence and Human Wellbeing'} />
            <ContactUs />
        </Grid>
    )


}

export default Contact;