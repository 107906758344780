// import React, { useState, useRef } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';


import PdfViewer from "@lmtri/react-pdf-viewer"

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { isMobile } from 'react-device-detect';

const Investors = () => {

    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
                <Grid item className='title-div' sx={{pb:'5em'}}
                    // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em', pb:'5em'}}
                >
                    <Grid item sx={{px: !isMobile ? 12.5 : 0}}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                            <Typography sx={{color:'#535353'}}>Investors / Name Change</Typography>
                        </Breadcrumbs>

                        <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                            <Typography className='titleStyle title-color' sx={{ fontSize: !isMobile ? '2em !important' : '1.5em !important', pb:2}}>Name Change</Typography>
                            <Typography sx={{mb:4, lineHeight:2}}>
                                Unique Engineers Pvt Ltd is now Unique Engineers Limited. While our name has evolved, our unwavering commitment to excellence remains. We are excited for what the future holds!
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container sx={{maxWidth: 700, height:800}}>
                <PdfViewer
                    src= {"/assets/pdf/name-conversion.pdf"}
                    renderAnnotationLayer={true}
                    renderTextLayer={true}
                    
                />
                </Grid>

            </Grid>
        </Grid>
    )

}

export default Investors;