import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import CaseStudies from '../../components/casestudy'

const CaseStudy = () => {
    const [selectedBanner, setSelectedBanner] = useState(0)
    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])

    const bannerArr = [
        {name:'aiimsjammu', image:'casestudybanner.png'},
        {name:'nalandauniversity', image:'casestudybanner-nalanda.jpg'},
        {name:'mopa-goa', image:'casestudybanner-mopa.jpg'}
    ]

    const handleBanner = (pValue) => {
        setSelectedBanner(pValue);
    }


    return (
        <Grid container style={{width:'100%'}}>
            <Banner img={`/assets/images/${bannerArr[selectedBanner].image}`} title='' />
            <CaseStudies handleBanner = {handleBanner} />
        </Grid>
    )

}

export default CaseStudy;