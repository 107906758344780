import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {isMobile} from 'react-device-detect'

const Infocus = () => {

    const navigate = useNavigate();
    
    return (
        <Grid item sx={{width:'100%', backgroundColor:'#F4F8FA', display:'flex', justifyContent:'center'}}>
            <Grid item className="infocus-div" > 
                <Grid item className="infocus1-div" sx={{px:10}}>
                    <Typography className='titleStyle title-color' sx={{ pb:2}}>In focus</Typography>
                    <Grid item className="infocus-flex">
                        <Grid item xs={12} sm={5} md={7} lg={7}>
                            <Typography className='normal-text' sx={{mb:2}}>
                                Through innovative solutioning, we have ensured that the MOPA International Airport not only meets the requirements for both plumbing and firefighting, but goes above and beyond to incorporate sustainable practices that align with LEED certification standards. The condensate drain collection system exemplifies our dedication to creating environmentally responsible infrastructure that mitigates the environmental impact of water usage in the airport facility.
                            </Typography>
                            <Typography className='normal-text' sx={{mb:2}}> MOPA International Airport, Goa 
                            <br />Year:  2022
                            {/* <br />1987 metric ton  */}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7} lg={7} sx={{textAlign:'right', ml:1, position:'relative'}}>
                            <div style={{position:'relative', height: !isMobile ? '100%' : 200}}>
                                <div className='img-wrapper' style={{}}>
                                    <img src='/assets/images/infocus.png' alt='' style={{marginBottom:'1em', width:'100%', }} />
                                </div>
                            </div>
                            <Grid item sx={{display:'flex', justifyContent:'flex-start'}}>
                                <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{  ml:-1, width:'fit-content', mt:2}}  onClick={()=> navigate('/case-study')} >
                                    <Typography variant='bod2' component={'p'} className={'normal-text text-color'} 
                                        sx={{textTransform:'capitalize', color:'#4F9A41 !important'}}>
                                        Read case study
                                    </Typography>
                                    <img src='/assets/images/right-arrow.png' alt='right icon' />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default Infocus;