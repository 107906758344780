import { Grid, Typography, Paper, Button, Breadcrumbs } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { isMobile } from 'react-device-detect';
import { validateEmail } from '../../utils/util';
import Phone from '@mui/icons-material/Phone';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Mail from '@mui/icons-material/Mail';

const ContactUs = () => {

    const [userInform, setUserInform] = useState({
        username: '',
        usermail: '',
        userphone: '',
        message: ''
    })
    const [status, setStatus] = useState(false);
    const [validate, setValidate] = useState({
        bool: false,
        msg: ''
    });

    const callUser = (event) =>{
        event.preventDefault();
        const {id, value} = event.target;
        // console.log(id, value);
        const tempUserInform = {...userInform};
        tempUserInform[id] = value
        setUserInform(tempUserInform);
        setValidate({bool:false, msg:''});
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log('userInform ', userInform);
        setStatus(false);
        const {username, userphone, usermail, message} = userInform;
        if(username.trim() === '' ) {
            setValidate({bool:true, msg:'Write full name.'}); 
            return;
        }
        
        if(userphone.trim() === '') {setValidate({bool:true, msg:'Write correct phone number.'}); return;}
        if(usermail.trim() === '' || !validateEmail(usermail) ) {setValidate({bool:true, msg:'Write correct email.'}); return;}

        Axios.post('sendmail.php', {name:username, phone:userphone, email:usermail, message})
        .then(res => console.log('res ', res))
        .catch(error => console.log('error ', error))
        // .error(error => console.log('error ', error))



        setStatus(true);
    }
    const inputStyle = {
        fontFamily:'Inter',
        fontSize:'1em',
        color:'',
        backgroundColor:'#F3F3F3',
        border:'unset',
        height:'3em',
        width:'100%',
        padding: '1em',
        marginBottom: '1.5em'
    }
    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
                <Grid item sx={{px: !isMobile ? 10: 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em', pb: !isMobile ? '5em' : '2em'}}>
                    <Grid item sx={{px: !isMobile ? 12.5 : 0, pt:'1.5em', pb: !isMobile && '2em'}}>
                        <Breadcrumbs aria-label="breadcrumb">
                                <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                                <Typography sx={{color:'#535353'}}>Contact Us</Typography>
                            </Breadcrumbs>
                        <Grid item xs={12} sx={{pt:'2em', pb: !isMobile ? '2em' : '1em'}}>
                            <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2}}>Connect with us to explore opportunities</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:2}}> 
                                We believe that effective communication is the key to building strong relationships. So, go on, call us or drop an email. Let's connect today and explore opportunities together. We look forward to hearing from you!
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{pl: !isMobile ? 22.5 : 1.5, pr: !isMobile ? '4em' : '0.25em', pt:'2em', pb:'5em', width:'100%', backgroundColor:'#F4F8FA', display:'flex', justifyContent:'center'}}>
                   <Grid item className='contact-details'>
                        <Grid item xs={12} sm={6}>
                            <Grid item sx={{py:'1.5em'}}>
                                <Typography variant='h4' component={'h4'} className="titleStyle text-color" style={{fontSize:'1.5em', paddingBottom:"0.5em"}}
                                    // sx={{ fontFamily:'Playfair Display', fontSize:'1.8em', fontWeight:500, color:'#1D5A93', pb:'0.7em'}}
                                >Get in touch</Typography>
                                <Grid item sx={{pb: !isMobile && '2em', display:'flex', flexDirection:isMobile && 'column', alignItems: !isMobile && 'center'}}>
                                    <Typography className='normal-text' >
                                        <Phone  style={{marginBottom:-7}} />+91-124-4192527/28/29 
                                    </Typography>
                                    <Typography className='normal-text' style={{marginLeft: !isMobile && 15}}><Mail style={{marginBottom:-7}} /> info@uelimited.com</Typography>
                                </Grid>    
                            </Grid>
                            
                            <Grid item sx={{py:'1.5em'}}>
                                <Typography variant='h4' component={'h4'} className="titleStyle text-color" style={{fontSize:'1.5em', paddingBottom:"0.5em"}}
                                    // sx={{ fontFamily:'Playfair Display', fontSize:'1.8em', fontWeight:500, color:'#1D5A93', pb:'0.7em'}}
                                >Reach us</Typography>
                                <Typography className='normal-text' sx={{ fontSize:'1em', pb: !isMobile && '2em'}}>
                                    <span style={{fontSize:'1.25em'}}>Head Office</span> <br/> 
                                332, 3rd Floor, A-Block, Spaze IT Park,
                                <br /> Sohna Road, Sec-49, Gurugram, Haryana, India 122001
                                </Typography>
                                <Grid item sx={{display:'flex', justifyContent:'flex-start'}}>
                                    <Typography variant='bod2' component={'p'} className={'normal-text text-color'} 
                                        sx={{textTransform:'capitalize', }}>
                                        Location map: <a className="text-color" style={{textTransform:'lowercase', textDecoration:'none',color:'#4F9A41'}} href='https://tinyl.io/9EKT' target='_blank' rel="noreferrer"> https://tinyl.io/9EKT</a>  
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sx={{py:'1.5em'}}>
                                <Typography variant='h4' component={'h4'} className="subtitleStyle text-color" sx={{fontSize:'1.5em', pb:'0.5em'}}
                                    // sx={{ fontFamily:'Playfair Display', fontSize:'1.8em', fontWeight:500, color:'#1D5A93', pb:'0.7em'}}
                                >Be a part of Unique team</Typography>
                                <Link to={'/careers'} className='normal-text' style={{textDecoration:'none',color:'#4F9A41'}}>Explore career opportunities <ArrowForwardIcon sx={{marginLeft: 2, marginBottom:'-5px', fontSize:'20px', color:'#4F9A41'}} /></Link>
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Grid item sx={{marginTop: isMobile ? '0em': '-6em', float:'right'}} >
                                <Paper sx={{padding: !isMobile ? '3em' : '1em'}}>
                                    <Typography variant='h4' component={'h4'} className='titleStyle title-color' sx={{ pb: !isMobile ? '1.5em' : '1em'}}>Your details</Typography>
                                    <form>
                                        {!status && <><input type='text' id='username' name='username' value={userInform.username} placeholder='Full Name' onChange={callUser} style={inputStyle} />
                                        <input type='text' id='usermail' name='usermail' value={userInform.usermail} placeholder='Email Id' onChange={callUser}  style={inputStyle} />
                                        <input type='number' id='userphone' name='userphone' pattern='^[0-9]+$' value={userInform.userphone} placeholder='Phone Number' onChange={callUser}  style={inputStyle}/>
                                        <textarea rows='5'  id='message' name='message' value={userInform.message} placeholder='Message' onChange={callUser}  style={{...inputStyle, resize:'none', height:'auto'}} />
                                        </>}
                                        {validate.bool && <Typography variant='body1' component={'p'} className='normal-text text-color' sx={{color:'red'}} >{validate.msg}</Typography>}
                                        {status && <Typography variant='body2' component={'h4'} className='normal-text text-color' sx={{fontSize:2}} >Thank you, your mail has been sent. We will be in touch shortly.</Typography>}
                                        {!status && <Grid item sx={{display:'flex', columnGap:'0.5em', alignItems:'center', justifyContent:'flex-end', width:'100%'}}>
                                            <Button variant='contained' className='normal-text' 
                                                sx={{backgroundColor:'#1D5A93', letterSpacing:'3px', color:'#FFFFFF !important', fontSize:'0.9em !important' }}
                                                onClick={handleSubmit}
                                            >Send</Button>
                                        </Grid>
                                        }
                                    </form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item sx={{px:22.5, width:'100%'}}>
                    <img src='/assets/images/locationmap.png' alt='location' style={{marginTop:'-5em'}} />
                </Grid> */}
            </Grid>
        </Grid>
    )


}

export default ContactUs;