import React, { useState, useRef } from 'react';
import { Grid, Typography, Breadcrumbs, Card, CardContent, Box, Modal, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Clients from '../clients';
import Infocus from '../../infocus';
import { isMobile } from 'react-device-detect';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {} from './projects.css';


const Project = () => {

    const [modalData, setModalData] = useState("");
    const [selectedService, setSelectedService] = useState('services');
    const [selectedVertical, setSelectedVertical] = useState('unread');
    const [selectedStatus, setSelectedStatus] = useState('-2');

    const sliderRef = useRef(null);

    const settings = {
      dots: false,
      arrows: true,
      className: 'center',
      centerMode: false,
      infinite: false,
    //   width:720,
      height:360,
      centerPadding: '30px',
      slidesToShow: Math.min(2, 1),
      slidesToScroll: Math.min(1),
      swipeToSlide: false,
      speed: 500,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            height:200,
            centerMode: false,
          },
        },
      ],
    };
    const imgPath = '/assets/images/project_image';

    const projectData = [
        {
            titleName:'Dehradun Airport',
            fullName: 'Dehradun Airport, ',
            status: 'completed',
            year:'2024',
            overview: 'The newly constructed terminal spans 42,776 square meters & was completed in February, 2024',
            capacity: '--',
            building: 'Comprise of 2140-meter runway, an apron accommodating 20 parking spots, 4 conveyor belts, 12 baggage X-ray machines, 48 check-in counters, and 500 parking spots.',
            rating: 'GRIHA-4 star rated terminal',
            designConcept: '--',
            vertical: 'airport',
            services: 'hvac, firefighting, electrical, plumbing',
            location:'Dehradun, UP',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/dehradun/frame-1.png`} />,
                <img alt="" src = {`${imgPath}/dehradun/frame-2.png`} />,
                <img alt="" src = {`${imgPath}/dehradun/frame-3.png`} />,
                <img alt="" src = {`${imgPath}/dehradun/frame-4.png`} />
            ],
            thumbImage: 'dehradun-thumb.png',
        },
        {
            titleName:'AIIMS, Jammu',
            fullName: 'AIIMS, Jammu',
            status: 'ongoing',
            year:'--',
            overview: '258 Acre / 45 Crore / Hospital',
            capacity: 'HVAC: Water cooled Chiller system 2800 TR. <br/> IBMS: System integration of  HVAC , PHE, Electrical & ELV having 19000 IO point .',
            building: '5 Hospital towers, Blocks: Academic, Auditorium, Ayush, Dining & Guest House.',
            rating: 'Platinum Level Pre-certification from IGBC.',
            designConcept: 'BIM 3D Concept / 2D Execution ',
            vertical: 'healthcare',
            services: 'hvac, ibms',
            location:'Jammu',
            content: '',
            imgData: [
                <img alt="" src= {`${imgPath}/aiims-jammu/frame-1.jpg`} style={{width:720, maxWidth:720, height:360, maxHeight:360}} />, 
                <img alt="" src = {`${imgPath}/aiims-jammu/frame-2.jpg`} style={{width:720, maxWidth:720, height:360, maxHeight:360}} />
                ],
            thumbImage: 'aiims-thumb.png',
        },
        {
            titleName:'IIT, Bhilai',
            fullName: 'IIT, ',
            status: 'completed',
            year:'2023',
            overview: '447 Acre / 40 Crore / Educational / Completed 2023',
            capacity: 'Water cooled Chilled water system 1600 TR & VRF 675 HP.',
            building: 'Blocks: Lecture hall, Academic & Data Centre, Utility, Hostel Blocks, Club & Director residence.',
            rating: '5-Star GRIHA Approved.',
            designConcept: 'BIM 3D Concept / 2D Execution',
            vertical: 'education',
            services: 'hvac',
            location:'Kutelabhata, Chhattisgarh',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/iit-bhilai/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/iit-bhilai/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/iit-bhilai/frame-3.jpg`} />
            ],
            thumbImage: 'iit-bhilai-thumb.png',
        },
        {
            titleName:'HQ27 Bharti Reality, Gurugram, Haryana',
            fullName: 'HQ27 Bharti Reality, ',
            status: 'completed',
            year:'2023',
            overview: '12.8 Acre (5,60,000 Sq. Ft) / 25 Crore / Office cum Retail  / Completed 2023',
            capacity: 'Water cooled Chilled water system 1200TR',
            building: '4 Basements, Floors: G + 16',
            rating: 'LEED Gold certified Green Commercial Building.',
            designConcept: 'CAD Designing / 2D Execution',
            vertical: 'retails',
            services: 'hvac',
            location:'Haryana',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/hq27-bharti-reality/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/hq27-bharti-reality/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/hq27-bharti-reality/frame-3.jpg`} />
            ],
            thumbImage: 'hq27-bharti-reality-thumb.png',
        },
        {
            titleName:'Sky Mansion, New Delhi ',
            fullName: 'Sky Mansion, ',
            status: 'ongoing',
            year:'--',
            overview: '10 Lakh Sq. Ft / Residential',
            capacity: '11KV sub-station, 2 trans. 1000KVA, 2 trans. 1010KVA, & Int. & ext. electrical works.',
            building: '5 Towers: 23 floors each.',
            rating: 'Platinum Level Pre-certification from IGBC.',
            designConcept: '2D Execution',
            vertical: 'residential',
            services: 'electrical',
            location:' Chattarpur, New Delhi',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/sky-mansion/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/sky-mansion/frame-2.jpg`} />
            ],
            thumbImage: 'sky-mansion-thumb.png',
        },
        {
            titleName:'Executive Enclave , PMO House, New Delhi',
            fullName: 'Executive Enclave , PMO House',
            status: 'ongoing',
            year:'--',
            overview: '21 Acre  (9,46,309 Sq. Ft) / 10 Crore / Residential',
            capacity: '3 Fire Plant rooms , Internal & INFRA fire-Fighting work',
            building: 'PMO House, NSCS, CS, Basement, Floors: G + 3',
            rating: 'Gold Rated.',
            designConcept: 'BIM 3D Concept / CAD Designing / 2D Execution',
            vertical: 'residential',
            services: 'firefighting, plumbing',
            location:'New Delhi',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/exec-enc-pmo/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/exec-enc-pmo/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/exec-enc-pmo/frame-3.jpg`} />,
                <img alt="" src = {`${imgPath}/exec-enc-pmo/frame-4.jpg`} />
            ],
            thumbImage: 'exec-enc-pmo-thumb.png',
        },
        {
            titleName:'MOPA International Airport, Goa ',
            fullName: 'MOPA International Airport, North Goa ',
            status: 'completed',
            year:'2023',
            overview: '2000 Acre / 130 Crore / Airport / Completed 2023',
            capacity: 'HVAC: Water cooled Chilled water system 2100 TR & VRF. <br/>Firefighting: 1 Fire Plant room.<br/>Electrical: ELV int. & ext. building works.<br />Plumbing: Water treatment plant & Soil/solid waste piping.',
            building: 'PTB, ATC Tower, WTP and other building Like AGLE, AGLW, utility, CFRM, CFRS, MRSS, GLATH PATH, GATE HOUSE SSS, DOVR &localizer.',
            rating: 'Platinum Level Pre-certification from IGBC.',
            designConcept: 'BIM 3D Concept / 2D Execution / MEPF 3D BIM Co-ordination',
            vertical: 'airport',
            services: 'hvac, firefighting, electrical, plumbing',
            location:'Goa',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/mopa-goa/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/mopa-goa/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/mopa-goa/frame-3.jpg`} />,
                <img alt="" src = {`${imgPath}/mopa-goa/frame-4.jpg`} />,
                <img alt="" src = {`${imgPath}/mopa-goa/frame-5.jpg`} />
            ],
            thumbImage: 'airport-goa-thumb.png',
        },
        {
            titleName:'Nalanda University, Rajgir',
            fullName:'Nalanda University, Rajgir, Bihar',
            status: 'ongoing',
            year:'--',
            overview: '455 Acre / 68 Crore / Educational',
            capacity: 'HVAC: Water cooled Chilled water system 1500 TR,  Water cooled VRF 2300 TR.<br/>IBMS: System integration of  HVAC  with SCADA having 13000 IO point .',
            building: 'Faculty Housing, Student Hostel & Dining, Academic Spine, Out-reach, NU School & Amenities.',
            rating: 'HVAC Cutting Edge to Net Zero Approach: Geo-thermal Energy, Solar Air-conditioning @day & Thermal Expansion backup @night, Micro climate, Modular power solution, Close cycle operation, Water exchange.',
            designConcept: 'BIM 3D Concept / 2D Execution ',
            vertical: 'education',
            services: 'hvac, ibms',
            location:'Bihar',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/nalanda-uni/frame-1.jpg`}  />,
                <img alt="" src = {`${imgPath}/nalanda-uni/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/nalanda-uni/frame-3.jpg`} />,
                <img alt="" src = {`${imgPath}/nalanda-uni/frame-4.jpg`} />,
                <img alt="" src = {`${imgPath}/nalanda-uni/frame-5.jpg`} />
            ],
            thumbImage: 'nalanda-thumb.png',
        },
        {
            titleName:'STT, Mumbai',
            fullName:'STT, ',
            status: 'ongoing',
            year:'--',
            overview: '7.6 Acre / Data center',
            capacity: 'IT Load: 24 MW per phase/48 MW overall, Air cooled Chiller system and VRF.            ',
            building: 'Two Data centers, G+7 level (additional terrace), Annex and Substation.',
            rating: 'Silver or Higher LEED Certification for HVAC System.',
            designConcept: 'BIM 3D Concept / 2D Execution',
            vertical: 'datacenter',
            services: 'hvac',
            location:'Maharastra',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/stt/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/stt/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/stt/frame-3.jpg`} />
            ],
            thumbImage: 'stt-thumb.png',
        },
        {
            titleName:'BITSoM, Mumbai',
            fullName: 'BITSoM, ',
            status: 'ongoing',
            year:'--',
            overview: '60 Acre / 45 Crore / Educational',
            capacity: 'Firefighting: 2 Fire Plant rooms (UGT-1 & UGT-2).',
            building: 'COE, Academic, Executive Faculty, Hostels, Dining, UGT-1&2, HVAC Plant Room.',
            rating: 'Zero-carbon footprint campus.',
            designConcept: 'CAD Designing / 2D Execution',
            vertical: 'education',
            services: 'firefighting, plumbing',
            location:'Mumbai, Maharastra',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/BITSoM/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/BITSoM/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/BITSoM/frame-3.jpg`} />
            ],
            thumbImage: 'BITSoM-thumb.png',
        },
        {
            titleName:'ATC Tower, Kolkata Airport',
            fullName: 'ATC Tower, Kolkata Airport, Kolkata, West Bengal',
            status: 'ongoing',
            year:'--',
            overview: '5 Lakh Sq. Ft / 50 Crore / Airport',
            capacity: 'Electrical: 33KV sub-station, 2 trans. 2500KVA, 3 DG sets 2000KVA & int. & ext. electrical works. <br/>IBMS: system integration of   Electrical & ELV having 10000 IO point.',
            building: 'Technical & Tower blocks. ',
            rating: 'Platinum Level Pre-certification from IGBC.',
            designConcept: 'BIM 3D Concept / 2D Execution',
            vertical: 'airport',
            services: 'electrical, ibms',
            location:'West Bengal',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/atc-kolkata/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/atc-kolkata/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/atc-kolkata/frame-3.jpg`} />
            ],
            thumbImage: 'atc-kolkata-thumb.png',
        },
        {
            titleName:'ESIC Medical College & Hospital, Bihta',
            fullName: 'ESIC Medical College & Hospital, ',
            status: 'completed',
            year:'2019',
            overview: '50 Acre / Hospital & Medical College / Completed 2019',
            capacity: '33KV sub-station, 3 trans. 2000 KVA, 2 sub-stations 11KV, 8 trans. 1600 KVA, 12 sets 1250 KVA & 2 sets 1010 KVA. ',
            building: 'Six buildings.',
            rating: 'Gold Rated.',
            designConcept: '2D Execution',
            vertical: 'healthcare',
            services: 'electrical',
            location:'Bihta, Patna, Bihar',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/esic-bihta/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/esic-bihta/frame-2.jpg`} />
            ],
            thumbImage: 'esic-bihta-thumb.png',
        },
        {
            titleName:'SSB Hospital,  Warangal',
            fullName: 'SSB Hospital, ',
            status: 'ongoing',
            year:'--',
            overview: '48 Acre / Hospital',
            capacity: '500Tr X 3 nos., VRF 146 HP.',
            building: 'Two buildings: Hospital and Utility. ',
            rating: 'Siver Rated.',
            designConcept: 'BIM 3D Concept / 2D Execution',
            vertical: 'healthcare',
            services: 'hvac',
            location:'Warangal, Telengana',
            content: '',
            imgData: [
                // <img alt="" src = {`${imgPath}/ssb-warangal/frame-1.jpg`} />,
                // <img alt="" src = {`${imgPath}/ssb-warangal/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/ssb-warangal/frame-3.jpg`} />
                // <img alt="" src = {`${imgPath}/ssb-warangal/frame-4.jpg`} />
            ],
            thumbImage: 'ssb-warangal-thumb.png',
        },
        {
            titleName:'Central Vista, Common Central Secretariat, New Delhi',
            fullName: 'Central Vista, Common Central Secretariat, ',
            status: 'ongoing',
            year:'--',
            overview: '18 Acre / Government',
            capacity: 'Chiller 1000 Tr x 12 nos.',
            building: 'Three buildings.',
            rating: 'Gold Rated.',
            designConcept: 'BIM 3D Concept / 2D Execution',
            vertical: 'retail',
            services: 'hvac',
            location:'New Delhi',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/central -vista/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/central -vista/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/central -vista/frame-3.jpg`} />,
                <img alt="" src = {`${imgPath}/central -vista/frame-4.jpg`} />
            ],
            thumbImage: 'central-vista-thumb.png',
        },
        {
            titleName:'SCB Hospital, Cuttack ',
            fullName: 'SCB Hospital, ',
            status: 'ongoing',
            year:'--',
            overview: '136 Acre / Hospital',
            capacity: 'Chiller 1300 Tr x 7 nos. & VRF 134 HP. ',
            building: 'Six buildings.',
            rating: 'Gold Rated.',
            designConcept: 'BIM 3D Concept / 2D Execution',
            vertical: 'healthcare',
            services: 'hvac',
            location:'Cuttack, Odisha',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/scb-hospital-cuttack/frame-1.png`} />,
                <img alt="" src = {`${imgPath}/scb-hospital-cuttack/frame-2.png`} />,
                <img alt="" src = {`${imgPath}/scb-hospital-cuttack/frame-3.png`} />,
                <img alt="" src = {`${imgPath}/scb-hospital-cuttack/frame-4.png`} />
            ],
            thumbImage: 'scb-hospital-thumb.png',
        },
        {
            titleName:'BITS, Goa',
            fullName: 'BITS, ',
            status: 'completed',
            year:'2019',
            overview: '180ACRE/  Crore / Educational / Completed  2019',
            capacity: 'Infra plumbing',
            building: 'DH-1-6, NAB-1-5, CH-1-5, B1, 2, 3, UGT-1 & 2.',
            rating: 'Zero-carbon footprint campus.',
            designConcept: '2D Execution',
            vertical: 'education',
            services: 'plumbing',
            location:'Goa',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/bits/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/bits/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/bits/frame-3.jpg`} />,
                <img alt="" src = {`${imgPath}/bits/frame-4.jpg`} />
            ],
            thumbImage: 'bits-goa-thumb.png',
        },
        {
            titleName:'DLF Camellias, Gurugram ',
            fullName: 'DLF Camellias, ',
            status: 'completed',
            year:'2020',
            overview: '2 Acre / 5 Crore / Residential / Completed  2020',
            capacity: 'Internal plumbing and plant room',
            building: 'Towers 1-16.',
            rating: 'Platinum Level Pre-certification from IGBC.',
            designConcept: '2D Execution',
            vertical: 'residential',
            services: 'plumbing',
            location:'Gurugram, Haryana',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/dlf-camellias/frame-1.jpg`} />,
                <img alt="" src = {`${imgPath}/dlf-camellias/frame-2.jpg`} />,
                <img alt="" src = {`${imgPath}/dlf-camellias/frame-3.jpg`} />,
                <img alt="" src = {`${imgPath}/dlf-camellias/frame-4.jpg`} />,
                <img alt="" src = {`${imgPath}/dlf-camellias/frame-5.jpg`} />
            ],
            thumbImage: 'dlf-camellias-thumb.png',
        },
        {
            titleName:'ASI (INSTITUTE OF ARCHAEOLOGY) Noida',
            fullName: 'ASI (INSTITUTE OF ARCHAEOLOGY) Noida, ',
            status: 'completed',
            year:'2019',
            overview: 'Completed 2019',
            capacity: '--',
            building: '--',
            rating: '--',
            designConcept: '--',
            vertical: 'industries',
            services: 'hvac, electrical',
            location:'UP',
            content: '',
            imgData: [
                <img alt="" src = {`${imgPath}/asi-noida/frame-1.png`} />,
                <img alt="" src = {`${imgPath}/asi-noida/frame-2.png`} />,
                <img alt="" src = {`${imgPath}/asi-noida/frame-3.png`} />
            ],
            thumbImage: 'asi-noida-thumb.png',
        },
    ]

    const [filteredArr, setFilteredArr] = useState(projectData);

    const [open, setOpen] = React.useState(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxWidth: !isMobile ? 800 : 320,
        height: !isMobile ? 650 : 430,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: !isMobile ? 6 : 3,
        py:4,
      };

    // const slidePrev = (e) => {
    //     e.preventDefault()
    //     sliderRef.current.slickPrev();
    // }
    // const slideNext = (e) => {
    //     e.preventDefault()
    //     sliderRef.current.slickNext();
    // }

    const openPopHandler = (pValue) => {
        const filterData = projectData.find(item => item.titleName === pValue)
        setModalData(filterData)
        setOpen(true);
    }
    const closePopHandler = () => {
        setModalData(null);
        setOpen(false);
    }
    const handleFilterProject = (pVertical, pService, pStatus) => {
        console.log('selected ', pVertical, pService, pStatus);
        if((pVertical === 'read' || pVertical === 'unread') && (pStatus === '-1' || pStatus === '-2') && (pService === 'services' || pService === '-1')){
            setFilteredArr(projectData);
            return;
        }
        const filterData = projectData.filter(item => {
            // let items = null;  
            // Check for status
            if((pVertical === 'unread' || pVertical === 'read') && (pService === 'services' || pService === '-1') && pStatus !== '-2' && pStatus !== '-1' && item.status === pStatus){
                return item;
            }
            // Check for verticals
            if(pVertical !== 'unread' && pVertical !== 'read' && item.vertical === pVertical && (pService === 'services' || pService === '-1') && (pStatus === '-2' || pStatus === '-1' )){
                return item;
            }
            // check for services
            console.log(item.services, item.services.includes(pService));
            if((pVertical === 'unread' || pVertical === 'read') && pService !== 'services' && pService !== '-1' && item.services.includes(pService) && (pStatus === '-2' || pStatus === '-1')){
                return item;
            }
            
            // [verticals, status]
            if(pVertical !== 'unread' && pVertical !== 'read' && item.vertical === pVertical && (pService === 'services' || pService === '-1')  && pStatus !== '-1' && pStatus !== '-2' && item.status === pStatus){
                return item;
            }
            // [verticals, services]
            if(pVertical !== 'unread' && pVertical !== 'read' && item.vertical === pVertical && pService !== 'services' && pService !== '-1' &&  item.services.includes(pService)  && (pStatus === '-1' || pStatus === '-2')){
                return item;
            }

            // [// [services, status]]
            if((pVertical === 'unread' || pVertical === 'read') && pService !== 'services' && pService !== '-1' &&  item.services.includes(pService)  && pStatus !== '-1' && pStatus !== '-2' && item.status === pStatus){
                return item;
            }

           return pVertical !== 'read' && pVertical !== 'unread' && item.vertical === pVertical && item.status === pStatus && pStatus !== '-1'  && pStatus !== '-2' && pService !== 'services' &&  pService !== '-1' && item.services.includes(pService)
           
        });
        setFilteredArr(filterData);
    }
    const handleVerticals = (pTarget) => {
        if(pTarget.target.value === 'unread')return;
        setSelectedVertical(pTarget.target.value);
        handleFilterProject(pTarget.target.value, selectedService, selectedStatus);
    }
    const handleServices = (pTarget) => {
        if(pTarget.target.value === 'services') return;
        setSelectedService(pTarget.target.value);
        handleFilterProject(selectedVertical, pTarget.target.value, selectedStatus);
    }
    const handleStatus = (pTarget) => {
        // console.log(pTarget.target.value);
        if(pTarget.target.value === '-2')return;
        setSelectedStatus(pTarget.target.value);
        handleFilterProject(selectedVertical, selectedService, pTarget.target.value);
    }

    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Modal
                open={open}
                onClose={closePopHandler}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <>
                <Grid item sx={{display:'flex', justifyContent:'space-between'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className='titleStyle title-color' sx={{lineHeight:'1.25em !important', mb:1.5}}>
                    {modalData?.titleName}
                </Typography>
                <IconButton sx={{width:30, height:30}} onClick={closePopHandler}><CloseIcon /></IconButton>
                </Grid>
                
                <Grid item xs={12} sx={{overflowY:'auto', height:'auto', maxHeight: !isMobile ? 500 : 320}}>
                <Grid item xs={12} id="project-slider">
                    <Slider ref={sliderRef} {...settings} style={{width:'100%', maxWidth:720, height: !isMobile?360 : 180}}>{modalData?.imgData}</Slider>
                </Grid>
                {/* <Grid item className='scroll-btn' sx={{marginLeft:'0px !important', display:'flex', alignItems:'center'}}>
                    <IconButton onClick={slidePrev}>
                        <img src='/assets/images/right-arrow.png' alt='right icon' style={{transform:'rotate(180deg)'}} />
                    </IconButton>
                    <Typography className='txt normal-text' sx={{fontSize:'2em', fontWeight:600, color:'#4F9A41 !important'}}>/</Typography>
                    <IconButton onClick={slideNext}><img src='/assets/images/right-arrow.png' alt='right icon' /></IconButton>
                </Grid> */}
                <Grid item style={{marginTop:'2em'}}>
                
                    <Typography variant='body2' component='p' className='normal-text text-color' sx={{mb:'1em', fontSize:'0.9em'}}>
                            <span style={{fontWeight:500, fontSize:'1em'}}>Overview  </span><br/>{modalData?.overview}
                    </Typography>
                    <Typography variant='body2' component='p' className='normal-text' sx={{mb:'1em', fontSize:'0.9em'}}>
                        <span style={{fontWeight:500, fontSize:'1em'}}>Capacity</span><span style={{fontSize:'1em'}} dangerouslySetInnerHTML={{ __html: `<br/> ${modalData?.capacity}` }} />
                    </Typography>
                    <Typography variant='body2' component='p' className='normal-text' sx={{mb:'1em', fontSize:'0.9em'}}>
                        <span style={{fontWeight:500, fontSize:'1em'}}>Building  </span><br/>{modalData?.building}
                    </Typography>
                    <Typography variant='body2' component='p' className='normal-text' sx={{mb:'1em', fontSize:'0.9em'}}>
                        <span style={{fontWeight:500, fontSize:'1em'}}>Rating  </span><br/>{modalData?.rating}
                    </Typography>
                    <Typography variant='body2' component='p' className='normal-text' sx={{mb:'1em', fontSize:'0.9em'}}>
                        <span style={{fontWeight:500, fontSize:'1em'}}>Design concept  </span><br/>{modalData?.designConcept}
                    </Typography>
                    
                </Grid>
                
                </Grid>
                </>
                </Box>
            </Modal>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
                <Grid item className='title-div'>
                    <Grid item className='breadcrumb-div'>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                            <Typography sx={{color:'#535353'}}>Projects</Typography>
                        </Breadcrumbs>
                        <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                            <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2 }}>Our Projects</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                                Dive into our portfolio of MEP projects as we unveil the engineering excellence behind them. From cutting-edge technology to sustainable solutions, witness our technical expertise combined with our commitment to shape the future.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    // Select filter
                }
                <Grid item sx={{width:'100%', backgroundColor:'#F4F8FA', display:'flex', justifyContent:'center'}}>
                    <Grid item className="vertical-div">
                        <Grid item xs={6} sm={2} >
                                <Typography className='normal-text' sx={{marginLeft: isMobile && 1.2,fontWeight:'500 !important', lineHeight:2}}>Select Projects</Typography>
                        </Grid>
                        <Grid item sx={{pt: !isMobile && '1em', pb:'1em' , display:'flex'}}>
                            <select id='casestudy' onChange={(e) => handleVerticals(e)} className="optionStyle" value={selectedVertical}>
                                <option value='unread'>Verticals</option>
                                <option value='read'>All</option>
                                <option value='healthcare'>Healthcare</option>
                                <option value='datacenter'>Data Centers</option>
                                <option value='residential'>Residential</option>
                                <option value='airport'>Airports</option>
                                <option value='corporate'>Corporate</option>
                                <option value='education'>Education</option>
                                <option value='hotels'>Hotels</option>
                                <option value='industry'>Industry</option>
                                <option value='retails'>Retail & Offices</option>
                            </select>
                            <span className="optionSpan">|</span>
                            <select id='serviceId'  onChange={handleServices} className="optionStyle" value={selectedService}>
                                <option value='services'>Services</option>
                                <option value='-1'>All</option>
                                <option value='hvac'>HVAC</option>
                                <option value='firefighting'>Firefighting</option>
                                <option value='plumbing'>Plumbing</option>
                                <option value='electrical'>Electrical</option>
                                <option value='ibms'>IBMS / LV</option>
                            </select> 
                            <span className="optionSpan">|</span>
                            <select id='decadeId' onChange={handleStatus} className="optionStyle" value={selectedStatus}>
                                <option value='-2'>Project Status</option>
                                <option value='-1'>All</option>
                                <option value='ongoing'>On Going</option>
                                <option value='completed'>Completed</option>
                            </select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className="select1-div">
                    <Grid item xs={12} className="select-div" sx={{}}>
                        <Grid item xs={12} sx={{marginTop:'2em', display:'flex', flexFlow:'wrap', columnGap:'32px', rowGap:'48px' }}>
                            {filteredArr.length === 0 && 
                                <Typography variant='body2' component='p' className='normal-text'>
                                There is no data available.
                                </Typography>
                            }
                            {filteredArr.length > 0 && filteredArr.map(item => {
                                return <Card id={item.title} 
                                        sx={{backgroundColor:'#F4F8FA',
                                         maxWidth:327, 
                                         background:`url(/assets/images/${item.thumbImage}) no-repeat`, position:'relative', height:395, 
                                         objectFit:'contain',
                                         backgroundPosition:'center',
                                         backgroundSize:'cover',
                                         cursor:'pointer', width:'100%', borderRadius:0, justifyContent: isMobile && 'center'}} 
                                        onClick={() => openPopHandler(item.titleName)}
                                    >
                                    {/* <img src={`/assets/images/${item.thumbImage}`} style={{height:300}} alt={item.titleName}/> */}
                                    
                                    {/* <Grid item sx={{background:`url(/assets/images/${item.thumbImage}) no-repeat`, position:'relative', height:200, objectFit:'contain', width:'100%'}}>
                                        
                                    </Grid> */}
                                    <CardContent style={{display:'flex', alignItems:'center', bottom:0, position:'absolute'}}>
                                        <Typography className='normal-text subtitleStyle' sx={{ color:'#FFFFFF !important', fontSize:'1.25em !important'}}>{item.titleName}</Typography>
                                        <ArrowForwardIcon sx={{marginLeft: 2, fontSize:'20px', color:'#FFFFFF'}} />
                                    </CardContent>
                                </Card>
                            })}


                            
                        </Grid>
                        {/* <Grid item xs={12} sm={4} md={4} lg={4} xl={4} >
                            <Card sx={{backgroundColor:'#D8F2FF', maxWidth:327, width:'100%'}}>
                                <CardContent>
                                    <Typography className='normal-text subtitleStyle' sx={{minHeight:'100px'}}>AIIMS Trauma Centre, New Delhi</Typography>
                                    <Typography className='normal-text' sx={{}}>2006</Typography>
                                    <Typography className='normal-text' sx={{}}>Full MEP & HVAC</Typography>
                                </CardContent>
                                <Grid item sx={{background:'url(/assets/images/aiimsbg.jpg) no-repeat', position:'relative', height:165, objectFit:'cover', width:'100%'}}>
                                    <Button variant='contained' sx={{backgroundColor:'#FFFFFF', position:'absolute', bottom:'1em', left:'1em'}} 
                                        endIcon={<img src='/assets/images/right-arrow.png' alt='righticon'/>}
                                        onClick={() => openPopHandler('aiims')}
                                    >
                                        <Typography className='normal-text' sx={{textTransform:'capitalize', color: '#4F9A41 !important'}} >View detail</Typography>
                                    </Button>
                                </Grid>
                            </Card>
                        </Grid> */}
                    {/* <Grid item xs={12} sm={4} sx={{maxWidth:237, width:'100%'}}>
                        <Card sx={{backgroundColor:'#D8F2FF',maxWidth:327, width:'100%'}}>
                            <CardContent>
                                <Typography className='normal-text' sx={{fontSize:'1.5em !important', minHeight:'100px'}}>Goa Airport</Typography>
                                <Typography className='normal-text' sx={{}}>2023</Typography>
                                <Typography className='normal-text' sx={{}}>Full MEP & HVAC</Typography>
                            </CardContent>
                            <Grid item sx={{background:'url(/assets/images/cardbg.png) no-repeat', position:'relative', height:165, objectFit:'cover', width:'100%'}}>
                                <Button variant='contained' sx={{backgroundColor:'#FFFFFF', position:'absolute', bottom:'1em', left:'1em'}} 
                                    endIcon={<img src='/assets/images/right-arrow.png' alt='righticon'/>}
                                    onClick={openPopHandler}
                                >
                                    <Typography className='normal-text'  sx={{textTransform:'capitalize', color: '#4F9A41 !important'}} >View detail</Typography>
                                </Button>
                            </Grid>
                        </Card>
                    </Grid> */}
                    </Grid>
                </Grid>
                <Infocus />
                <Grid item className="customer-div">
                    <Grid item xs={12}>
                         <Typography className='titleStyle title-color' sx={{ width:'70%' }}>Customer-focused attitude</Typography>
                            
                        <Grid item xs={12} className="customer-flex" sx={{pt:2, display:'flex'}}>
                            <Grid item xs={12} sm={10} sx={{ml:0 }}>
                                <Typography className='normal-text subtitleStyle' sx={{ py:'1.25em !important' }}>“With our vast experience in the MEP space, and strong focus on constant improvement, we seek to widen our sphere of influence through manufacturing and adopting eco-friendly technologies, essential to create aesthetically pleasing and functional spaces.”</Typography>
                                <Typography className='quote-owner titleStyle text-color '> <span style={{fontWeight:'400 !important', color:'#4F9A41'}}> / </span>Pradeep Sharma</Typography>
                                <Typography className='quote-owner normal-text' sx={{ opacity:0.6 }}>Director</Typography>
                            </Grid>
                            <Grid item xs={12} sm={2} sx={{pt:'3em', display: isMobile && 'none'}}>
                                <img src='/assets/images/visionbg.png' alt='' />
                            </Grid>
                        
                        </Grid>
                    </Grid>
                </Grid>
                <Clients />
            </Grid>
        </Grid>
    )

}

export default Project;