import React, {useEffect} from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import CorporateCompliance from '../../components/corporate'

const Corporate = () => {

    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])


    return (
        <Grid container style={{width:'100%'}}>
            <Banner img={'/assets/images/corporate-banner.jpg'} title='' />
            <CorporateCompliance />
        </Grid>
    )

}

export default Corporate;