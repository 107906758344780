import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import Leaders from '../../components/leaders'

const Leadership = () => {
    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])

    return (
        <Grid container style={{width:'100%'}}>
            <Banner img={'/assets/images/leadersbanner.jpg'} title='Building services are the life force or "prana" of building structures.' />
            <Leaders />
        </Grid>
    )

}

export default Leadership;