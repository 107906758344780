import React from 'react';
import {Grid, Breadcrumbs, Typography} from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';


const AboutInformation = () =>{

    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className="title-div" sx={{width:'100%', display:'block !important'}}
                // sx={{px:22.5, pb:10, pt:5, width:'100%', maxWidth:1440}}
                >
                <Grid item sx={{px:!isMobile && 12.5}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}}>Home</Link>
                        <Typography sx={{color:'#535353'}}>About us </Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                        <Typography className='titleStyle  title-color' sx={{fontSize:36, pb:2}}>About Unique Engineers</Typography>
                        <Typography sx={{mb:4, lineHeight:2}}>
                        Unique Engineers is a full-service MEP solutions provider with extensive experience spanning over three decades. Our journey has been one of continuous growth and evolution, driven by our passion for delivering exceptional results and creating a positive impact on the world around us. We are a trusted advisor, implementation partner and domain consultant for public and business infrastructure projects, hospitals and community spaces. Each project is a unique opportunity to breathe life into structures, enrich habitats and foster a sustainable future.
                        </Typography>
                        <Typography sx={{overflow:'hidden',  textOverflow:'ellipsis', lineHeight:2}}>
                        At Unique Engineers, our commitment to excellence remains at the forefront. From the inception of a project to its completion, we strive to match requirements and exceed expectations. Our team of dedicated professionals takes the time to understand your vision, needs, and challenges, ensuring a collaborative and customer-driven approach. With a strong focus on ethical business practices, we embrace the responsibility of optimizing living and working spaces. Our MEP solutions center around the well-being of humans and enhance indoor living and working environments. We actively work towards reducing waste and minimizing our carbon footprint, contributing to a greener and more environmentally attuned world.
        When you choose Unique Engineers, you choose more than a service provider; you choose a reliable partner you can count on through your transformation journey of building an eco-friendly and brighter future.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    )

}

export default AboutInformation;