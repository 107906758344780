import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import SocialCommit from '../../components/socialcommit'

const SocialCommitment = () => {
    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])

    return (
        <Grid container style={{width:'100%'}}>
            <Banner img={'/assets/images/social-commitments-banner.jpg'} title='' />
            <SocialCommit />
        </Grid>
    )

}

export default SocialCommitment;