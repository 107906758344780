import { Grid, Typography, Card, CardContent, CardActions, Button } from '@mui/material';
// import axios from 'axios';
import React from 'react';
import { isMobile } from 'react-device-detect';
import {useNavigate} from 'react-router-dom';


const GetKnow = () => {

    const navigate = useNavigate();
    // const [postData, setPostData] = useState({media:'', title:'', excerpt:'', sLink:''});
    // const baseURL = "https://beta.uniqueengineersltd.com/blog/wp-json/wp/v2";
    // useEffect(()=>{
        
    //     try {
    //         axios.get(`${baseURL}/posts?per_page=1`)
    //         .then ((res)=>{
    //             const data = res.data;
    //             const {featured_media, title, excerpt, slug} = data[0];
    //             setPostData({media:featured_media, excerpt: excerpt.rendered, sLink:slug, title: title.rendered});
    //         })
    //     } catch (error) {
    //         console.log('errr =', error)
    //     }
        
    // }, [])


return(
    <Grid item sx={{width:'100%', display:'flex', justifyContent:'center'}}>
        <Grid item className='getknow1-div' sx={{maxWidth:1440, width:'100%'}}>
        <Grid item className='getknow-div'>
            <Typography variant='h3' component={'h3'} className='titleStyle title-color' sx={{mb:2}}>
                            Get to know us
            </Typography>
        </Grid>
        <Grid item className='getknow-section'>
            <Grid item className='getknow1-section' sx={{background: !isMobile && 'url(/assets/images/getnewbg1.png) no-repeat', backgroundPosition:'bottom right' }}> 
            <Grid item sx={12} sm={4} md={5}>
                <Typography variant='body2' component={'p'} className='normal-text size-20'>
                    SOCIAL RESPONSIBILITY
                </Typography>
                <Card sx={{my: !isMobile ? 4 : 2, borderRadius:0 }}>
                    <div style={{position:'relative', height: !isMobile ? 250 : 200}}>
                        <div className='img-wrapper'>
                            <img src= '/assets/images/blogimage2.png' alt='social responsibility' style={{width:'100%',height:'100%'}}/>
                        </div>
                    </div>
                    {/* <CardMedia
                            sx={{ height: 260}}
                            image="/assets/images/blogimage.jpeg"
                            title="Blog"
                        /> */}
                    </Card>
                <CardContent sx={{pl:0, py:0}}>
                    <Typography variant='h4' component={'h4'} className='titleStyle subtitleStyle'  sx={{mb:2, minHeight:72}}>
                        Our social commitment is part of our growth story
                    </Typography>
                    <Typography variant='body2' component={'p'} className='normal-text'>
                        At the workplace and beyond, we focus on fostering an environment that values people. Explore our commitment to society.
                    </Typography>
                </CardContent>
                <CardActions sx={{pl:0}}>
                    <Button variant='text' className='btn' 
                        onClick={() => navigate('/social-commitment')} 
                        disableFocusRipple disableRipple sx={{pl:0}}>
                        <Typography variant='body2' component={'p'} className='normal-text'
                            sx={{textTransform:'capitalize', lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                            Read More
                        </Typography>
                        <img src='/assets/images/right-arrow.png' alt='right icon' />
                    </Button>
                </CardActions>
            </Grid>
            <Grid item sx={12} sm={4} md={5}>
                <Typography variant='body2' component={'p'} className='normal-text size-20'>
                    OUR APPROACH
                </Typography>
                <Card sx={{my: !isMobile ? 4 : 2, borderRadius:0 }}>
                    <div style={{position:'relative', height:!isMobile ? 250 : 200}}>
                        <div className='img-wrapper'>
                            <img src='/assets/images/cultureimage.png' alt='' style={{width:'100%',height:'100%'}}/>
                        </div>
                    </div>
                </Card>
                <CardContent sx={{pl:0, py:0}}>
                    <Typography variant='h4' component={'h4'} className='titleStyle subtitleStyle' sx={{mb:2, minHeight: !isMobile ? 72: 30}}>
                        We like to work in a different way
                        {/* {postData.title !== '' ? postData.title : "We like to work in a different way"} */}
                    </Typography>
                    <Typography variant='body2' component={'p'} className='normal-text'>
                        Our people are our family and we help them unlock their potential at work. We support them beyond work wherever needed.
                    </Typography>
                    {/* <Typography variant='body2' component={'p'} className='normal-text' dangerouslySetInnerHTML={{ __html:postData.excerpt !== '' ? postData.excerpt : "No Data" }} /> */}
                </CardContent>
                <CardActions sx={{pl:0}}>
                    <Button variant='text' className='btn' 
                        onClick={() => navigate(`/our-approach`)} 
                        // onClick={() => navigate(`/blogs/${postData.sLink}`)} 
                        disableFocusRipple disableRipple sx={{pl:0}} 
                    >
                        <Typography variant='body2' component={'p'} className='normal-text'
                        sx={{textTransform:'capitalize', color:'#4F9A41 !important'}}>
                        Read More
                    </Typography>
                    <img src='/assets/images/right-arrow.png' alt='right icon' />
                    </Button>
                </CardActions>
            </Grid>
            </Grid>
        </Grid>
        <Grid item className='getknow-section' sx={{columnGap:4, display:'flex',
            background: !isMobile && 'url(/assets/images/getnewbg.png) no-repeat', backgroundPosition:'bottom left'}}>
            <Grid item className='getknow1-section '
                sx={{display:'flex', pt:0, borderTop: !isMobile && '1px solid #DAE6EF', pb:25, 
                
            }}
            >
                <Grid item xs={12} sm={6} md={6} sx={{pt:2}}>
                    <Typography variant='h3' component={'h3'} className='titlestyle subtitleStyle'
                        sx={{mb:2}}>
                            A better world, a brighter tomorrow</Typography>
                    <Typography variant='bod2' component={'p'} className={'text-color'} 
                        sx={{fontFamily:'Inter',lineHeight:'1.8em', mt:1, height:147, overflowWrap:true, textOverflow:'ellipsis', overflow:'hidden'}}>
                        At our core, we draw inspiration from the beauty of nature and the dynamic world we live in. The environment's influence drives us to be mindful of our actions, while the profound impact of human experiences gives a deeper relevance to our engineering feats. 
                    </Typography>
                    <Typography variant='bod2' component={'p'} className={'text-color'}
                        sx={{fontFamily:'Inter',lineHeight:'1.8em', mt:1 }}
                    >
                    Our ultimate goal is to create spaces that withstand the trials of time and leave a lasting impression on people's lives. With a vision for a brighter future, we are dedicated to building a world that is better, both for today and tomorrow.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{textAlign: !isMobile? '-webkit-center':'left', pt:0, pb:0, ml: !isMobile? 2 : 0}}>
                    <Grid item xs={12} sx={{textAlign:'-webkit-center', background: !isMobile ? 'url(/assets/images/getknowbg.png) no-repeat' : 'url(/assets/images/getknowbgmobile.png) no-repeat' , width:'100%', maxWidth: !isMobile ? 525 : 335, height:'auto', maxHeight:396, backgroundPosition: !isMobile ? 'bottom left' : 'center', backgroundSize: isMobile && 'contain', pt:!isMobile ? 6 : 2, pb: !isMobile ? 18: 2 }}>
                        <div style={{position:'relative', height: !isMobile ? 250 : 286, }}>
                            <div className='img-wrapper' style={{ display: isMobile && 'flex', alignItems: isMobile && 'center', justifyContent: isMobile && 'center' }}>
                                <img src='/assets/images/brightercycle.png' alt='' style={{width:'45%', mixBlendMode: 'darken'}}/>
                            </div>
                        </div>
                        
                        {/* <div style={{background:'url(/assets/images/brightercycle.png) no-repeat', mixBlendMode: 'darken', width:235, height:243, transform: isMobile && 'scale(0.8)'}}></div> */}
                    </Grid>
                    
                </Grid>
            </Grid>
        </Grid>
        </Grid>
    </Grid>
)

}

export default GetKnow;