import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import Banner from '../../components/services/Banner';
// import { Link } from 'react-router-dom';


const Privacy = () => {
    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])

    return (
        <Grid container sx={{justifyContent:'center'}}>
            <Banner img={`/assets/images/privacy-policy-banner.jpg`} title= 'React out and Connect' />
            <Grid item sx={{width:'100%', maxWidth:1440}}>
                
                <Grid item xs={12} className='title-div' >
                <Grid item className='breadcrumb-div'>
                    {/* <Grid item sx={{px:12.5, pt:'1.5em', pb:'2em'}}> */}
                        {/* <Breadcrumbs aria-label="breadcrumb">
                                <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                                <Typography sx={{color:'#535353'}}>Products</Typography>
                            </Breadcrumbs> */}
                        <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                            <Typography className='titleStyle title-color' sx={{ fontSize:36, pb:2}}>Privacy Policy</Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                Unique Engineers ("Company," "we," or "us") is committed to protecting the privacy and security of our corporate clients' personal and project information.
                                <br/>
                                This Privacy Policy outlines how we collect, use, share and safeguard the information provided to us during the provision of MEP services.
                            </Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                <span className='subtitleStyle' style={{fontWeight:'500 !important'}}>1. Information collection</span><br/>
                                a. We collect personal information from our corporate clients, including contact details, billing information and other relevant information necessary for effective project management.
                                <br/>
                                b. Additionally, we may collect project-related information such as building plans, specifications, designs, and financial data to deliver our MEP services.
   
                            </Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                <span className='subtitleStyle' style={{fontWeight:'500 !important'}}>2. Confidentiality measures</span><br/>
                                a. We prioritize the confidentiality of our clients' sensitive information by implementing strict access controls, password protection and secure storage systems.
                                <br />
                                b. Our employees and authorized subcontractors undergo training on confidentiality and sign confidentiality agreements to ensure the protection of client data.
                            </Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                <span className='subtitleStyle' style={{fontWeight:'500 !important'}}>3. Purpose and use of information</span><br/>
                                a. The personal and project information collected is used solely for providing MEP services, including project planning, design, coordination, implementation and maintenance.
                                <br />
                                b. We may also use this information to communicate project updates, address client inquiries, and improve our services.
                            </Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                <span className='subtitleStyle' style={{fontWeight:'500 !important'}}>4. Third-Party sharing</span><br/>
                                a. We may share project-related information with authorized subcontractors, partners, or vendors involved in the delivery of MEP services, always ensuring they uphold confidentiality and security standards.
                                <br />
                                b. Client information will not be shared with third parties for marketing purposes without explicit consent, unless required by law or in response to legal requests.
                            </Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                <span className='subtitleStyle' style={{fontWeight:'500 !important'}}>5. Data retention</span><br/>
                                a. We retain client information only as long as it is necessary to fulfil the purposes for which it was collected or as required by relevant laws and regulations.
                                <br />
                                b. Once the MEP project is completed, we securely dispose of or anonymize client data in accordance with industry best practices.
                            </Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                <span className='subtitleStyle' style={{fontWeight:'500 !important'}}>6. Data security</span><br/>
                                a. We employ technical, physical and administrative measures to protect client information from unauthorized access, disclosure, alteration or destruction.
                                <br/>
                                b. These measures include data encryption, firewalls, access controls, regular system monitoring and ongoing staff training on data security protocols.
                            </Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                <span className='subtitleStyle' style={{fontWeight:'500 !important'}}>7. Compliance with laws and regulations</span><br/>
                                a. We comply with applicable privacy and data protection laws and regulations in the jurisdictions where we operate.
                                <br />
                                b. We also cooperate with relevant authorities and legal requirements regarding the protection and disclosure of client information.
                            </Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                <span className='subtitleStyle' style={{fontWeight:'500 !important'}}>8. Client rights</span><br/>
                                a. Corporate clients have the right to access, correct or update their personal information provided to us.
                                <br/>
                                b. Clients may also request the deletion of their information, unless legal or business requirements dictate otherwise.
                                <br/>
                                c. Additionally, clients have the right to opt out of receiving marketing communications from us.
                            </Typography>
                            <Typography variant='body2' component='p' className='normal-text' sx={{mb:2, lineHeight:2}}>
                                <span className='subtitleStyle' style={{fontWeight:'500 !important'}}>9. Updates to Privacy Policy</span><br/>
                                a. We may update this Privacy Policy periodically, and any changes will be posted on our website.
                                <br />
                                b. Clients will be notified of significant changes and, if required by applicable laws, provided with an opportunity to review and consent to the updated policy.

                                <br/>
                                <br/>
                                Please note that this privacy policy may be further tailored and customized to meet specific legal and regulatory requirements, as well as industry standards.
                            </Typography>

                        </Grid>
                    </Grid>
                
                </Grid>
            </Grid>
        </Grid>
    )



}

export default Privacy;