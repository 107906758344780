import React from 'react';
import { Grid } from '@mui/material';

const Banner = (props) => {

    return (
        <Grid item sx={{width:'100%'}}>
            <Grid sx={{px:22.5, backgroundColor:'#F4F8FA', pt:'1.6em', pb:'2em'}}>
                {/* <Typography variant='h1' component={'h1'} sx={{ fontFamily:'Playfair Display', fontSize:'2em', fontWeight:500, color:'#1D5A93', textAlign:'center'}}> {props.title}</Typography> */}
            </Grid>
            <Grid item sx={{background:'url(/assets/images/background-pattern.svg) no-repeat',backgroundSize:'cover', backgroundPosition:'center top', textAlign:'center', width:'100%', height:'auto'}}>
                <div style={{position:'relative', height:'100%'}}>
                    <div className='img-wrapper' style={{position:'relative'}}>
                        <img src={props.img} alt='bannerimage' className='page-banner-image' style={{objectFit:'contain'}}/>
                        {/* <img src='/assets/images/multifaced1.png' alt='multifaced' style={{objectFit:'cover', width:'100%'}} /> */}
                    </div>
                </div>
            </Grid>
            
        </Grid>
    )

}

export default Banner;