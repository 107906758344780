import { Grid, Typography } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';

const Clients = () => {

    const settings = {
        dots: false,
        dotsClass: 'slick-dots',
        arrows: false,
        className: 'center',
        centerMode: false,
        infinite: true,
        centerPadding: '20px',
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        swipeToSlide: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              dots: false,
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 0,
              centerMode: false,
            },
          },
        ],
      };
const imgHeight = isMobile ? 40 : 60;
const sliderss = [
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center', px:10}}>
        <img src='/assets/images/clients/acil-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/airindia-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/amazon-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/amity-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/bharti-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/britannia-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/gmr-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'7'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/kelloggs-logo.png' style={{height:imgHeight}}  alt="7" />
    </Grid>,
    <Grid key={'9'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/marriott-logo.png' style={{height:imgHeight}} alt="9" />
    </Grid>,
    <Grid  key={'10'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/pepsi-logo.png' style={{height:imgHeight}} alt="10" />
    </Grid>,
    <Grid key={'11'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/pnb-logo.png' style={{height:imgHeight}} alt="11" />
    </Grid>,
    <Grid key={'12'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/png-logo.png' style={{height:imgHeight}} alt="12" />
    </Grid>,
    <Grid key={'12'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/samsung-logo.png' style={{height:imgHeight}} alt="12" />
    </Grid>
]



return (
    <Grid item sx={{backgroundColor:'#F4F8FA', width:'100%', display:'flex', justifyContent:'center'}}>
        <Grid className='client-div' sx={{width:'100%', maxWidth:1440, pt: !isMobile? '5em' : '4em', pb: !isMobile? '7em': '5em'}}>
            <Typography variant='h4' component={'h4'} className='titleStyle title-color client-title' sx={{mb:2}}>
                            Clients we serve</Typography>
            <Grid item xs={12} sx={{mt:'3em'}}>
                <Slider className='client-slider' {...settings}>{sliderss}</Slider>
                {/* <Grid item xs={2} sm={2}>
                    <img src='/assets/images/coke.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <img src='/assets/images/schiender.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <img src='/assets/images/spaze.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <img src='/assets/images/pg.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <img src='/assets/images/mariott.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid> */}
            </Grid>
        </Grid>
    </Grid>
)

}

export default Clients;