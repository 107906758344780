import React, { useState, useRef } from 'react';
import { Grid, Typography, Breadcrumbs, IconButton, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import {Waypoint} from 'react-waypoint'

import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';

import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';

const Leaders = () => {

    const [directorNumb, setDirectorNumb] = useState(0);
    const [numberBool, setNumberBool] = useState(false);
    const sliderRef = useRef(null);

    const directorData = [
        {
            director:'Rajiv Gupta',
            content:`Rajiv Gupta has a deeper-than-usual connection with his work. He also has an invigorating reading and running routine. During the pandemic, he discovered the pleasure of audio books and since then, he makes it a point to audio-read as he goes on his daily run.
                    <br/><br/>
                    An electrical engineer by training, he has been in this successful partnership for over three decades and has nurtured the company from a seed to its present avatar. Through this long journey filled with ups and downs, he has forged long-lasting bonds with customers and business associates alike. His personal mantra of staying updated with the latest trends and technologies in the field as well as learning from experiences, all make him a valuable leader and colleague.
                    <br/><br/>
                    As a fitness enthusiast he has a dream of completing the Ironman Kalmar held in Sweden. He is also a devoted student of Vedanta, and views his work as an embodiment of the ‘panch-tatva’ or five elements that all life is made from. He remains an avid reader, genre no bar, and it’s likely you’ll find him immersed in a book on a quiet evening. Above all, he cherishes his family and places great importance on strong familial relationships.`,
            quotes: `“Achieving greatness means having the courage to persist through failures.”`
        },
        {
            director:'Pradeep Sharma',
            content:`Pradeep Sharma has a winning work ethic: To do everything to the highest quality, and deliver with complete commitment and honesty. As a result, he has always gained opportunities to expand, explore and grow from strength to strength.
            <br /><br />
            Dedicated to his work, he has had a long and inspiring career after completing his engineering in 1988. Having been exposed to sophisticated technologies in the HVAC industry early on in his career, he nurtured a great vision for the future. In fact, his deep hands-on knowledge of all facets of the industry now gives him a ringside view of the challenges as well as opportunities for expansion in the future.
            <br /><br />
            Throughout his career Pradeep has cultivated a strong focus on self-improvement, with a commitment to keep learning, whether about new and emerging technologies or more efficient work practices. Additionally, he has a passion for interior design and Vaastu, and creative skills coupled with attention to detail – all essential ingredients in the creation of aesthetically pleasing and functional spaces.
            <br /> <br />           
            His personal value-system of being god-fearing and compassionate makes him empathetic and caring, and colleagues as well as clients greatly appreciate his attitude towards people and work alike.`,
            quotes: `“Empathy and compassion foster a healthy, supportive work environment.”`
        },
        {
            director:'Manmohan Jindal',
            content:`Manmohan Jindal brings a silent passion to his work, and to watch him go about his daily tasks is nothing short of inspirational. It’s easy to pass him by, but certainly, that cannot be said of his contribution to the company and its rich ethos.
            <br/><br/>
            He earned his engineering degree in 1989 with a specialization in refrigeration and air conditioning, and thereafter, dedicated himself to learning the ropes of turning building spaces into living breathing habitats that went beyond being just ‘air-conditioned’. Even today he keeps himself updated with new technologies, for instance, for water conservation, sustainability and reduction of carbon footprint.
            <br/><br/>
            Alongside enhancing his own knowledge, he is also committed to upgrading the knowledge of his people, and strongly believes in training and upskilling them to unlock their potential. He also throws his might behind social endeavors, like helping out with an employee's daughter’s marriage, or with a girl child's schooling. 
            <br/><br/>
            Dedicated to work he is, but he also makes the time to keep abreast of business and political news. He likes to spend time with his family, and loves listening to old songs. Occasionally you might catch him dabbling in the kitchen, cooking a finger-licking dish.`,
            quotes: `“Our people are our family, and developing their talents is key to our growth.”`
        }
    ]

    
    const handleDirectordetails = (pIndex) =>{
        setDirectorNumb(pIndex);
    }
    const settings = {
        dots: true,
        arrows: false,
        className: 'center',
        centerMode: false,
        infinite: true,
        slidesToShow: Math.min(1),
        slidesToScroll: Math.min(1),
        swipeToSlide: true,
        afterChange: current => handleDirectordetails(current),
        
        width:'100%',
        speed: 500,
        responsive: [
         
          {
            breakpoint: 600,
            settings: {
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              width:'100%',
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
              infinite: true,
              width:'100%',
              centerMode: false,
            },
          },
        ],
      };

      const slides = [
        <Grid item xs={12} sx={{width:'99% !important'}}>
            <Card elevation={0} sx={{my:2, background:'transparent', borderRadius:0 }}onClick={e=>{handleDirectordetails(0)}}>
                <div style={{position:'relative', height:300}}>
                    <div className='img-wrapper'>
                        <img src= {'/assets/images/rajiv.png'} alt='rajiv' style={{width:'100%'}}/>
                    </div>
                </div>
                
                <CardContent 
                    sx={{background: directorNumb !== 0 ? 'url(/assets/images/leader-bg.png) no-repeat': 'url(/assets/images/leadersactivebg.png) no-repeat', backgroundSize:'contain', display:'flex', paddingRight:0, alignItems:'center'}}
                >
                    <Grid item xs={10}>
                        <Typography className='normal-text' sx={{ fontWeight:'500 !important', fontSize:'1.25em !important', lineHeight:'18px !important'}}>Rajiv Gupta</Typography>
                        <Typography className='normal-text' sx={{ fontWeight:'500 !important' }}>Director</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'right'}}>
                        {console.log(directorNumb)}
                        {directorNumb !== 0 && <IconButton onClick={e=>{handleDirectordetails(0)}}>
                            {/* <AddIcon /> */}
                            {/* {directorNumb === 0 ? <RemoveIcon /> : <AddIcon />} */}
                            <AddIcon />
                            </IconButton>
                        }
                    </Grid>
                </CardContent>
            </Card>
        </Grid>,
        <Grid item xs={12} sx={{width:'99% !important'}}>
            <Card elevation={0} sx={{my:2, background:'transparent', borderRadius:0 }}onClick={e=>{handleDirectordetails(1)}}>
                <div style={{position:'relative', height:300}}>
                    <div className='img-wrapper'>
                        <img src={directorNumb !== 1 ? '/assets/images/pradeepgrey.png': '/assets/images/pradeep.png'} alt='pradeep' style={{width:'100%'}}/>
                    </div>
                </div>
                
                <CardContent sx={{background:directorNumb !== 1 ? 'url(/assets/images/leader-bg.png) no-repeat': 'url(/assets/images/leadersactivebg.png) no-repeat', backgroundSize:'contain', display:'flex', paddingRight:0, alignItems:'center'}}>
                    <Grid item xs={10}>
                        <Typography className='normal-text' sx={{ fontWeight:'500 !important', fontSize:'1.25em !important', lineHeight:'18px !important'}}>Pradeep Sharma</Typography>
                        <Typography className='normal-text' sx={{ fontWeight:'500 !important' }}>Director</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'right'}}>
                        {directorNumb !== 1 && <IconButton onClick={e=>{handleDirectordetails(1)}}>
                            {/* <AddIcon /> */}
                            <AddIcon/>
                        </IconButton>
                        }
                    </Grid>
                </CardContent>
            </Card>
        </Grid>,
        <Grid item xs={12} sx={{width:'99% !important'}}>
            <Card elevation={0} sx={{my:2, background:'transparent', borderRadius:0 }} onClick={e=>{handleDirectordetails(2)}}>
                <div style={{position:'relative', height:300}}>
                    <div className='img-wrapper'>
                        <img src={directorNumb !== 2 ? '/assets/images/manmohangrey.png': '/assets/images/manmohan.png'} alt='pradeep' style={{width:'100%'}}/>
                    </div>
                </div>
                
                <CardContent sx={{background:directorNumb !== 2 ? 'url(/assets/images/leader-bg.png) no-repeat': 'url(/assets/images/leadersactivebg.png) no-repeat', backgroundSize:'contain', display:'flex', paddingRight:0, alignItems:'center'}}>
                    <Grid item xs={10}>
                        <Typography className='normal-text' sx={{ fontWeight:'500 !important', fontSize:'1.25em !important', lineHeight:'18px !important'}}>Manmohan Jindal</Typography>
                        <Typography className='normal-text' sx={{ fontWeight:'500 !important' }}>Director</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'right'}}>
                        {directorNumb !== 2 && <IconButton onClick={e=>{handleDirectordetails(2)}}>
                            {/* <AddIcon /> */}
                             <AddIcon />
                        </IconButton>
                        }
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    ]
    

    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
                <Grid item className='title-div' sx={{pb:'5em'}}
                    // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em', pb:'5em'}}
                >
                    <Grid item sx={{px: !isMobile ? 12.5 : 0}}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                            <Typography sx={{color:'#535353'}}>Leadership Team</Typography>
                        </Breadcrumbs>

                        <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                            <Typography className='titleStyle title-color' sx={{ fontSize: !isMobile ? '2em !important' : '1.5em !important', pb:2}}>Leadership Team</Typography>
                            <Typography sx={{mb:4, lineHeight:2}}>
                                The leadership team is a tightly-knit trio that have stood the test of time and changes in the business environment. Besides providing visionary leadership to the organization, they also display exemplary human qualities of empathy and a vision for society beyond the walls of the office. It is what sets them ahead of challenges, both personal and professional.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    // Select filter
                }
                <Grid item xs={12} sx={{width:'100%', backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px:!isMobile ? 10: 2.5, display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{width:'100%', maxWidth:1440, px: !isMobile && 12.5, pt: !isMobile ? "5em" : '3em',  pb: !isMobile ? '5em' : '2em', }}>
                        <Grid item sx={{}}>
                            <Typography className='titleStyle title-color' sx={{ pb:'2em', textAlign: !isMobile && 'center'}}>A leadership journey</Typography>
                            <Grid item sx={{width:'100%', display:'flex', flexFlow:!isMobile ? 'row' : 'column', pb: !isMobile ? '2em' : '1em', px: !isMobile && '3em', columnGap:3 }}>
                                <Grid item xs={12} sm={4} sx={{}}>
                                    <Typography variant='body2' component='div' className='titleStyle title-color subtitleStyle' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign: !isMobile && 'center'}}>
                                        { numberBool &&  <p className="numbers">
                                            <span className="numbers__window">
                                                <span className="numbers__window__digit numbers__window__digit--1" data-fake="6642212862">1</span>
                                            </span>
                                            <span className="numbers__window">
                                                <span className="numbers__window__digit numbers__window__digit--2" data-fake="9641218062">9</span>
                                            </span>
                                            <span className="numbers__window">
                                                <span className="numbers__window__digit numbers__window__digit--3" data-fake="3692750268">9</span>
                                            </span>
                                            <span className="numbers__window">
                                                <span className="numbers__window__digit numbers__window__digit--3" data-fake="0612455271">0</span>
                                            </span>
                                        </p>
                                        }
                                    </Typography>
                                    <Typography className='normal-text' sx={{pb:2, textAlign: !isMobile && 'center'}}>Started out together, deputed to the same NTPC project</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{}}>
                                    <Waypoint onEnter={() => setNumberBool(true)} />
                                    <Typography variant='body2' component='div' className='titleStyle title-color subtitleStyle' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign: !isMobile && 'center'}}>
                                    { numberBool &&  <p className="numbers">
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--1" data-fake="3642210061">3</span>
                                        </span>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--2"  style={{width:'100%', animation:'none'}} data-fake="+">+</span>
                                        </span>
                                    </p>
                                    }
                                    </Typography>
                                    <Typography className='normal-text' sx={{pb:2, textAlign: !isMobile && 'center'}}>Decades of thriving partnership</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{}}>
                                    <Typography className='titleStyle title-color subtitleStyle' sx={{ pb:1.7, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign: !isMobile && 'center'}}>1</Typography>
                                    <Typography className='normal-text' sx={{pb:2, textAlign: !isMobile && 'center'}}>Vision to exceed client expectations for MEP solutions</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{backgroundColor:'#F4F8FA',width:'100%', px:!isMobile ? 10:2.5, display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{width:'100%', maxWidth:1440, px:!isMobile && 22.5, display:'flex', justifyContent:'center' }}>
                        {!isMobile ?
                        <Grid item xs={12} sx={{width:'100%', display:'flex', columnGap:4}}>
                            <Grid item xs={12} sm={4}>
                                <Card elevation={0} sx={{my:2, background:'transparent', borderRadius:0 }}>
                                    <div style={{position:'relative', height:300}}>
                                        <div className='img-wrapper'>
                                            <img src= {directorNumb !== 0 ? '/assets/images/rajivgrey.png': '/assets/images/rajiv.png'} alt='rajiv' style={{width:'100%'}}/>
                                        </div>
                                    </div>
                                    
                                    <CardContent sx={{background: directorNumb !== 0 ? 'url(/assets/images/leader-bg.png) no-repeat': 'url(/assets/images/leadersactivebg.png) no-repeat', backgroundSize:'contain', display:'flex', paddingRight:0, alignItems:'center'}}>
                                        <Grid item xs={10}>
                                            <Typography className='normal-text' sx={{ fontWeight:'500 !important', fontSize:'1.25em !important', lineHeight:'18px !important'}}>Rajiv Gupta</Typography>
                                            <Typography className='normal-text' sx={{ fontWeight:'500 !important' }}>Director</Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{textAlign:'right'}}>
                                            {directorNumb !== 0 && <IconButton onClick={e=>{handleDirectordetails(0)}}> 
                                                <AddIcon />
                                                {/* {directorNumb === 0 ? <RemoveIcon /> : <AddIcon />} */}
                                            </IconButton>
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card elevation={0} sx={{my:2, background:'transparent', borderRadius:0  }}>
                                    <div style={{position:'relative', height:300}}>
                                        <div className='img-wrapper'>
                                            <img src={directorNumb !== 1 ? '/assets/images/pradeepgrey.png': '/assets/images/pradeep.png'} alt='pradeep' style={{width:'100%'}}/>
                                        </div>
                                    </div>
                                    
                                    <CardContent sx={{background:directorNumb !== 1 ? 'url(/assets/images/leader-bg.png) no-repeat': 'url(/assets/images/leadersactivebg.png) no-repeat', backgroundSize:'contain', display:'flex', paddingRight:0, alignItems:'center'}}>
                                        <Grid item xs={10}>
                                            <Typography className='normal-text' sx={{ fontWeight:'500 !important', fontSize:'1.25em !important', lineHeight:'18px !important'}}>Pradeep Sharma</Typography>
                                            <Typography className='normal-text' sx={{ fontWeight:'500 !important' }}>Director</Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{textAlign:'right'}}>
                                            {directorNumb !== 1 && <IconButton onClick={e=>{handleDirectordetails(1)}}>
                                                <AddIcon />
                                                {/* {directorNumb === 1 ? <RemoveIcon /> : <AddIcon />} */}
                                            </IconButton>
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card elevation={0} sx={{my:2, background:'transparent', borderRadius:0  }}>
                                    <div style={{position:'relative', height:300}}>
                                        <div className='img-wrapper'>
                                            <img src= {directorNumb !== 2 ? '/assets/images/manmohangrey.png' : '/assets/images/manmohan.png'} alt='manmohan' style={{width:'100%'}}/>
                                        </div>
                                    </div>
                                    <CardContent sx={{background: directorNumb !== 2 ? 'url(/assets/images/leader-bg.png) no-repeat': 'url(/assets/images/leadersactivebg.png) no-repeat', backgroundSize:'contain', display:'flex', paddingRight:0, alignItems:'center'}}>
                                        <Grid item xs={10}>
                                            <Typography className='normal-text' sx={{ fontWeight:'500 !important', fontSize:'1.25em !important', lineHeight:'18px !important'}}>Manmohan Jindal</Typography>
                                            <Typography className='normal-text' sx={{ fontWeight:'500 !important' }}>Director</Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{textAlign:'right'}}>
                                        {directorNumb !== 2 && <IconButton onClick={e=>{handleDirectordetails(2)}}>
                                                <AddIcon />
                                                {/* {directorNumb === 2 ? <RemoveIcon /> : <AddIcon />} */}
                                            </IconButton>
                                        }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12}> 
                            <Slider id='footprintSlider' ref={sliderRef} {...settings}>{slides}</Slider>
                         </Grid>    
                        }
                    </Grid>
                </Grid>
                <Grid item sx={{backgroundColor:'#F4F8FA',width:'100%', px:!isMobile ? 10:2.5, display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{width:'100%', maxWidth:1440, px: !isMobile && 22.5, pt:'1em', pb:'2em'}}>
                        <Grid item sx={{pt:'0em'}}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{textAlign: 'left',mb:2}} 
                                dangerouslySetInnerHTML={{__html: directorData[directorNumb].content}}>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{width:'100%', pt:'3em', pb: !isMobile ? '5em' : '3em', display:'flex', justifyContent:'center'}}>
                            <Grid item xs={12} sm={12} sx={{pl:'0em', pt: !isMobile ? '2em' : '2em'}}>
                                <Typography id='directorqotation' variant='body2' className='titleStyle title-color' 
                                    sx={{ fontFamily:'Inter !important', pb: isMobile ? '1em': 0, fontSize: !isMobile ? '1.5em !important' : '1.25em !important' , fontWeight:'500 !important', textAlign:'Left', lineHeight:'1.5em !important'}}
                                >
                                    {directorData[directorNumb].quotes}
                                </Typography>
                                <Typography id='directorname' variant='body2' component={'p'} className='quote-owner titleStyle text-color' 
                                    sx={{textAlign: 'left',mb:2}}>
                                    <span style={{fontWeight:'400 !important', color:'#4F9A41'}}>/ </span>{directorData[directorNumb].director}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default Leaders;