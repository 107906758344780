import { Grid, Typography } from '@mui/material';
// import { Timeline, TimelineDot, TimelineConnector, TimelineItem, TimelineSeparator, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import React, { useEffect } from 'react';
import $ from 'jquery';
import {isMobile} from 'react-device-detect';
import {} from './milestone.css';

const Milestone = () => {

    useEffect(() => {

        // console.log('window', window);
        var agTimeline = $('.js-timeline'),
         agTimelineLine = $('.js-timeline_line'),
        agTimelineLineProgress = $('.js-timeline_line-progress'),
        agTimelinePoint = $('.js-timeline-card_point-box'),
        agTimelineItem = $('.js-timeline_item'),
        agOuterHeight = $(window).outerHeight(),
        // agHeight = $(window).height(),
        f = -1,
        agPosY = 0,
        agFlag = false;

        function fnOnScroll() {
            agPosY = $(window).scrollTop();
            fnUpdateFrame();
        }
          
        function fnOnResize() {
            agPosY = $(window).scrollTop();
            // agHeight = $(window).height();
        
            fnUpdateFrame();
        }
    
        function fnUpdateWindow() {
        agFlag = false;
    
        agTimelineLine.css({
            top: agTimelineItem.first().find(agTimelinePoint).offset().top - agTimelineItem.first().offset().top,
            bottom: agTimeline.offset().top + agTimeline.outerHeight() - agTimelineItem.last().find(agTimelinePoint).offset().top
        });
    
        f !== agPosY && (f = agPosY);
        fnUpdateProgress()
        }
          
        function fnUpdateProgress() {
        var agTop = agTimelineItem.last().find(agTimelinePoint).offset().top;
    
        var i = agTop + agPosY - window.scrollY;
        var a = agTimelineLineProgress.offset().top + agPosY - window.scrollY;
        var n = agPosY - a + agOuterHeight / 2;
        i <= agPosY + agOuterHeight / 2 && (n = i - a);
        agTimelineLineProgress.css({height: n + "px"});
    
        agTimelineItem.each(function () {
            var agTop = $(this).find(agTimelinePoint).offset().top;
    
            (agTop + agPosY - window.scrollY) < agPosY + .5 * agOuterHeight ? $(this).addClass('js-ag-active') : $(this).removeClass('js-ag-active');
        })
        }
    
        function fnUpdateFrame() {
            agFlag || requestAnimationFrame(fnUpdateWindow);
            agFlag = true;
        }

        window.addEventListener('scroll', function () {
            fnOnScroll();
          });
      
        window.addEventListener('resize', function () {
            fnOnResize();
          });
      
          return () => {
            window.removeEventListener('scroll', function () {
                fnOnScroll();
              });
          
            window.removeEventListener('resize', function () {
                fnOnResize();
              });
          
          }

    }, [])


    return (
        <Grid sx={{backgroundColor:'#FEFBEB', width:'100%'}}>
            <Grid sx={{px: !isMobile ? 22.5: 2.5 , pt: !isMobile ? '5em' : '4em',pb: !isMobile ? '0em' : '4em', width:'100%', background: !isMobile && 'url(/assets/images/milestonebg.png) no-repeat', backgroundPosition:'top left'}}>
                <Typography className='titleStyle title-color' sx={{ textAlign: !isMobile ? 'center' : 'left', fontSize: !isMobile ? '2.5em !important': '1.5em !important', pb:2}}>Our journey</Typography>
                
                <div class="ag-timeline-block">
                    <section class="ag-section">
                        <div class="ag-format-container">
                        <div class="js-timeline ag-timeline">
                            <div class="js-timeline_line ag-timeline_line">
                            <div class="js-timeline_line-progress ag-timeline_line-progress"></div>
                            </div>
                            <div class="ag-timeline_list">
                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                    <div class="ag-timeline-card_point"></div>
                                </div>
                                <div class="ag-timeline-card_meta-box">
                                    <div class="ag-timeline-card_meta">1990</div>
                                </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                        <div class="ag-timeline-card_title">Getting started</div>
                                        <div class="ag-timeline-card_desc">
                                            Unique Engineers is formed with its first office in a simple shipping container.
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>

                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                    <div class="ag-timeline-card_meta-box">
                                        <div class="ag-timeline-card_meta">1991</div>
                                    </div>
                                    <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                        <div class="ag-timeline-card_point"></div>
                                    </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                        <div class="ag-timeline-card_title">Contracted by Mitsui Japan</div>
                                        <div class="ag-timeline-card_desc">
                                            Unique Engineers secures the HVAC contract of UPSEB Anpara, awarded by Mitsui Japan, establishing its reputation in the industry.
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>

                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                    <div class="ag-timeline-card_point"></div>
                                </div>
                                <div class="ag-timeline-card_meta-box">
                                    <div class="ag-timeline-card_meta">'90-'95</div>
                                </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                    <div class="ag-timeline-card_title">Gaining recognition</div>
                                    <div class="ag-timeline-card_desc">
                                    We gain recognition in the thermal power belt of India, becoming a reliable HVAC Partner for NTPC and BHEL which was controlled by Rolls Royce Power Engineering Group, UK.
                                    </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>

                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                <div class="ag-timeline-card_meta-box">
                                    <div class="ag-timeline-card_meta">2000</div>
                                </div>
                                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                    <div class="ag-timeline-card_point"></div>
                                </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                    <div class="ag-timeline-card_title">Projects across sectors</div>
                                    <div class="ag-timeline-card_desc">
                                    We carry out central air conditioning projects across Healthcare, Industrial sectors, Airports and Educational institutions.
                                    </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>

                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                    <div class="ag-timeline-card_point"></div>
                                </div>
                                <div class="ag-timeline-card_meta-box">
                                    <div class="ag-timeline-card_meta">2005</div>
                                </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                        <div class="ag-timeline-card_title">Independent entity</div>
                                        <div class="ag-timeline-card_desc">
                                        We begin manufacturing of Air side products at our IMT Manesar factory.
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>

                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                <div class="ag-timeline-card_meta-box">
                                    <div class="ag-timeline-card_meta">2010</div>
                                </div>
                                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                    <div class="ag-timeline-card_point"></div>
                                </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                    <div class="ag-timeline-card_title">Role in Commonwealth Games</div>
                                    <div class="ag-timeline-card_desc">
                                    We play a key role during the Commonwealth Games by providing air conditioning services to stadiums and five-star hotels in and around Delhi.
                                    </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>

                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                    <div class="ag-timeline-card_point"></div>
                                </div>
                                <div class="ag-timeline-card_meta-box">
                                    <div class="ag-timeline-card_meta">2012</div>
                                </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                    <div class="ag-timeline-card_title">PMSSY hospitals</div>
                                    <div class="ag-timeline-card_desc">
                                    Recognizing our potential, HSCC (India) Limited entrusts with 20 PMSSY hospitals and five AIIMS and cancer hospitals.
                                    </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>

                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                    <div class="ag-timeline-card_meta-box">
                                        <div class="ag-timeline-card_meta">2019</div>
                                    </div>
                                    <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                        <div class="ag-timeline-card_point"></div>
                                    </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                    <div class="ag-timeline-card_title">MEP contract for MOPA International Airport, Goa</div>
                                    <div class="ag-timeline-card_desc">
                                        We secure the prestigious MEP contract for the MOPA International Airport at Goa which is valued at Rs 130-plus crore.
                                    </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>

                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                    <div class="ag-timeline-card_point"></div>
                                </div>
                                <div class="ag-timeline-card_meta-box">
                                    <div class="ag-timeline-card_meta">2020</div>
                                </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                    <div class="ag-timeline-card_title">Key role in pandemic</div>
                                    <div class="ag-timeline-card_desc">
                                    During COVID-19, we play a crucial role, ensuring uninterrupted operations and maintenance of HVAC systems at various hospitals across the country.
                                    </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>

                            <div class="js-timeline_item ag-timeline_item">
                                <div class="ag-timeline-card_box">
                                    <div class="ag-timeline-card_meta-box">
                                        <div class="ag-timeline-card_meta">2023</div>
                                    </div>
                                
                                    <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                                        <div class="ag-timeline-card_point"></div>
                                    </div>
                                </div>
                                <div class="ag-timeline-card_item">
                                <div class="ag-timeline-card_inner">
                                    <div class="ag-timeline-card_info">
                                    <div class="ag-timeline-card_title">Key value of Central Vista</div>
                                    <div class="ag-timeline-card_desc">
                                        We have recently been awarded HVAC and firefighting works at the upcoming Central Common Secretariat & Executive Enclave under the Central Vista.
                                    </div>
                                    </div>
                                </div>
                                {/* <div class="ag-timeline-card_arrow"></div> */}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                </div>
            </Grid>
            
        </Grid>
    )

}

export default Milestone;