import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Waypoint } from 'react-waypoint';
import Testimonial from '../testimonial';
// import ProjectSlick from '../projects';
import FootPrint from '../../footprint';

const IbmsService = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/ibms/atc-tower-ib.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>ATC Tower, Kolkata Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Kolkata, West Bengal</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/ibms/aiims-ib.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>AIIMS, Jammu</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Vijaypur Samba Distt, Jammu & Kashmir</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/ibms/goa-ib.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/ibms/nalanda-ib.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Nalanda University, Rajgir </Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Rajgir, Bihar</Typography>
        </Grid>
        
    ]
   
return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className='title-div'
                sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px:!isMobile && 12.5, background:!isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography sx={{color:'#535353'}}>IBMS / LV</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em',pb: !isMobile ? '8em' : '6em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{pb:2}}>IBMS / LV</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            Unique Engineers has executed numerous projects with IBMS (Integrated Building Management Systems) by effectively merging technology and expertise. With the help of our professional teams, we have transformed buildings into intelligent and efficient structures that enhance occupant comfort and reduce operational costs.
                        <br/><br/>
                            Our IBMS services include:
                            <br/><br/>
                            <span style={{fontWeight:'500'}}>Energy Management:</span> We implement advanced energy management systems that optimize energy consumption.
                            <br/>
                            <span style={{fontWeight:'500'}}>HVAC Control:</span> Employ our IBMS solutions to regulate HVAC systems to guarantee optimal comfort with efficiency.
                            <br/>
                            <span style={{fontWeight:'500'}}>Security and Access Control:</span> Our IBMS seamlessly integrates security systems providing enhanced security and real-time management.
                            <br/>
                            <span style={{fontWeight:'500'}}>Fire and Safety Management:</span> Fuse fire detection, suppression systems, emergency lighting, and safety mechanisms ensure prompt response to fire incidents, enhancing overall safety of the building.
                            <br/>
                            <span style={{fontWeight:'500'}}>Building Automation:</span> Streamline the control and supervision of HVAC, lighting, security, and energy systems from a single hub.
                            <br/><br/>
                            What sets us apart
                        </Typography>
                        <ul>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Energy management systems to ensure significant energy savings and reduced operational costs</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Precise management of heating, cooling and ventilation ensures optimum comfort and minimal energy waste</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Intelligent lighting control systems provides for efficient lighting and reduced electricity consumption</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>IBMS enables centralized control of various systems, including HVAC, lighting, security, and energy management.</li>
                        </ul> 
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px:!isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px:!isMobile ? 10 : 0, pt: !isMobile ? '5em' : '3em', pb: !isMobile ? '5em' : '3em' }}>
                    <Grid item sx={{}}>
                        <Typography className='titleStyle title-color' sx={{ pb:'1em', textAlign: 'center'}}>Overall building transformations</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb: !isMobile && '2em' }}>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>100 million</Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Square feet <br/> of area air conditioned</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="8642519073">1</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="0252119170">0</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--3" data-fake="8242209043">0</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--4" style={{width:'100%', animation:'none'}} data-fake="+">+</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Easy breathing <br/> basements </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="5642210061">5</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8622214079">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Industries <br/> solutioned for</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <ProjectSlick /> */}
            <FootPrint title='Our projects' imageArray = {slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            <Testimonial title={'Words of satisfaction'}  />
        </Grid>
    </Grid>
)

}

export default IbmsService;