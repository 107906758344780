import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/aboutuscomponents/banner';
import AboutInformation from '../../components/aboutuscomponents/aboutinformation';
import Vision from '../../components/aboutuscomponents/vision';
import GetUsKnow from '../../components/aboutuscomponents/getus';
import Leading from '../../components/aboutuscomponents/leading';
import Founders from '../../components/aboutuscomponents/founders';
import Milestone from '../../components/aboutuscomponents/milestones';
const AboutUs = () => {

    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])

    return (
        <Grid container sx={{justifyContent:'center'}}>
            <Banner img={'/assets/images/aboutusbanner.jpg'} title='' />
            {/* <Grid item sx={{px:10}}> */}
                <AboutInformation />
                <Vision />
                <GetUsKnow />
                <Leading />
                <Founders />
                <Milestone />
            {/* </Grid> */}
        </Grid>
    )

}

export default AboutUs;