/**
 * Author: Saurabh Kumar
 * Component Name: InputComment
 * Description: Text Editor with avatar to use on post commenting system.
 * Dependency NPM: draftJS, React draft wysiwyg
 */

import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback} from 'react';
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState,convertToRaw, } from "draft-js";
// import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { makeStyles } from '@mui/styles';
import {Container, Grid, Button, Typography, Avatar} from '@mui/material';
// import StarBorder from '@mui/icons-material/StarBorder';

import { } from './inputComment.css';

const InputComment = React.memo(forwardRef((props, ref) => {
    const {insertPostItem, commentAvatar, commentId, cancelCommentItem} = props; // Properties from parent class
    const [formValue, setFormValue] = useState({name:'', email:'', message:''})
    const [inPutAvatar, setAvatar] = useState(commentAvatar); // store avatar path to display user's pic.
    // const [editorsState, setEditorState] = useState(() =>
    //     EditorState.createEmpty()
    // );
    // const [isDisabled, setIsDisabled] = useState(true); // Makes the post button enabled/disabled
    
    useEffect(() => {
        setAvatar(commentAvatar);
    },[commentAvatar])

    
    useImperativeHandle(ref, () => ({
        getCommentUser(pValue) {
          setAvatar(pValue);
        }    
      }));

    /**
     * @summary : Convert the text content to raw html text to embed in database and .
     * @param 
     */
    // const callChangeHandler = (e) => {
    //     if(convertToRaw(editorsState.getCurrentContent()).blocks[0].text !== ""){
    //         setIsDisabled(false)
    //     } else {
    //         setIsDisabled(true)
    //     }
    // }
    /**
     * Cancel comment Box to avoid editing...
     */
    // const cancelCommentHandler = () => {
    //     cancelCommentItem();
    // }
    const handleChange = (e) =>{
        const {name, value} = e.target;
        console.log(name, value);
        const formData = {...formValue};
        formData[name] = value;
        setFormValue(formData);
    }
    /**
     * Submit comments to parent class file with all required parameters.
     * Params: img, commentId, publishTime, content 
     */
    const submitCommentHandler = useCallback(() =>{
        const {name, email, message} = formValue;
        const insertItem = {
            img:"", 
            commentId:commentId, 
            author:name,
            author_email: email,
            publishTime: Date().toString(), 
            content:message};
        // setEditorState(EditorState.createEmpty());
        // setIsDisabled(true);
        insertPostItem(insertItem);
    }, [commentId, formValue, insertPostItem])

    const classess = useStyles();
    return (
        <Container component="div" style={{maxWidth:700, width:'100%'}} className={classess.container}>
            <Grid item xs={12} style={{marginBottom:20, display:"flex"}}>
                <Grid item xs={12} >
                    <Typography variant='h5' component={"h5"} sx={{width:'100%'}}>Leave a Reply</Typography>
                </Grid>
                <Grid item style={{width:"100%"}}>
                </Grid>
            </Grid>
            <Grid>
                <form >
                    <div className='post-form'>
                        <div className='post-div'>
                            <label htmlFor='name'>Name*</label><br/>
                            <input style={{height:40, width:'100%', marginBottom:5}} type="text" name="name" id="name" value={formValue.name} onChange={handleChange} required />
                            <br />
                            <label htmlFor='email'>Email*</label><br/>
                            <input style={{height:40, width:'100%'}} type="email" name="email" id="email" value={formValue.email}  onChange={handleChange} required validate />
                        </div>
                        <div className='post-div'>
                            <label htmlFor='message'>Message*</label><br/>
                            <textarea name="message" id="message" style={{height:108, width:'100%', resize:'none', fontSize:'1em', fontFamily:'Inter'}} col='5' value={formValue.message} required onChange={handleChange}></textarea>
                        </div>
                    </div>
                    <Grid item xs={12} sx={{display:'flex', gap:'10px', marginTop:'1em'}}>
                        <Button variant='contained' className='normal-text' 
                            sx={{backgroundColor:'#1D5A93', letterSpacing:'3px', color:'#FFFFFF !important', fontSize:'0.9em !important' }}
                            onClick={cancelCommentItem}>Cancel
                        </Button>
                        <Button variant='contained' className='normal-text' 
                            sx={{backgroundColor:'#1D5A93', letterSpacing:'3px', color:'#FFFFFF !important', fontSize:'0.9em !important' }}
                            onClick={submitCommentHandler}>Submit
                        </Button>
                    </Grid>
                    
                </form>
            {/* <Editor 
                toolbar={{options:['emoji', 'inline','link'],
                    emoji:{className: classess.inputButton },
                    inline: {
                        options: ['italic', 'bold', 'strikethrough'],
                        italic:{className:classess.inputButton,},
                        bold:{className:[classess.inputButton],},
                        strikethrough:{className:classess.inputButton,}
                    },
                    link: {options: ['link'],link: { className: classess.inputButton }}
                }}
                toolbarStyle={{border:"none", borderTop:"1px solid"}} editorState={editorsState}
                toolbarCustomButtons={[
                    <Grid style={{display:"flex", alignItems:"center", position:"absolute", right:"48px"}}>
                        <Typography style={{fontSize:12, fontFamily:"URWGeometric-Regular", marginRight:20, color:"#231f20"}}>@mention user to notify them | Some HTML is OK </Typography>
                        <Button type="button"
                        variant="contained"
                        color="primary"
                        className={["button", classess.button].join(" ")} style={{paddingLeft:24, paddingRight:24, marginRight: 20,  backgroundColor: '#a70237', color: '#FFFFFF', display: commentId !== 0 ? 'block': 'none' }} onClick={cancelCommentHandler} >Cancel</Button>
                    <Button type="button"
                        variant="contained"
                        color="primary"
                        className={["button", classess.button].join(" ")} style={{paddingLeft:24, paddingRight:24, backgroundColor: isDisabled ? '#f1d7df' : '#a70237', color: '#FFFFFF'}} disabled={isDisabled} onClick={submitCommentHandler} >Post</Button>
                    </Grid>]}
                onChange={callChangeHandler}
                onEditorStateChange={setEditorState}
                editorClassName={classess.editiorStyle}
                mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: authorsName,
                  }}
            /> */}
            </Grid>
        </Container>
    )
}, () => true))
const useStyles = makeStyles(()=>({
    inputButton:{
        border:"none",
    }, 
    button:{

    },
    container:{
        width:"100%", 
        maxWidth:"100%", 
        paddingLeft:16, 
        paddingBottom:5, 
        paddingTop:14, 
        paddingRight:16
    },
    editiorStyle:{
        fontFamily:"Inter", fontSize:14
    }
}))

export default InputComment;
