import React from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const Approach = () => {

    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
                <Grid item className="title-div" 
                    // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
                >
                    <Grid item sx={{px: !isMobile && 12.5, pt:'1em'}}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                            <Link to={'/about'} className='normal-text title-color' style={{textDecoration:'none'}} >About Us</Link>
                            <Typography variant='body2' component={'p'} className="normal-text" sx={{color:'#535353'}}>Our Approach</Typography>
                        </Breadcrumbs>
                        <Grid item xs={12} sx={{pt:'2em', pb:'1em'}}>
                            <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ fontSize:36, pb:2}}>Our Approach</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:2, lineHeight:2}}>
                                With every project, we redefine what's possible in the world of MEP solutions. Keeping ethics, environment - the life force or and prana at the heart of our operations, we design future-ready solutions. Explore what makes us unique in a true sense.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{px:!isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'1em'}}>
                    <Grid item sx={{px:!isMobile && 12.5, pt:'1em'}}>
                        <Grid item xs={12} sx={{pt: !isMobile && '1em', pb: !isMobile ? '2em' : '1em'}}>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:'flex', flexFlow: isMobile && 'column', borderTop: !isMobile && '1px solid #DAE6EF'}}>
                                <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography className='titleStyle title-color' sx={{ fontSize:36, lineHeight:'1.4em', pt: !isMobile && '1em',  pb:'1em'}}>Step into a space alive with the power of 'Prana' - the life force</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                        At Unique Engineers, we design solutions inspired by the five elements of nature - earth, water, fire, air and ether. ‘Earth’ signifies stability and foundational support, like our plumbing solutions. ‘Water’ represents life, rejuvenation and refreshment, which air conditioning provides by creating comfortable and refreshing indoor environments. 
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} sx={{textAlign:'-webkit-center', pt:0, pb:0, ml: !isMobile? 2 : 0}}>
                                    <Grid item xs={12} sx={{textAlign:'-webkit-center', background: !isMobile ? 'url(/assets/images/getknowbg.png) no-repeat' : 'url(/assets/images/getknowbgmobile.png) no-repeat' , width:'100%', maxWidth:525, height:'auto', maxHeight:396, backgroundPosition: !isMobile ? 'bottom left' : 'center', pt:!isMobile ? 6 : 2, pb: !isMobile ? 18: 2, backgroundSize: isMobile && 'contain' }}>
                                    <div style={{position:'relative', height:250}}>
                                        <div className='img-wrapper' style={{ display: isMobile && 'flex', justifyContent: isMobile && 'center', alignItems: isMobile && 'center' }}>
                                        <img src='/assets/images/brightercycle.png' alt='' style={{width:'45%', mixBlendMode: 'darken'}}/>
                                        {/* <div style={{background:'url(/assets/images/brightercycle.png) no-repeat', mixBlendMode: 'darken', width:235, height:243, transform: isMobile && 'scale(0.8)'}}></div> */}
                                        </div>
                                    </div>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:'1em', lineHeight:2}} >‘Fire’ represents transformation and energy, provided by our fire alarm and firefighting systems, by preventing fires that can cause damage. ‘Air’ signifies freedom, movement and inspiration; like our ventilation systems enabling the free flow of air within a building. ‘Ether’ stands for space, infinite potential, just like automation systems for regulating various systems seamlessly, thus creating an optimized building, that pulsates with life.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item sx={{width:'100%', maxWidth:1440, px: !isMobile ? 10 : 2.5, mt: !isMobile && '2em', pt:'2em', pb:'1em'}}>
                    <Grid item sx={{px: !isMobile && 12.5, width:'100%', display:'flex', justifyContent:'center', pt:'1em'}}>
                        <Grid item xs={12}>
                            <Typography className='titleStyle title-color' sx={{ pb:2 }}>Our promise of quality</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:'1em', lineHeight:2}} >We strive to maintain the highest standards of quality at all times and thus be recognized as a trusted and reliable MEP service provider. 
                            <br/>
                                Our Quality Policy focuses on: 
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{px:!isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'0.5em', pb:'3em'}}>
                    <Grid item xs={12} sx={{display:'flex', px:!isMobile && 12.5, flexFlow: isMobile && 'column',columnGap:'2em', rowGap: '1em'}}>
                        <Grid item xs={12} sm={4} sx={{backgroundColor:'#D8F2FF', padding:'2em'}}>
                            <Typography className='normal-text' sx={{mb:1, lineHeight:2}}>
                            Ensuring strict compliance with all relevant regulations and industry standards.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{backgroundColor:'#D8F2FF', padding:'2em'}}>
                            <Typography className='normal-text' sx={{mb:1, lineHeight:2}}>
                            Implementing safe, efficient systems and processes, in order to deliver technical expertise coupled with superior workmanship within project timelines.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{backgroundColor:'#D8F2FF', padding:'2em'}}>
                            <Typography className='normal-text' sx={{mb:1, lineHeight:2}}>
                            Improving continuously through ongoing training and upskilling of our teams, and fostering a culture of open communication and collaboration.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item sx={{width:'100%', backgroundColor:'#F4F8FA', mt:'2em', pt:'2em', pb:'4em', display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{px:!isMobile ?22.5 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}>
                        <Grid item xs={12}>
                            <Typography className='titleStyle title-color' sx={{ pb:2 }}>Sustainability, ethics and excellence</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:'1em', lineHeight:2}} >We stand committed to preserving our environment and pioneering sustainability. By adopting eco-friendly practices and technologies, along with responsible resource management, we contribute to a greener future. 
                            <br />
                                Ethical practices form an integral part of our company culture. We conduct our business with utmost integrity and transparency, while maintaining ethical relationships with our clients, employees, suppliers, and stakeholders. As your associate, you can rest assured that our operations have the highest safety standards, regulatory compliance, and social responsibility.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{width:'100%', backgroundColor:'#DAFAD8', px: !isMobile ? 10 : 2.5, pt: !isMobile ? '5em' : '4em', pb: !isMobile && '3em', display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{width:'100%', maxWidth:1440, px:!isMobile && 22.5, pb:'3em', background: !isMobile && 'url(/assets/images/approach-linepattern.png) no-repeat', backgroundPosition:'bottom right'}}>
                        <Typography className='titleStyle title-color' sx={{ pb:3 }}>Single window MEP services</Typography>
                        <Grid item xs={12} sx={{ display:'flex', flexFlow: isMobile ? 'column-reverse' : 'column'}}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:'2em'}} > We aspire to be the premier single-window solution provider for MEP services. Our objective is to offer comprehensive mechanical, electrical, and plumbing services under one roof, adding significant value to our clients' projects throughout the entire supply chain. Through seamless coordination, expert knowledge, and innovative solutions, we strive to surpass our clients' expectations and deliver customer-centric MEP services.
                                </Typography>
                            </Grid>
                                <Grid item xs={12}>
                                    <div style={{position:'relative', height:'100%', minHeight: isMobile? 140 : 300}}>
                                        <div className='img-wrapper'>
                                            <img src='/assets/images/approach-content.png' alt='approach-content' style={{width:'100%'}}/>
                                        </div>
                                    </div>
                                </Grid>   
                            
                        </Grid>
                        
                    </Grid>    
                </Grid>
            
            </Grid>
            
        </Grid>
    )

}

export default Approach;