export function validateEmail (pValue) {
    // var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  // console.log(pValue);
  if (pValue.match(validRegex)) {
    // alert("Valid email address!");
    // document.form1.text1.focus();
    return true;
  } else {
    // alert("Invalid email address!");
    // document.form1.text1.focus();
    return false;
  }
}