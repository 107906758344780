import { Grid, Typography } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';

const GetusKnow = () => {

    const settings = {
        dots: true,
        arrows: false,
        className: 'center',
        centerMode: false,
        infinite: true,
        centerPadding: '50px',
        slidesToShow: Math.min(2, 1),
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: Math.min(1),
        swipeToSlide: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerMode: false,
            },
          },
        ],
      };
    const slides = [
        <div>
            <Typography variant='body2' component={'p'} className='nomral-text what-text' sx={{pb:'1em'}} >
                We provide air conditioning and ventilation solutions across 32 industries.
            </Typography>
        </div>,
        <div>
            <Typography variant='body2' component={'p'} className='nomral-text what-text' sx={{pb:'1em'}} >
                We deliver customer-focused innovative solutions throughout the supply chain.
            </Typography>
        </div>,
        <div>
            <Typography variant='body2' component={'p'} className='nomral-text what-text' sx={{pb:'1em'}} >
            We are committed to eco-friendly technologies and practices.
            </Typography>
        </div>,
        <div>
            <Typography variant='body2' component={'p'} className='nomral-text what-text' sx={{pb:'1em'}} >
              Our aspiration: to be a single-window solution provider for the range of MEP services.
            </Typography>
        </div>
    ]
      
    return (
        <Grid item className='what1-style' sx={{backgroundSize:'contain', background:'url(/assets/images/getusknowbg.png) no-repeat', width:'100%'}}>
            <Grid className='what-style' sx={{backgroundColor:'#DAFAD8', float:'right', pt:7.5, pr:'3em'}}>
                <Typography variant='h4' component={'h4'} className='titleStyle title-color' sx={{mb:4}}>What make us unique</Typography>
                <Slider {...settings}> {slides} </Slider>
                {/* <Typography variant='h4' component={'h4'} className='normal-text' sx={{fontSize:'1.5em !important', fontWeight:'500 !important', mb:2}}>We provide air conditioning and ventilation solutions across 32 industries</Typography> */}
            </Grid>
        </Grid>
    )


}

export default GetusKnow;