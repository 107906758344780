import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint'
import Testimonial from '../testimonial';
import FootPrint from '../../footprint';
// import ProjectSlick from '../projects';

const HtltService = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/stt-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>STT, Mumbai</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Navi Mumbai, Maharashtra</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/atc-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>ATC Tower, Kolkata Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Kolkata, West Bengal</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/goa-e.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/bhita-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>ESIC Medical College & Hospital, Bihta</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Patna, Bihar</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/sky-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Sky Mansion, New Delhi</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Chattarpur, New Delhi</Typography>
        </Grid>
        
    ]
    
return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className='title-div'
                // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px:!isMobile && 12.5, background: !isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography variant='body2' component={'p'} sx={{color:'#535353'}}>Electrical</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em',pb:'8em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2 }}>Electrical</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            Our comprehensive electrical services encompass both High Tension (HT) and Low Tension (LT) systems which form a vital component of our MEP offerings. This integrated approach allows us to create unique solutions for each project and align with specific load requirements. By integrating electrical systems into our services, we ensure a holistic and efficient approach that adds significant value to the project's overall execution.
                            <br/><br/>
                            One of the key advantages of our integrated approach is the improved coordination between HVAC and electrical services. This seamless coordination leads to smoother project execution and enhanced efficiency. Our team of experienced professionals ensures that all aspects of the project, including electrical and HVAC, are planned, executed, and coordinated effectively.
                            <br/><br/>
                            What sets us apart
                        </Typography>
                        <ul>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Streamlined project execution and enhanced efficiency</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Depth of understanding of project intricacies</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Exceptional results with our comprehensive knowledge and track record in MEP solutions</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Project delivery that upholds the highest standards of quality and functionality.</li>
                        </ul> 
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px: !isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px:!isMobile ? 10 : 0, pt: !isMobile ? '5em' : '3em', pb: !isMobile ? '5em' : '3em'  }}>
                    <Grid item sx={{}}>
                        <Typography className='titleStyle title-color' sx={{ pb:'1em', textAlign: 'center'}}>Safety served with comfort</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb: !isMobile && '2em' }}>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="4642210061">4</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stadiums <br/> made spacious</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="6642212862">6</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8641218062">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stress-free and <br/> comfortable hospitals </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="5612210038">5</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8824918502">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Industries <br/> innovated for</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FootPrint title='Our projects' imageArray = {slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            <Testimonial title={'Words of satisfaction'}  />
        </Grid>
    </Grid>
)

}

export default HtltService;