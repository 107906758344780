import React from 'react';
import { Grid, Typography, Button, Card, CardContent }from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';


const Founders = () => {

    const navigate = useNavigate();
    const handleNavigate = () =>{
        navigate('/leadership')
    }

    return (
        <Grid item sx={{px: isMobile ? 2.5 : 22.5, py: !isMobile ? '5em' : '3em', width:'100%', maxWidth:1440}}>
            <Typography className='titleStyle title-color' sx={{pb:2}}>Know our founders</Typography>
            <Grid item sx={{display:'flex', width:'100%', justifyContent:'center'}}>
                <Grid item className="expertise-column expertise-Grid" sx={{width:'100%', maxWidth:'80%', display:'flex', columnGap:4}}>
                    <Grid item xs={12} sm={4}>
                        <Card elevation={0} sx={{my:4 }}>
                            <div style={{position:'relative', height:300}}>
                                <div className='img-wrapper'>
                                    <img src='/assets/images/rajiv.png' alt='Blog' style={{width:'100%'}}/>
                                </div>
                            </div>
                            <CardContent sx={{background:'url(/assets/images/foundertitlebg.png) no-repeat', backgroundSize: !isMobile ? 'contain' : 'cover'}}>
                                <Typography className='normal-text' sx={{ fontWeight:'500 !important', fontSize:'1.5em !important'}}>Rajiv Gupta</Typography>
                                <Typography className='normal-text' sx={{ fontWeight:'500 !important' }}>Director</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card elevation={0} sx={{my:4 }}>
                        <div style={{position:'relative', height:300}}>
                                <div className='img-wrapper'>
                                    <img src='/assets/images/pradeep.png' alt='Blog' style={{width:'100%'}}/>
                                </div>
                            </div>
                            <CardContent sx={{background:'url(/assets/images/foundertitlebg.png) no-repeat', backgroundSize:!isMobile ? 'contain' : 'cover'}}>
                                <Typography className='normal-text' sx={{ fontWeight:'500 !important', fontSize:'1.5em !important'}}>Pradeep Sharma</Typography>
                                <Typography className='normal-text' sx={{ fontWeight:'500 !important' }}>Director</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card elevation={0} sx={{my:4 }}>
                            <div style={{position:'relative', height:300}}>
                                <div className='img-wrapper'>
                                    <img src='/assets/images/manmohan.png' alt='Blog' style={{width:'100%'}}/>
                                </div>
                            </div>
                            <CardContent sx={{background:'url(/assets/images/foundertitlebg.png) no-repeat', backgroundSize:!isMobile ? 'contain' : 'cover'}}>
                                <Typography className='normal-text' sx={{ fontWeight:'500 !important', fontSize:'1.5em !important' }}>Manmohan Jindal</Typography>
                                <Typography className='normal-text' sx={{ fontWeight:'500 !important' }}>Director</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{width:'100%'}}>
                
            </Grid>
            <Grid item sx={{}}>
            <Typography variant='body2' component='p' className='normal-text' sx={{mb:4, lineHeight:2}}>
                Our founders set an example for us to explore untapped potentials, seek new vistas of opportunities. With well-rounded profiles, they drive not only the company's growth but also contribute to the betterment of the community. Beyond their professional endeavors, they empower team members with empathy and understanding. 
            </Typography>
            <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{pl:0}} onClick={()=> handleNavigate()}>
                <Typography variant='body2' component={'p'} className='normal-text'
                    sx={{textTransform:'capitalize', lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                    Read More
                </Typography>
                <img src='/assets/images/right-arrow.png' alt='right icon' />
            </Button>
            </Grid>
        </Grid>
    )
}

export default Founders;