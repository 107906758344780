import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint'
import Testimonial from '../testimonial';
// import ProjectSlick from '../projects';
import FootPrint from '../../footprint';

const FireFighting = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/firefighting/bitsmum-f.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>BITSoM, Mumbai </Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Mumbai, Maharashtra </Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/firefighting/exec-f.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Executive Enclave , PMO House</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>New Delhi</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/firefighting/goa-f.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>
        
    ]

return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className='title-div'
                // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px:!isMobile && 12.5, background: !isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography variant='body2' component={'p'} sx={{color:'#535353'}}>Firefighting</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em',pb:'8em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2}}>Firefighting</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            We have taken a step forward by adding firefighting services to our array of offerings. Capitalizing on our extensive experience in delivering top-notch piping solutions through our HVAC team, venturing into firefighting services has been a natural progression for us.
                        <br/><br/>
                            We understand that fire safety is non-negotiable. We utilize high-quality solutions sourced from available globally, enabling us to provide reliable and efficient fire safety systems that comply with industry standards and regulations. By combining firefighting services with HVAC and electrical services, we simplify the process for our business partners. This integrated approach streamlines coordination efforts and eliminates potential conflicts, leading to better project execution and smoother workflows.
                            <br/><br/>
                            What sets us apart
                        </Typography>
                        <ul>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Utilization of high-quality solutions from the international market to ensure reliable and efficient fire safety systems</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Integration of fire fighting, HVAC, and electrical services streamlines coordination for clients, architects and others</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>With a one-stop solution for MEP needs, project workflows are smooth, leading to efficiency and cost effective implementation.</li>
                        </ul> 
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px: !isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px: !isMobile ? 10 : 0, pt: !isMobile ? '5em' : '3em', pb: !isMobile ? '5em' : '3em'  }}>
                    <Grid item sx={{}}>
                        <Typography variant='body2' component={'p'} className=' titleStyle title-color' sx={{pb:'1em', textAlign: 'center'}}>Fire safety brings security</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb: !isMobile && '2em' }}>
                            <Grid item xs={4} sx={{}}>
                                <Typography className=' titleStyle title-color' sx={{pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="6642212862">1</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="0641218062">0</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--3" data-fake="0692750268">0</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--4" style={{width:'100%', animation:'none'}} data-fake="+">+</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:2, textAlign:'center'}}>Basements <br/>ventilated</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color' sx={{pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="5612210038">5</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8639118002">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Industries <br/>provided solutions</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="2642210061">2</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="5612630828">5</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Secure <br/>5-star hotel spaces</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <ProjectSlick /> */}
            <FootPrint title='Our projects' imageArray = {slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            <Testimonial title={'Words of satisfaction'}  />
        </Grid>
    </Grid>
)

}

export default FireFighting;