import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import Approach from '../../components/ourapproach'

const OurApproach = () => {
    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])

    return (
        <Grid container style={{width:'100%'}}>
            <Banner img={'/assets/images/our-approach-banner.jpg'} title='' />
            <Approach />
        </Grid>
    )

}

export default OurApproach;