import React from 'react';
import { Grid, Typography } from '@mui/material';
import Slider from 'react-slick';
import {} from './testimonial.style.css';
import { isMobile } from 'react-device-detect';

const Testimonial = (props) => {
    const { title } = props;


    const settings = {
        dots: true,
        dotsClass: 'slick-dots',
        arrows: false,
        className: 'center',
        centerMode: false,
        infinite: true,
        centerPadding: '50px',
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 500,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
            },
          },
        ],
      };

    return (
        <>
        <Grid item sx={{px:!isMobile ? 10 : 2.5, maxWidth: !isMobile ? 1440 : '100%'}}>
                <Grid item sx={{width:'100%', px: !isMobile ? 10 : 0, pt: !isMobile ? '6em' : '2em', pb:'4em'}}>
                    <Grid item className="expertise-column"
                     sx={{px: !isMobile ? 12.5 : 2.5, pb:'3em', pt: !isMobile ? '3em' : '2em', backgroundColor:'#DAFAD8', borderRadius:4, display:'flex'}}>
                        <Grid item xs={12} sm={9}>
                          <Typography className='titleStyle title-color' sx={{pb:2}}>{title}</Typography>
                          <Slider {...settings}>
                              <Grid item sx={{display:'flex',}}>
                                  <Grid item xs={12} sm={12}>
                                  
                                  <Typography className='normal-text' sx={{mb:2, lineHeight:'1.7em !important', pb:'1.5em' }}>
                                  “For the past three decades, Unique has been our trusted and specialized HVAC vendor, and throughout this time, they have consistently delivered unmatched quality and adhered to strict timelines. Whenever we faced any challenging situations, Unique has stood by us, offering unwavering support. We choose them over larger corporate entities because of their agility and ability to swiftly respond to our needs.”
                                  </Typography>
                                  <Typography className='titleStyle text-color' sx={{fontSize: '1.25em !important', lineHeight:'1.7 !important', fontFamily:'Inter !important',  mt:'1em'}}>Shobhit Uppal</Typography>
                                  <Typography className='normal-text' sx={{fontSize: '1.25em !important', lineHeight:2, opacity:'0.6'}}> Deputy Managing Director, ACIL </Typography>
                                  </Grid>
                              </Grid>
                              <Grid item sx={{display:'flex',}}>
                                  <Grid item xs={12} sm={12}>
                                  
                                  <Typography className='normal-text' sx={{mb:2, lineHeight:'1.7em !important', pb:'1.5em' }}>
                                    “Unique surpassed our expectations by mobilizing tremendous and unimaginable resources, ensuring timely completion of the job despite the constraints imposed by the pandemic. The system they implemented demonstrated flawless performance when tested. We wish them continued success in all future endeavors and eagerly anticipate partnering with them for all our forthcoming projects.”
                                  </Typography>
                                  <Typography className='titleStyle text-color' sx={{fontSize: '1.25em !important', lineHeight:'1.7 !important', fontFamily:'Inter !important',  mt:'1em'}}>Shiridhar</Typography>
                                  <Typography className='normal-text' sx={{fontSize: '1.25em !important', lineHeight:2, opacity:'0.6'}}>CEO, Megawide</Typography>
                                  </Grid>
                              </Grid>
                              <Grid item sx={{display:'flex',}}>
                                  <Grid item xs={12} sm={12}>
                                  
                                  <Typography className='normal-text' sx={{mb:2, lineHeight:'1.7em !important', pb:'1.5em' }}>
                                    “When Bharti Realty decided to collaborate with Unique Engineers for HVAC services in our state-of-the- art 16-floor building … they surpassed our expectations. They not only completed the job with remarkable speed but also wholeheartedly embraced our commitment to the highest quality standards. They even went beyond their scope of work to create an aesthetically pleasing and visually appealing plant room...”
                                  </Typography>
                                  <Typography className='titleStyle text-color' sx={{fontSize: '1.25em !important', lineHeight:'1.7 !important', fontFamily:'Inter !important',  mt:'1em'}}>Mukesh Gaur</Typography>
                                  <Typography className='normal-text' sx={{fontSize: '1.25em !important', lineHeight:2, opacity:'0.6'}}>Project Director, Bharti Realty</Typography>
                                  </Grid>
                              </Grid>
                              <Grid item sx={{display:'flex',}}>
                                  <Grid item xs={12} sm={12}>
                                  
                                  <Typography className='normal-text' sx={{mb:2, lineHeight:'1.7em !important', pb:'1.5em' }}>
                                    “We entrusted Unique with the task of completely renovating our hostels at BITS Pilani, with the objective of modernizing the facilities while preserving their historic grandeur. Unique delivered an exceptional job, ensuring high-quality workmanship and strict adherence to project timelines. The end result exceeded our expectations, capturing the essence of the yesteryears while meeting contemporary standards. … We trust in Unique&#39;s capabilities and look forward to continuing our collaborations.”
                                  </Typography>
                                  <Typography className='titleStyle text-color' sx={{fontSize: '1.25em !important', lineHeight:'1.7 !important', fontFamily:'Inter !important',  mt:'1em'}}>Siddharth Banerjee,</Typography>
                                  <Typography className='normal-text' sx={{fontSize: '1.25em !important', lineHeight:2, opacity:'0.6'}}>Sr. President, BITS</Typography>
                                  </Grid>
                              </Grid>
                          </Slider>
                        </Grid>
                        {!isMobile && <Grid item xs={12} sm={3} sx={{pt:'7em', pr:'2em', textAlign:'right'}}>
                            <img src='/assets/images/visionbg.png' alt='' style={{width:'70%', marginTop:'-2em'}} />
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
            
        </>
    )

}

export default Testimonial;