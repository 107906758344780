import { Grid, Typography } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';

const Enrich = () => {

    return (
        <Grid item xs={12} 
            sx={{backgroundColor: '#FFF', width:'100%', display:'flex', justifyContent:'center'}}>
            <Grid className='enrich-div' sx={{background: !isMobile && 'url(/assets/images/enrichbg.png) no-repeat', backgroundPosition:'bottom left', rowGap:'1em'}}>
                <Grid item xs={12} sm={5} md={5} lg={4} xl={4} sx={{mr: !isMobile && 2}}>
                    <Typography variant='h3' component={'h3'} className='titleStyle title-color'>
                            Enriching lives and spaces</Typography>
                    <Typography variant='bod2' component={'p'} className={'text-color'} 
                        sx={{fontFamily:'Inter',lineHeight:'1.8em', mt:1, overflowWrap:true, textOverflow:'ellipsis', overflow:'hidden'}}>
                        We transform built-up spaces into vibrant habitats that exude character while being highly functional. Our expertise in MEP (Mechanical, Electrical, and Plumbing) solutions guarantees an experience of comfort, safety, and productivity. We blend essential functions, and give every building a soul. Our services ensure efficient resource utilization and also reduce waste and cut down operational costs. With Unique, you can unlock the true potential of your industrial, residential, and commercial spaces and ensure a positive impact on humans and the environment.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={6} xl={6} sx={{textAlign:'center'}}>
                    <img src={'/assets/images/iconsimage.jpg'} alt='iconsbg' className='enrich-img'/>
                    {/* <Grid xs={12}>
                        <Grid sx={{background:'url(/assets/images/subtract.png) no-repeat', width:318, height:72, display:'flex', alignItems:'center', pl:8}}>
                            <img src={'assets/images/healthcare-icon.png'} alt='healthcare' />
                            <Typography class={'normal-text size-20'} sx={{}} >Healthcare</Typography>
                        </Grid>
                        <Grid sx={{background:'url(/assets/images/subtract.png) no-repeat', width:318, height:72, display:'flex', alignItems:'center', pl:8}}>
                            <img src={'assets/images/malls-icon.png'} alt='healthcare' />
                            <Typography class={'normal-text size-20'} sx={{}} >Malls & multiplexes</Typography>
                        </Grid>
                        <Grid sx={{background:'url(/assets/images/subtract.png) no-repeat', width:318, height:72, display:'flex', alignItems:'center', pl:8}}>
                            <img src={'assets/images/industry-icon.png'} alt='healthcare' />
                            <Typography class={'normal-text size-20'} sx={{}} >Industries</Typography>
                        </Grid>
                        <Grid sx={{background:'url(/assets/images/subtract.png) no-repeat', width:318, height:72, display:'flex', alignItems:'center', pl:8}}>
                            <img src={'assets/images/hotels-icon.png'} alt='healthcare' />
                            <Typography class={'normal-text size-20'} sx={{}} >Hotel</Typography>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    )


}

export default Enrich;