import React,{ useState } from 'react';
import { Grid, Breadcrumbs, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Infocus from '../../infocus';
import {Waypoint} from 'react-waypoint';
import Testimonial from './testimonial';
// import CounterNumber from '../../CounterNumber';



const Services = () => {

    const navigate = useNavigate();
    const [numberBool, setNumberBool] = useState(false);

    const serviceIconStyle = {display:'flex', alignItems:'center', p:'1em'};
    const numberStyle = {pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'};

    const depthIcon = <img src='/assets/images/healthicon.png' alt='' style={{width:'25px'}} />;
    const depthDivStyle = {display:'flex', padding:'10px', mr:'1em', borderRadius:'50%', backgroundColor:'#F4F8FA'}

    return (
        <Grid item xs={12} sx={{width:'100%', display:'flex', justifyContent:'center'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
                <Grid item className='title-div' 
                    // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
                >
                <Grid item sx={{px: !isMobile && 12.5, background: !isMobile && 'url(/assets/images/servicebg.svg) no-repeat', backgroundPosition:'top right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Typography sx={{color:'#535353'}}>Our services </Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2}}>Our Services</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            As an MEP solutions provider we offer services that transcend the conventional. We design spaces for the wellbeing and safety of humans above all else. Our team of experts delivers  projects with a blend of cutting-edge technologies and human-centric design. Complex projects are also managed with elegant competence.
                        </Typography>
                        
                        <Typography variant='body2' component={'p'} className='titleStyle title-color' sx={{ pb:2}}>Depth of experience</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:2, lineHeight:2}}>
                            With decades of collective experience, our team believes in exceeding client expectations by providing innovative and energy-efficient solutions to meet their unique needs. Residential buildings and commercial complexes, industrial facilities and healthcare centers, we have successfully executed a wide range of projects catering to various sectors. Our comprehensive approach ensures seamless integration of mechanical, electrical, and plumbing systems; the result is building spaces that function optimally, and are also comfortable and energy efficient habitats for one and all.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {
            // icons information.
            }
            <Grid item  sx={{px:10, maxWidth:1440, display:'flex', justifyContent:'center', width:'90%'}}>
                <Grid item sx={{display:'flex', pr: !isMobile && '5em' ,background: !isMobile && 'url(/assets/images/img1.svg) no-repeat', backgroundPosition:'right bottom'}}>
                    <Grid item  sx={{display:'flex', width: !isMobile ? 900: 300, flexFlow: isMobile && 'column', px:2.5, pb: !isMobile? '5em' : '4em', justifyContent:'space-between', 
                        
                    }}>
                        <Grid item xs={12} sm={3} >
                            <Grid item sx={serviceIconStyle}>
                                <Grid item sx={depthDivStyle} >
                                    {depthIcon}
                                </Grid>
                                <Typography className='normal-text'>Healthcare</Typography>
                            </Grid>
                            <Grid item sx={serviceIconStyle}>
                                <Grid item sx={depthDivStyle} >
                                    { depthIcon }
                                </Grid>
                                <Typography className='normal-text'>Industries</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3} >
                            <Grid item sx={serviceIconStyle}>
                                <Grid item sx={depthDivStyle} >
                                    { depthIcon }
                                </Grid>
                                <Typography className='normal-text'>Data Centers</Typography>
                            </Grid>
                            <Grid item sx={serviceIconStyle}>
                                <Grid item sx={depthDivStyle} >
                                    { depthIcon }
                                </Grid>
                                <Typography className='normal-text'>Education</Typography>
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={12} sm={3} >
                            <Grid item sx={serviceIconStyle}>
                                <Grid item sx={depthDivStyle} >
                                    {depthIcon}
                                </Grid>
                                <Typography className='normal-text'>Corporate</Typography>
                            </Grid>
                            <Grid item sx={serviceIconStyle}>
                                <Grid item sx={depthDivStyle} >
                                    {depthIcon}
                                </Grid>
                                <Typography className='normal-text'>Hotels</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3} >
                            <Grid item sx={serviceIconStyle}>
                                <Grid item sx={depthDivStyle} >
                                    {depthIcon}
                                </Grid>
                                <Typography className='normal-text'>Airports</Typography>
                            </Grid>
                            <Grid item sx={serviceIconStyle}>
                                <Grid item sx={depthDivStyle} >
                                    {depthIcon}
                                </Grid>
                                <Typography className='normal-text'>Retail & Offices</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                // multitude
            }
            <Grid item className="multitude-div" 
                // sx={{backgroundColor:'#D8F2FF', width:'100%', px:10,  display:'flex', justifyContent:'center'}}
            >
                <Grid item className="multitude1-div"
                    // sx={{width:'100%', maxWidth:1440, px:10, py:'5em', }}
                >
                    <Grid item sx={{}}>
                        <Typography className='titleStyle title-color' sx={{ pb:2, textAlign:'center'}}>Multitude of services rendered</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb:'2em' }}>
                            <Grid item xs={4} sx={{}} id='about-stats'>
                                <Typography variant='body2' component='div'  className='titleStyle title-color stats__count' sx={numberStyle}>
                                {/* <Waypoint onEnter={()=> callCounter('stats__count')}/> */}
                                
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="8642519073">1</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="0252119170">0</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--3" data-fake="8242209043">0</span>
                                        </span>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--4" style={{width:'100%', animation:'none'}} data-fake="+">+</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Basements <br/> ventilated</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography variant='body2' component='div' className='titleStyle title-color' sx={numberStyle}>
                                    <Waypoint onEnter={()=> setNumberBool(true)}/>
                                    {numberBool && <p className='numbers'>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--1" data-fake="9642519073">9</span>
                                        </span>
                                    </p>}
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Airports provided <br/> with clean air </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={ numberStyle}>
                                {numberBool && <p className='numbers'>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--1" data-fake="2620969717">2</span>
                                        </span>
                                        <span className="numbers__window">
                                            <span className="numbers__window__digit numbers__window__digit--2" data-fake="5612810041">5</span>
                                        </span>
                                    </p>}
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Five-star hotels</Typography>
                            </Grid>
                        </Grid>
                        <Grid item className="customer-flex" 
                            // sx={{ml:12.5, mr:6}}
                        >
                            <Typography className='normal-text subtitleStyle' sx={{ pt: !isMobile ? '1.5em !important' : '0.5 !important', pb: '1.5em !important'}}>“True success lies in creating a future through innovation, putting customers first, and leading by example. Achieving greatness requires integrity, responsibility, and the courage to persist through failures.”</Typography>
                            <Typography className='quote-owner titleStyle text-color'> <span style={{fontWeight:'400 !important', color:'#4F9A41'}}> / </span>Rajiv Gupta</Typography>
                            <Typography className='quote-owner normal-text' sx={{opacity:0.6 }}>Director</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                // multiface expertise
            }
            <Grid item className="multiface-div">
                <Grid item sx={{width:'100%', background: !isMobile && 'url(/assets/images/mechinalbg.png) no-repeat', backgroundPosition:'bottom left'}}>
                    <Grid item className="multiface1-div">
                        <Grid item sx={{}}>
                            <Typography className='titleStyle title-color' sx={{ pb: !isMobile ? 4 : 2}}>Multifaceted expertise</Typography>
                            <Grid item className="expertise-div expertise-column-reverse">
                                <Grid item xs={12} sm={6} sx={{display:'flex', flexFlow:'column', marginTop: isMobile && '14em'}}>
                                    <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em', pb: !isMobile ? 4 : 2}}>Mechanical</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                        We optimise building performance by designing mechanical solutions that enhance air quality, maximize water efficiency, and enhance safety of the people. We like to look beyond the obvious, and exceed client expectations by providing innovative and energy-efficient solutions to meet their unique needs.
                                    </Typography>
                                    <Grid item sx={{display:'flex'}}>
                                        <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{pl:0, width:'fit-content', minWidth:0}} onClick={()=> navigate('/services/hvac')}>
                                            <Typography variant='body2' component={'p'} className={'normal-text text-color'} 
                                                sx={{textTransform:'capitalize', color:'#4F9A41 !important'}}>
                                                HVAC
                                            </Typography>
                                            <img src='/assets/images/right-arrow.png' alt='right icon' />
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{display:'flex'}}>
                                        <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{pl:0, width:'fit-content'}}  onClick={()=> navigate('/services/firefighting')} >
                                            <Typography variant='body2' component={'p'} className={'normal-text text-color'} 
                                                sx={{textTransform:'capitalize', color:'#4F9A41 !important'}}>
                                                Firefighting
                                            </Typography>
                                            <img src='/assets/images/right-arrow.png' alt='right icon' />
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{display:'flex'}}>
                                        <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{pl:0, width:'fit-content'}} onClick={()=> navigate('/services/plumbing')}>
                                            <Typography variant='body2' component={'p'} className={'normal-text text-color'} 
                                                sx={{textTransform:'capitalize', color:'#4F9A41 !important'}}>
                                                Plumbing
                                            </Typography>
                                            <img src='/assets/images/right-arrow.png' alt='right icon' />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{position:'relative'}}>
                                    <div className='img-wrapper' style={{height: isMobile && 200}}>
                                        <img src='/assets/images/multifaced1.png' alt='multifaced' style={{objectFit:'cover', width: !isMobile ? '100%' : 350, height: isMobile && 200}} />
                                    </div>
                                </Grid>    
                            </Grid>
                            <Grid item  className="expertise-div expertise-column" 
                                // sx={{width:'100%', display:'flex', columnGap:'2em', py:'1em', justifyContent:'space-between' }}
                            >
                                <Grid item xs={12} sm={6} sx={{position:'relative'}}>
                                    <div className='img-wrapper' style={{ height: isMobile && 200 }}>
                                        <img src='/assets/images/multifaced2.png' alt='multifaced' style={{width:'100%'}}  />
                                    </div>
                                </Grid> 
                                <Grid item xs={12} sm={6} sx={{display:'flex', flexFlow:'column', marginTop: isMobile && '14em'}}>
                                    <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em', pb: !isMobile ? 4 : 2}}>Electrical</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                        In response to the evolving needs of clients, Unique Engineers recognized the demand for a single-window solution for all MEP services. And so, we expanded our services to also include electrical services, encompassing HT (High Tension) and LT (Low Tension) systems. By offering the complete bouquet of MEP services, including electrical, we provide a complete solution tailored to each project's specific load requirements.
                                    </Typography>
                                    <Grid item sx={{display:'flex'}}>
                                        <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{pl:0, minWidth:0, width:'fit-content'}}  onClick={()=> navigate('/services/electrical')} >
                                            <Typography variant='body2' component={'p'} className={'normal-text text-color'} 
                                                sx={{textTransform:'capitalize', color:'#4F9A41 !important'}}>
                                                Electrical
                                            </Typography>
                                            <img src='/assets/images/right-arrow.png' alt='right icon' />
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{display:'flex'}}>
                                        <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{pl:0, width:'fit-content'}}  onClick={()=> navigate('/services/ibms')}>
                                            <Typography variant='body2' component={'p'} className={'normal-text text-color'} 
                                                sx={{textTransform:'capitalize', color:'#4F9A41 !important'}}>
                                                IBMS / LV
                                            </Typography>
                                            <img src='/assets/images/right-arrow.png' alt='right icon' />
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                            
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            {
                //Client Testimonial
            }
            <Testimonial title={'Words of satisfaction'}  />
            {
                //in focus
            }
            <Infocus />
            {/* <CounterNumber /> */}
           </Grid> 
        </Grid>
    )

}

export default Services;