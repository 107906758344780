/**
 * Author: Saurabh Kumar
 * Component Name: CommentPost
 * Description: Display the post's comment if the comment is allowed for that particular post
 * Dependency NPM: google auth.
 */

import React, { useState, useEffect, useRef, useCallback} from 'react';
import { makeStyles } from '@mui/styles';
import {Grid, Typography, Link, Divider, List, ListItem, ListItemIcon, ListItemAvatar ,ListItemText, Avatar, Paper,} from '@mui/material';

import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Collapse from '@mui/material/Collapse';
import CommentIcon from '@mui/icons-material/Comment';

// import GoogleAuth from '../googleAuth';
import InputComment from '../../../components/inputComment/InputComment';
import Axios from 'axios';


/**
 * @description : Check for comment childrens if there is any anohter comment text window is open.
 * @param {*} pArr : Array of comment window.
 * @param {*} pId : check for comment id.
 * @returns 
 */

function checkCommentChildren (pArr, pId){
    const sArr = pArr.map((pElement) => {
        pElement.id === pId ? pElement.isOpen === "false" ? 
            pElement.isOpen = "true" : pElement.isOpen = "false" :
            pElement.isOpen = "false";
    
        if(pElement.children !== undefined){
          return checkCommentChildren(pElement.children, pId)
        }
        return pElement;
    })
    return sArr;
}


const CommentPost = (props) => {
    const classes = useStyles();
    const [sopen, setOpen] = useState(true);
    const [authorUser, setAuthorUser] = useState([]); // auth users for mentioning with @...
    const [exEditor, setExEditor] = useState(null);
    
    const [comments, setComment] = useState([]);
    const [commentUser, setCommentUser] = useState({})
    // const [commentAvatar, setCommentAvatar] = useState("");
    const commentUserRef = useRef(null);
    // const [isLogin, setIsLogin] = useState(false);
    
    const postId = props.postId;
    const postSlug = props.postSlug;
    const commentURL = "";

    
    useEffect(() => {
// set array of comment after removing duplication of comments.
        const map = new Map();
        for (const item of comments){
            if(!map.has(item.user_id)){
              map.set(item.user_id, true);
              setAuthorUser(authorUser => [...authorUser, {text: item.comment_author, value: item.comment_author, url: item.comment_author_url}])
            }
          }
    }, [comments])

    /**
     *  Fetching comment data from wordpress.
     */ 
    const getComments = useCallback(async (pslug = "") =>{
        if(postId === "") return;
        await Axios.get(`https://beta.uniqueengineersltd.com/blog/wp-json/wp/v2/comments/?post=${postId}`)
        .then(res => {
            // const commentsArr = [res.data];
            // console.log(res.data);
            const postCommentsop = optimizeComments(res.data);
            setComment(postCommentsop);
        })
        .catch(error => console.log('error', error));
        
        
    }, [postId])
    
    useEffect(() => {
        getComments();
    }, [getComments])
    
/**
 * @description : make a nested comments process from comment array.
 * @param {*} comments : this is the data fetched from database using rest api.
 * @returns 
 */
    const optimizeComments = (comments) => {
        const comms = comments.sort((a, b) => parseInt(b.parent) - parseInt(a.parent))
        
        const sortComments = (comms) => {
            const temp = [ ...comms ]
            comms.forEach((sso, index) => {
                // console.log(sso, temp[index]);
                if (parseInt(sso.parent) === 0 && !sso._links.children) return
                
                let parentIndex = temp.findIndex(o => parseInt(o.id) === parseInt(temp[0].parent))
                // console.log('parentIndex', parentIndex, temp[0], temp[parentIndex]);
                if(parentIndex !== -1){
                    temp[parentIndex].children ? temp[parentIndex].children.push(temp[0]) : temp[parentIndex].children = [temp[0]]
                    temp.shift();
                }
            })
            return temp;
        }
        const sorted = sortComments(comms)
        const commsToRender = sorted.sort((a, b) => { 
            const s2 = new Date(b.date)
            const s1 = new Date(a.date)
            return s1 - s2
        })
        return commsToRender;
    }

    const handleClick = () => {
        setOpen(!sopen);
      };

    /**
     * @description : check for user loggedin. if not found then open google auth window for registration.
     * After user verification, open comment text window for commenting on post.
     * @param {*} pId 
     * @returns 
     */
    const replyPost = (pId) => {
        // if(!isLogin) {
        //     window.location.href=`${window.location.origin}${window.location.pathname}#googleAuth`;
        //     return
        // };
        if(document.getElementById("editor"+pId) !== null){
            if(exEditor === null){
                document.getElementById("editorcommon").style.display="none";
            }
            checkCommentChildren(comments, pId);
            
            if(exEditor !== null){
                exEditor.style.display = "none";
            }
            setExEditor(document.getElementById("editor"+pId));
            
        }
        
    }
    
    const cancelReplyHandler = (pId = "") => {
        console.log(pId);
        if(pId !== ""){
           checkCommentChildren(comments, pId);
        }
        if(exEditor != null){
            exEditor.style.display = "none";
        }
        setExEditor(null);
        document.getElementById("editorcommon").style.display="flex";
    }
    /**
     * @description : insert user information to the database after getting information from google authentication
     * @param {*} pData 
     */

    // const insertUser = async(pData) => {
        
    //     const registerData = JSON.stringify({username:pData.givenName, email:pData.email, first_name:pData.givenName, last_name:pData.familyName, password:"abc12346"});
    //     const registerUser = await fetch(`${commentURL}/wordpress/wp-json/wp/v2/users/`, {
    //         method:"POST",
    //         body: registerData, 
    //         headers: {
    //             "Content-Type": "application/json",
    //         }
    //     })
    //     .then(data => data.json())
    //     .catch(error => console.log(error))
    //     if(registerUser.id > 0){
    //         setIsLogin(true);
    //         setCommentUser({author:registerUser.id, author_email:pData.email, author_name:pData.name, avatar:registerUser.avatar_urls["48"]})
    //         setCommentAvatar(registerUser.avatar_urls["48"]);
    //         commentUserRef.current.getCommentUser(registerUser.avatar_urls["48"]);
    //     }
    // }

    /**
     * 
     * @param {*} pData : pass the email from data what the application get from google authenticatiion 
     * and if it is not found in the data then call "insertUser" function else use the user information for further commenting.
     * @returns 
     */

    // const googleLogin = async (pData) =>{
    //     const getLoginUser = await fetch(`${commentURL}/wordpress/wp-json/wp/v2/users/?search=${pData.email}`)
    //     .then(data => data.json())
    //     .catch(error => console.log(error));
        
    //     //console.log("sss ",getLoginUser);
        
    //     if(getLoginUser.length === 0){
    //         insertUser(pData);
    //         return ;
    //     }


    //     if(getLoginUser[0] !== undefined){
    //         setIsLogin(true);
    //         setCommentUser({author:getLoginUser[0].id, author_email:pData.email, author_name:getLoginUser[0].name, avatar:getLoginUser[0].avatar_urls["48"]})
    //         setCommentAvatar(getLoginUser[0].avatar_urls["48"]);
    //         commentUserRef.current.getCommentUser(getLoginUser[0].avatar_urls["48"]);
    //     }
    // }


      const insertPostHandler = async (pData) => {
        const tempCommentData = JSON.parse(JSON.stringify(commentUser));
        tempCommentData.content = pData.content;
        tempCommentData.author_name = pData.author;
        tempCommentData.author_email = pData.author_email;
        tempCommentData.post = postId;
        
        // tempCommentData.date_gmt = pData.publishTime;
        tempCommentData.parent = pData.commentId;
        
        const cmtData = JSON.stringify(tempCommentData);

        //console.log(tempCommentData);
        //return;
        const commentURL = `https://beta.uniqueengineersltd.com/blog/wp-json/wp/v2/comments/?post_id=${postId}`;
        
        await fetch( commentURL, 
            {
                method: "POST", 
                body: cmtData, 
                headers: {
                    "Content-Type": "application/json",
                }
            }
        )
         .then(data => data.json())
         .catch(error => console.log(error))
         cancelReplyHandler();
         getComments();
     }

     /**
      * @description : creates a comment list from the data of comments from database.
      * @param {*} pData 
      * @returns 
      */

     const CommentElement = (pData) =>{
         const abc = pData.element.map((commentData, index)=>{
            return (
                <React.Fragment key={Math.random()}>
                    <List component="ul" disablePadding style={{marginLeft:60}}>
                        <ListItem key={commentData.id} id={commentData.id} className={classes.nested} divider style={{paddingBottom:40, alignItems:'flex-start'}}>
                            <ListItemAvatar>
                                <Avatar alt={commentData.comment_author} src={String(commentData.author_avatar_urls["48"])} />
                            </ListItemAvatar>
                            <ListItemText classes={{secondary:classes.postText}} 
                            primary={
                                <React.Fragment>
                                    <Typography className={classes.authorNam} varient="body2" component="p">{commentData.author_name}</Typography>
                                    <Typography className={classes.authorDate} style={{fontSize:12}} varient="body2" component="span"> {commentData.date_gmt}</Typography>
                                </React.Fragment>
                            } secondary={ 
                                <React.Fragment>
                                    <Typography component="span" className={classes.commentText} varient="body2" dangerouslySetInnerHTML={{__html: commentData.content.rendered}} /> 
                                </React.Fragment>
                            } />
                            <ListItemSecondaryAction style={{position:"relative", marginLeft:90, top:-29}} >
                                <Link component="button" className={classes.replyBtn} onClick={() => replyPost(commentData.id)} style={{marginRight:"5px"}}>Reply</Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem key={`editor${commentData.id}`} id={`editor${commentData.id}`} style={{display: commentData.isOpen === "true" ? "flex":"none", paddingLeft:0, paddingRight:0, inset:0}}>
                            <Grid item xs={12} sm={12} lg={12} xl={12} style={{width:"auto", maxWidth:"100%", display:"flex" }}>
                                
                                    <InputComment commentId={commentData.id} 
                                        // commentAvatar={commentAvatar} 
                                        // authorsName={authorUser} 
                                        cancelCommentItem = {() => cancelReplyHandler(commentData.id)} 
                                        insertPostItem={insertPostHandler} />
                                </Grid>
                        </ListItem>
                    {commentData.children !== undefined ? <CommentElement key={Math.random()} depth={pData.depth+1} element={commentData.children} /> : null }
                </List>
                </React.Fragment>
                

            )
        })
        return abc
     }

    return (
        
        <Paper style={{marginLeft:'5em', marginRight:'5em', boxShadow:'none', marginBottom:50, width:'100%',}}>
            <Grid container sx={{justifyContent: 'center', boxShadow:'none'}}>
                <List style={{width:"100%", maxWidth:800, paddingBottom:0}}>
                    <List>
                        <ListItem key={"expan"} onClick={handleClick}>
                            <ListItemIcon className={classes.itemIcon} style={{minWidth:40}}>
                                <CommentIcon />
                            </ListItemIcon>
                            <ListItemText classes={{primary:classes.itemTextTitle}} className={classes.itemTextTitle} primary="Comments" />
                        </ListItem>
                    </List>
                    <Divider />
                    <Collapse in={sopen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {/* {console.log('comments', comments)} */}
                        {comments.map((commentData)=>{

                            return (
                                <React.Fragment key={Math.random()}>
                                <ListItem key={commentData.id} id={commentData.id} className={classes.nested} divider style={{paddingBottom:40, alignItems:'flex-start'}}>
                                    <ListItemAvatar>
                                        <Avatar alt={commentData.comment_author} src={String(commentData.author_avatar_urls["48"])} />
                                    </ListItemAvatar>
                                    <ListItemText classes={{secondary:classes.postText}} primary={
                                        <React.Fragment>
                                            <Typography className={classes.authorNam} varient="body2" component="p">{commentData.author_name}</Typography>
                                            <Typography className={classes.authorDate} varient="body2" component="span"> {commentData.date_gmt}</Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography component="span" className={classes.commentText} varient="body2" dangerouslySetInnerHTML={{__html: commentData.content.rendered}} /> 
                                        </React.Fragment>
                                    } />
                                    <ListItemSecondaryAction style={{position:"relative", marginLeft:90, top:-29}} >
                                        <Link component="button" className={classes.replyBtn} onClick={() => replyPost(commentData.id)} style={{marginRight:"5px"}}>Reply</Link>
                                    </ListItemSecondaryAction>
                                    
                                </ListItem>
                                <ListItem key={`editor${commentData.id}`} id={`editor${commentData.id}`} style={{display:commentData.isOpen === "true" ? "flex": "none", paddingLeft:0, paddingRight:0, inset:0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12} style={{width:"100%", maxWidth:"500px", display:"flex" }}>
                                            <InputComment commentId={commentData.id} 
                                                // commentAvatar = {commentAvatar} 
                                                authorsName={authorUser} 
                                                cancelCommentItem = {() => cancelReplyHandler(commentData.id)} insertPostItem={insertPostHandler} />
                                        </Grid>
                                </ListItem>
                                {commentData.children !== undefined ? <CommentElement key={Math.random()} depth={1} element={commentData.children} /> : null }
                                </React.Fragment>
                            )
                        })}
                        </List>
                    </Collapse>
                    
                    {<Grid id="editorcommon" item xs={12} sm={12} lg={12} xl={12} style={{width:"auto", maxWidth:"100%", display:"flex"}}>
                        <InputComment ref={commentUserRef} commentId={0} 
                            // commentAvatar = {commentAvatar} 
                            authorName={authorUser}
                            insertPostItem={insertPostHandler} />
                    </Grid>}
                    
                    {/* <GoogleAuth updateLogin={googleLogin} props={props}/> */}
                </List>
                
            </Grid>
            <Grid>

            </Grid>
            <Grid>
                
            </Grid>

        </Paper>
    )
}

const useStyles = makeStyles((theme) =>({
    itemIcon:{
        color: "#27333e"
    },
    itemTextTitle:{
        fontSize: 32,
        fontFamily:"Inter",
        color: "#27333e",
        marginTop:0,
        marginBottom:0,
    },
    authorNam:{
        fontFamily:"Inter",
        fontSize:16,
        color: '#27333e',
        textTransform: "capitalize",
    },
    authorDate:{
        fontFamily:"Inter",
        fontSize:14,
        color:"#27333e"
    },
    commentText:{
        fontFamily:"Inter",
        fontSize:16,
        color: '#27333e'
    },
    replyBtn:{
        fontSize:16,
        fontFamily:"Inter",
        color: "#27333e",
        cursor:"pointer",
        '&:hover':{
            textDecoration:"none",
            color:"#27333e"
        }
    }
}))


export default CommentPost;