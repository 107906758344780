import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import Project from '../../components/project'

const Projects = () => {

    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])

    return (
        <Grid container style={{width:'100%'}}>
            <Banner img={'/assets/images/projectbanner.jpg'} title='Building services are the life force or "prana" of building structures.' />
            <Project />
        </Grid>
    )

}

export default Projects;