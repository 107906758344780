import React, { useEffect, useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const CorporateCompliance = () => {

    const [ethicsPos, setEthicsPos] = useState(0);
    const [compliancePos, setCompliancePos] = useState(0);
    const [reportingPos, setReportingPos] = useState(0);
    const [diversityPos, setDiversityPos] = useState(0);
    const [environPos, setEnvironPos] = useState(0);

    useEffect(() => {
        const mIcon = document.getElementsByClassName('ethics-icon')[0];
        mIcon.marginLeft = (document.getElementsByClassName('ethics-id')[0].clientWidth/2 - mIcon.clientWidth/2)+'px'; 
        setEthicsPos(mIcon.marginLeft);

        const complianceIcon = document.getElementsByClassName('compliance-icon')[0];
        const reportingIcon = document.getElementsByClassName('reporting-icon')[0];
        const diversityIcon = document.getElementsByClassName('diversity-icon')[0];
        const environIcon = document.getElementsByClassName('environ-icon')[0];

        const complianceEle = document.getElementsByClassName('compliance-id')[0];
        const reportingEle = document.getElementsByClassName('reporting-id')[0];
        const diversityEle = document.getElementsByClassName('diversity-id')[0];
        const environEle = document.getElementsByClassName('environ-id')[0];
        // console.log(complianceIcon, complianceEle)
        setCompliancePos((complianceEle.clientWidth/2 - complianceIcon.clientWidth/2)+'px');
        setReportingPos((reportingEle.clientWidth/2 - reportingIcon.clientWidth/2)+'px');
        setDiversityPos((diversityEle.clientWidth/2 - diversityIcon.clientWidth/2)+'px');
        setEnvironPos((environEle.clientWidth/2 - environIcon.clientWidth/2)+'px');


    }, [])

    // const optionStyle = {width:170, height:40, fontSize:'1em', color:'#4F9A41', backgroundColor:'transparent', border:'unset', padding:'0px 10px'}
    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className="title-div">
                <Grid item sx={{px: !isMobile && 12.5}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Typography variant='body2' sx={{color:'#535353'}}>Corporate Compliance</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2}}>Corporate compliance</Typography>
                        <Typography variant='body2' component={'p'}  className='normal-text' sx={{mb: !isMobile ? 4 : 2, lineHeight:2}}>
                            Our corporate governance policy reflects our commitment to maintain a culture of trust, integrity, and responsible business practices. Regular review and monitoring of our governance practices is conducted to ensure their effectiveness and alignment with our values and business objectives.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {
                // Select filter
            }
            <Grid item sx={{width:'100%', maxWidth:1440, display:'flex', flexFlow:'column', pt: !isMobile? '2em' : '1em', pb: !isMobile ? '4em' : '2em'}}>
                <Grid item xs={12} sx={{px:!isMobile ? 22.5 : 2.5, display:'flex', flexFlow: isMobile && 'column'}}>
                    <img src='/assets/images/ethics-icon.png' style={{width:'fit-content', maxWidth:'100%', height:'fit-content', marginRight:'3em', paddingTop:'1em'}} alt='' /> 
                    <Grid item xs={12} sm={12}>
                        <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em !important', pb:1}}>Ethics and Accountability</Typography>
                        <Typography sx={{mb:5, lineHeight:2}}>
                            We are committed to upholding high ethical standards and ensuring accountability at all levels of the organization. Our employees and stakeholders are expected to adhere to a comprehensive code of conduct that promotes honesty, integrity, and responsible decision-making.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{px:!isMobile ? 22.5 : 2.5, display:'flex', flexFlow: isMobile && 'column'}}>
                    <img src='/assets/images/transparency-icon.png' style={{width:'fit-content', maxWidth:'100%', height:'fit-content', marginRight:'3em', paddingTop:'1em'}} alt='' /> 
                    <Grid item xs={12}  sm={10}>
                        <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em !important', pb:1}}>Transparency and Communication</Typography>
                        <Typography sx={{mb:5, lineHeight:2}}>
                            We believe in transparent communication and disclosure of information to our shareholders, employees, customers, and other stakeholders. We strive to provide timely and accurate information to enable informed decision-making and foster trust.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{px:!isMobile ? 22.5 : 2.5, display:'flex', flexFlow: isMobile && 'column'}}>
                    <img src='/assets/images/compliance-icon.png' style={{width:'fit-content', maxWidth:'100%', height:'fit-content', marginRight:'3em', paddingTop:'1em'}} alt='' /> 
                    <Grid item xs={12} sm={10}>
                        <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em !important', pb:1}}>Compliance and Legal Framework</Typography>
                        <Typography sx={{mb:5, lineHeight:2}}>
                            We comply with all applicable laws, regulations, and industry standards, ensuring that our operations are conducted ethically and with integrity. Our governance practices reflect a thorough understanding of legal and regulatory requirements.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{px:!isMobile ? 22.5 : 2.5, display:'flex', flexFlow: isMobile && 'column'}}>
                    <img src='/assets/images/stack-icon.png' style={{width:'fit-content', maxWidth:'100%', height:'fit-content', marginRight:'3em', paddingTop:'1em'}} alt='' /> 
                    <Grid item xs={12}  sm={10}>
                        <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em !important', pb:1}}>Stakeholder Engagement</Typography>
                        <Typography sx={{mb:5, lineHeight:2}}>
                        We recognize the importance of engaging with our stakeholders, including employees, customers, suppliers, and local communities. We actively seek their inputs and incorporate their perspectives into our decision-making processes to create shared value.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{px: !isMobile ? 22.5 : 2.5, display:'flex', flexFlow: isMobile && 'column'}}>
                    <img src='/assets/images/risk-icon.png' style={{width:'fit-content', maxWidth:'100%', height:'fit-content', marginRight:'3em', paddingTop:'1em'}} alt='' /> 
                    <Grid item xs={12} sm={10}>
                        <Typography className='titleStyle title-color' sx={{ fontSize:'1.5em !important', pb:1}}>Risk Management and Oversight</Typography>
                        <Typography sx={{mb:5, lineHeight:2}}>
                            We implement robust risk management processes to identify, assess, and mitigate risks that may impact our performance and reputation. Our board of directors and management exercise effective oversight to ensure the proper management of risks and protection of stakeholder interests.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{width:'100%', backgroundColor:'#FEFBEB', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', maxWidth:1440, px: !isMobile ? 22.5 : 2.5}}>
                    <Grid item sx={{py: !isMobile ? '5em' : '3em'}}>
                        <Typography variant='h3' component={'h3'} className='titleStyle title-color' sx={{ textAlign:'center', mb:2}}> Our values</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{textAlign: 'left',mb:2}}>Our values are a reflection of our culture of trust, integrity and responsible business practices. We strive to uphold these in our work and operations in every way possible.</Typography>
                        <Grid item sx={{mt:'5em', textAlign:'center'}}>
                            <Grid item xs={12} sx={{display:'flex', justifyContent:'space-between', flexFlow: isMobile && 'column', columnGap:'3em', rowGap:'2em', pb:'2em'}}>
                                <Grid item xs={12} sm={4}>
                                    <Grid item xs={12} sx={{display:'flex', alignItems:'flex-start', flexFlow:'column'}}>
                                        <div className='ethics-icon' style={{marginLeft:ethicsPos}}>
                                            <img src='/assets/images/value-icon.png' alt='value-icon' />
                                        </div>
                                        <Typography id={'ethics-id'} variant='h4' component={'h4'} className='ethics-id titleStyle title-color' sx={{fontSize:'1.50em !important', mb:2}}>Integrity and Ethics</Typography>
                                    </Grid>
                                    <Typography variant='body2' component={'p'} className='normal-text' sx={{textAlign: 'left',mb:2}}>We uphold high standards of honesty, integrity, and ethical behavior in all aspects of our business operations.</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Grid item xs={12} sx={{display:'flex', alignItems:'flex-start', flexFlow:'column'}}>
                                        <div className='compliance-icon' style={{marginLeft:compliancePos}}>
                                            <img src='/assets/images/value-icon.png' alt='value-icon' />
                                        </div>
                                        <Typography id={'compliance-id'} variant='h4' component={'h4'} className='compliance-id titleStyle title-color' sx={{fontSize:'1.50em !important', mb:2}}>Health, Safety, and Quality</Typography>
                                    </Grid>
                                    <Typography variant='body2' component={'p'} className='normal-text' sx={{textAlign: 'left',mb:2}}>We prioritize the health and safety of our employees and customers, and deliver high-quality products and services.</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Grid item xs={12} sx={{display:'flex', alignItems:'flex-start', flexFlow:'column'}}>
                                        <div className='reporting-icon' style={{marginLeft:reportingPos}}>
                                            <img src='/assets/images/value-icon.png' alt='value-icon' />
                                        </div>
                                        <Typography id={'reporting-id'} variant='h4' component={'h4'} className='reporting-id titleStyle title-color' sx={{fontSize:'1.50em !important', mb:2}}>Compliance and Reporting</Typography>
                                    </Grid>
                                    <Typography variant='body2' component={'p'} className='normal-text' sx={{textAlign: 'left',mb:2}}>We comply with laws and regulations, reject corruption, and provide channels for reporting misconduct confidentially.</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{display:'flex', justifyContent:'center', flexFlow: isMobile && 'column', columnGap:'3em', rowGap:'2em', pt: !isMobile ? '2em' : '1em'}}>
                                <Grid item xs={12} sm={4}>
                                    <Grid item xs={12} sx={{display:'flex', alignItems:'flex-start', flexFlow:'column'}}>
                                        <div className='diversity-icon' style={{marginLeft:diversityPos}}>
                                            <img src='/assets/images/value-icon.png' alt='value-icon' />
                                        </div>
                                        <Typography id={'diversity-id'} variant='h4' component={'h4'} className='diversity-id titleStyle title-color' sx={{fontSize:'1.50em !important', mb:2}}>Respect and Diversity</Typography>
                                    </Grid>
                                    <Typography variant='body2' component={'p'} className='normal-text' sx={{textAlign: 'left',mb:2}}>We treat all individuals with respect, promote diversity, and foster an inclusive workplace free from discrimination and harassment.</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Grid item xs={12} sx={{display:'flex', alignItems:'flex-start', flexFlow:'column'}}>
                                        <div className='environ-icon' style={{marginLeft:environPos}}>
                                            <img src='/assets/images/value-icon.png' alt='value-icon' />
                                        </div>
                                        <Typography id={'environ-id'} variant='h4' component={'h4'} className='environ-id titleStyle title-color' sx={{fontSize:'1.50em !important', mb:2}}>Environmental Responsibility</Typography>
                                    </Grid>
                                    
                                    <Typography variant='body2' component={'p'} className='normal-text' sx={{textAlign: 'left',mb:2}}>We commit to sustainable practices, minimizing our environmental impact and promoting responsible resource management.</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    )

}

export default CorporateCompliance;