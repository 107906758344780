import React from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
// import Slider from 'react-slick';
import FootPrint from '../footprint';

const Products = () => {

    // const sliderRef = useRef(null);

    // const settings = {
    //     dots: false,
    //     arrows: false,
    //     className: 'center',
    //     centerMode: false,
    //     infinite: true,
    //     centerPadding: '30px',
    //     slidesToShow: Math.min(2, 1),
    //     slidesToScroll: Math.min(1),
    //     swipeToSlide: false,
    //     speed: 500,
    //     responsive: [
    //       {
    //         breakpoint: 1025,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 1,
    //           infinite: true,
    //         },
    //       },
    //       {
    //         breakpoint: 600,
    //         settings: {
    //           dots: false,
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //           infinite: false,
    //         },
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           dots: false,
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //           initialSlide: 1,
    //           centerMode: false,
    //         },
    //       },
    //     ],
    //   };
    // const slides = [
    //     <Grid item xs={12}>
    //         <img src='/assets/images/goaAirport.jpg' alt='Goa Airport' style={{marginBottom:'2em'}} />
    //         <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Goa airport</Typography>
    //         <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
    //     </Grid>,
    //     <Grid item xs={12}>
    //         <img src='/assets/images/amityUniversityKolkata.jpg' alt='aiims1' style={{marginBottom:'2em'}} />
    //         <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Amity university</Typography>
    //         <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Kolkata</Typography>
    //     </Grid>,
    //     <Grid item xs={12}>
    //         <img src='/assets/images/harrowInternationalschool.jpg' alt='aiims1' style={{marginBottom:'2em'}} />
    //         <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Harrow international school</Typography>
    //         <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>--</Typography>
    //     </Grid>
    // ]
      
    // const slidePrev = (e) => {
    //     e.preventDefault()
    //     sliderRef.current.slickPrev();
    // }
    // const slideNext = (e) => {
    //     e.preventDefault()
    //     sliderRef.current.slickNext();
    // }

    return (
        <Grid item xs={12} sx={{width:'100%'}}>
            <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
                {/* <Grid item sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}> */}
                <Grid item xs={12} className='title-div' >
                    <Grid item className='breadcrumb-div'>
                        {/* <Grid item sx={{px:12.5, pt:'1.5em', pb:'2em'}}> */}
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                            <Typography variant='body2' component={'p'} sx={{color:'#535353'}}>Products</Typography>
                        </Breadcrumbs>
                        <Grid item xs={12} sx={{pt:'2em', pb:'2em'}}>
                            <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ fontSize:36, pb:2}}>Our Products</Typography>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:2, lineHeight:2}}>
                                We design and manufacture our products to meet specific customer applications. The construction details offer flexibility to adapt to varying space constraints on-site. In addition to delivering products with effortless maintenance, we prioritise user well-being and safety.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    // multitude
                }
                <Grid item className="multitude-div">
                    <Grid item className="multitude1-div">
                        <Grid item className="multitude-subdiv" sx={{}}>
                            <Typography className='normal-text subtitleStyle' sx={{ pt:'1em !important', pb:'1.5em' }}>“Our focus is on client delivery at all times; however, our people are our family, and developing their talents, enhancing their skills, is key to our growth in the long term.”</Typography>
                            <Typography className='quote-owner titleStyle text-color' > <span style={{fontWeight:'400 !important', color:'#4F9A41'}}> / </span>Manmohan Jindal</Typography>
                            <Typography className='quote-owner normal-text' sx={{ opacity:0.6 }}>Director</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    // multiface expertise
                }
                <Grid item sx={{width:'100%', display:'flex', justifyContent:'center'}}>
                    <Grid item className="product-div" sx={{marginTop: isMobile && '1.5em'}}>
                        <Grid item className="product1-div">
                            <Grid item className="product-subdiv">
                                <Grid item xs={12} sm={7} sx={{display:'flex', flexFlow:'column'}}>
                                    <Typography className='titleStyle title-color' sx={{ pb:2}}>Air washer</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                    The Evaporative Air Washer Cooling System designed by Unique Engineers is a cost-effective alternative to refrigerated air conditioning. It provides efficient cooling through water evaporation and an air motion system. It utilizes direct evaporative cooling to lower air temperature without changing energy content to suit dry and hot climates. The construction features include double-skin or single-skin casing panels, a DIDW centrifugal fan with a belt-driven motor, and a wet deck with specially treated cellulose paper fill media. Additional components include a water storage tank and a centrifugal pump for water recirculation, along with pre-filters to minimize dust loading. The system offers advantages such as lower installation and operating costs, energy efficiency, and ease of maintenance. Optional features include different cooling pad thicknesses, spray chambers with PVC eliminators, and a standby pump. This system finds applications in various settings such as industrial plants, commercial kitchens, warehouses, and more.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5} sx={{marginLeft: !isMobile && '3em'}}>
                                    <Grid item sx={{ display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#FAF6FF', height: !isMobile ? '100%' : 250 , padding:'1em', position:'relative'}}>
                                        <div className='img-wrapper' style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <img src='/assets/images/airwasher.png' alt='multifaced' style={{width:"80%"}}  />
                                        </div>
                                    </Grid>
                                </Grid>    
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{width:'100%', mt: !isMobile &&'2em', display:'flex', justifyContent:'center', background: !isMobile && 'url(/assets/images/productbg2.png) no-repeat', backgroundPosition:'top right'}}>
                    <Grid item className="product-div" sx={{paddingTop: !isMobile && '0px !important'}}>
                        <Grid item className="product1-div" sx={{paddingTop:!isMobile && '0px !important'}}>
                            <Grid item className="product-subdiv expertise-column-reverse" sx={{mt: isMobile && '1em', paddingTop:'0px !important'}}>
                                <Grid item xs={12} sm={5} className="product1-subdiv ">
                                    <Grid item sx={{ position:'relative', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#E8FCFF', height: !isMobile ? '100%' : 250, padding:'1em'}}>
                                        <div className='img-wrapper' style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <img src='/assets/images/fancoil.png' alt='multifaced' style={{width:"80%"}} />
                                        </div>
                                    </Grid>
                                </Grid> 
                                <Grid item xs={12} sm={7} sx={{display:'flex', flexFlow:'column'}}>
                                    <Typography className='titleStyle title-color' sx={{ pb:2}}>Fan coil unit</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                        The Fan Coil Unit (FCU) is a compact HVAC system designed with a galvanized steel casing and options for plain, pre-coated, or powder-coated finishes. It features a multi-row cooling coil with copper tubes and aluminum fins for efficient heat transfer. The FCU is equipped with twin blowers made of aluminum or galvanized steel, driven by an FHP double-extended shaft motor. The fan motor is a three-speed, permanent split capacitor, single-phase motor suitable for 220 V, 50 c/s AC. A washable media filter is included in the return air plenum. The drain pan comes in two options: single-skin stainless steel with closed-cell insulation or double-skin galvanized steel with sandwiched insulation. The FCU offers individual zone control, prevents cross-communication, and is adaptable to future load and space layout changes. It is commonly used in hospitality, healthcare, offices, schools, shopping malls, and residential apartments.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{width:'100%',mt: !isMobile && '2em', display:'flex', justifyContent:'center'}}>
                    <Grid item className="product-div" sx={{paddingTop:!isMobile && '0px !important'}}>
                        <Grid item className="product1-div" sx={{paddingTop:!isMobile && '0px !important'}}>
                            <Grid item className="product-subdiv" sx={{mt: isMobile && '1em', paddingTop:'0px !important'}}>
                                <Grid item xs={12} sm={7} sx={{display:'flex', flexFlow:'column'}}>
                                    <Typography className='titleStyle title-color' sx={{ pb:2}}>Fan section</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                        Our Fan Section Units are designed to enhance air management efficiency. We manufacture floor-mounted fan sections with capacities ranging from 2000 CFM to 35000 CFM, and ceiling-suspended units with capacities from 1200 CFM to 6000 CFM. These units can be customised as per your air control requirements with precise cooling load calculations, psychrometric analysis, and optimized air treatment processes for each zone. The sturdy casing features an aluminum framework with fibreglass joints and double-skin panels for insulation. Our energy-efficient centrifugal fans, certified by AMCA, are paired with TEFC motors, all mounted on a vibration-isolated aluminum base frame. The multiple discharge orientations and innovative features in our Fan Section Units set new standards for effective air management.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5} sx={{marginLeft: !isMobile && '3em'}}>
                                    <Grid item sx={{ position:'relative', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#F1FFEE', height: !isMobile ? '100%' : 250, padding:'1em'}}>
                                        <div className='img-wrapper' style={{display:'flex', justifyContent:'center', alignItems:'center', padding:isMobile && '1.5em'}}>
                                            <img src='/assets/images/fansection.png' alt='multifaced' style={{width:"80%"}} />
                                        </div>
                                    </Grid>
                                </Grid>    
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{width:'100%',mt: !isMobile && '2em', display:'flex', justifyContent:'center'}}>
                    <Grid item className="product-div" sx={{paddingTop:!isMobile && '0px !important'}}>
                        <Grid item className="product1-div" sx={{paddingTop:!isMobile && '0px !important'}}>
                            <Grid item className="product-subdiv expertise-column-reverse" sx={{mt: isMobile && '1em'}}>
                                <Grid item xs={12} sm={5} className="product1-subdiv">
                                    <Grid item sx={{ position:'relative', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#FFF8EF', height: !isMobile ? '100%' : 250, padding:'1em'}}>
                                        <div className='img-wrapper' style={{display:'flex', justifyContent:'center', alignItems:'center', padding:isMobile && '1.5em'}}>
                                            <img src='/assets/images/airhandle.png' alt='multifaced' style={{width:"80%"}} />
                                        </div>
                                    </Grid>
                                </Grid> 
                                <Grid item xs={12} sm={7} sx={{display:'flex', flexFlow:'column'}}>
                                    <Typography className='titleStyle title-color' sx={{ pb:2}}>Air handling unit</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                    Our Air Handling Units (AHU) are highly flexible HVAC systems designed to meet specific customer needs. They offer a wide capacity range from 2040 CMH to 59500 CMH, with a pressure range of 250 Pa to 1500 Pa. The units ensure high indoor air quality, reduce dirt accumulation and prevent moisture absorption. They feature a corrosion-resistant stainless steel drain pan for efficient moisture drainage and minimize microbial growth. The AHU&#39;s flexible construction allows for customised configurations to serve distinct conditions. Their high PUF density offers excellent thermal and sound insulation properties for a reduced casing breakout noise. These units find applications in cooling, heating, dehumidification, and humidification across shopping malls, offices, restaurants, healthcare facilities, clean rooms, and industrial settings.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{width:'100%',mt: !isMobile && '2em', pb: !isMobile ? '12em' : '2em', display:'flex', justifyContent:'center', background: !isMobile && 'url(/assets/images/productbg3.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Grid item className="product-div" sx={{paddingTop:!isMobile && '0px !important'}}>
                        <Grid item className="product1-div" sx={{paddingTop:!isMobile && '0px !important'}}>
                            <Grid item className="product-subdiv" sx={{mt: isMobile && '1em' }}>
                                <Grid item xs={12} sm={7} sx={{display:'flex', flexFlow:'column'}}>
                                    <Typography className='titleStyle title-color' sx={{ pb:2}}>Cooling / Heating coils</Typography>
                                    <Typography className='normal-text' sx={{mb:2, lineHeight:2}}>
                                    These coils offer adaptable solutions for cooling and heating needs by accommodating chilled water, hot water, brine, and direct expansion systems. Constructed with precision, they feature copper tubes encased within galvanized steel end plates. Configurations include 2/4/6/8 rows of plain copper tubes with a 12.7mm outer diameter. To optimize heat transfer, tubes are mechanically expanded and combined with aluminum fins and provide a choice of 8/10/12 fins per inch (FPI) spacing. Water/brine coils possess self-draining capabilities. Hydrophilic-coated fins can be requested for enhanced performance. All coils undergo a rigorous 21 kg/cm² leak test. DX coils are equipped with a distribution nozzle and can be either face or row split.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5} sx={{marginLeft: !isMobile && '3em'}}>
                                    <Grid item sx={{ position:'relative', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#FAF6FF', height: !isMobile ? '100%' : 250, padding:'1em'}}>
                                        <div className='img-wrapper' style={{display:'flex', justifyContent:'center', alignItems:'center', padding:isMobile && '1.5em'}}>
                                            <img src='/assets/images/cooling.png' alt='multifaced' style={{width:"80%"}} />
                                        </div>
                                    </Grid>
                                </Grid>    
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
                <FootPrint title='Our projects' content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            </Grid>
        </Grid>
    )

}

export default Products;