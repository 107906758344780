import { Grid, Typography, } from '@mui/material';
import React, { useState } from 'react';
import {Waypoint} from 'react-waypoint'
import { isMobile } from 'react-device-detect';


const Leading = () => {

    const [numberBool, setNumberBool] = useState(false);
    const styles = {fontSize: !isMobile ? '2em !important' : '1.5em !important', fontWeight:'700 !important'}

    return (
        <Grid item sx={{backgroundColor:'#F4F8FA', pl: isMobile ? 2.5  : 22.5, pr:2, width:'100%', display:'flex',justifyContent:'center'}}>
            <Grid item sx={{pl: !isMobile ? 10 : 0, pr:0, py: !isMobile ? 12.5 : 6.25, width:'100%', maxWidth:1440, background: !isMobile && 'url(/assets/images/aboutuslead.png) no-repeat', backgroundPosition:'bottom right'}}>
                <Grid item className="expertise-column" sx={{display:'flex'}}>
                    <Grid item xs={12} sm={4} md={5} lg={4} xl={4} sx={{display:'flex', width:'100%', alignItems:'center', mr:2, height:237, py:'1.7em'}}>
                        <Typography variant='h3' component={'h3'} className='titleStyle title-color'
                            // sx={{fontSize:32, fontFamily:'Playfair Display', fontWeight:500, lineHeight:1.7}}
                            >
                                Leading the way <br />in MEP Solutions</Typography>
                        
                    </Grid>
                    <Grid item xs={12} sm={8} md={7} lg={8} xl={8} sx={{mr:2, display:'flex', flexFlow:'column'}} >
                        <Grid item xs={12} sm={6} sx={{display:'flex', mb:'2em'}}>
                            <Grid item xs={6}>
                                <Typography className='normal-text title-color' sx={styles}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="6642212862">6</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8641218062">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text'>Hospitals</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className='normal-text title-color' sx={styles}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="9642210061">9</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text'>Airports</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{display:'flex', alignItems:'flex-end'}}>
                            <Grid item xs={6}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='normal-text title-color' sx={styles}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="2612917035">2</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="5612218001">5</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{}}>Five-star Hotels</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className='normal-text title-color' sx={styles}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="5612210038">5</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8622118002">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text'>Industries</Typography>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )

}

export default Leading;