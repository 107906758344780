import React, {useRef} from 'react';
import { Grid, Typography, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';

const FootPrint = (props) => {
    const {title, content, imageArray} = props;

    const navigate = useNavigate();
    const sliderRef = useRef(null)

    const settings = {
        dots: false,
        arrows: false,
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '50px',
        slidesToShow: Math.min(1),
        slidesToScroll: Math.min(1),
        swipeToSlide: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
              centerMode: false,
              centerPadding: '10px',

            },
          },
        ],
      };
      
const getShuffledArr = arr => {
    const newArr = arr.slice()
    for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr
};
    
const slidePrev = (e) => {
    e.preventDefault()
    sliderRef.current.slickPrev();
}
const slideNext = (e) => {
    e.preventDefault()
    sliderRef.current.slickNext();
}
const handleNavigate = () => {
    navigate('/projects');
}
const slides = [
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/aiims-jammu.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>AIIMS, Jammu</Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Vijaypur Samba Distt, Jammu & Kashmir</Typography>
    </Grid>,
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/atc-kolkota.png' alt='aiims1' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>ATC Tower, Kolkata Airport</Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Kolkata, West Bengal</Typography>
    </Grid>,
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/center-vista.png' alt='aiims1' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Central Vista, Common Central Secretariat</Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>New Delhi</Typography>
    </Grid>,
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/esic-bihta.png' alt='aiims1' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>ESIC Medical College & Hospital, Bihta</Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Patna, Bihar</Typography>
    </Grid>,
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/hq27-bharti.png' alt='aiims1' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>HQ27 Bharti Reality</Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Gurugram, Haryana</Typography>
    </Grid>,
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/iit-bhilai.png' alt='aiims1' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>IIT, Bhilai</Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Kutelabhata, Chhattisgarh</Typography>
    </Grid>,
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/mopa-goa.png' alt='aiims1' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Mopa International Airport, Goa </Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>MOPA, North Goa</Typography>
    </Grid>,
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/nalanda-rajgir.png' alt='aiims1' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Nalanda University, Rajgir </Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Rajgir, Bihar</Typography>
    </Grid>,
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/sky-mansion.png' alt='aiims1' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Sky Mansion, New Delhi </Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Chattarpur, New Delhi</Typography>
    </Grid>,
    <Grid item xs={12} sx={{width:'90% !important'}}>
        <img src='/assets/images/home_image/ssb-warangal.png' alt='aiims1' style={{marginBottom:'2em'}} />
        <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>SSB Hospital, Warangal</Typography>
        <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Warangal, Telengana</Typography>
    </Grid>
]

return (
    <Grid item sx={{width:'100%', display:'flex', justifyContent:'center'}}>
        <Grid item className='footprint' sx={{width:'100%',maxWidth:'100%', backgroundColor:"#dbfad8"}}>
            <Grid item className='footprint-div' sx={{maxWidth:1440, rowGap:'1em'}}>
                <Grid item xs={12} sm={4} md={5} lg={4} xl={4} sx={{mr:!isMobile && 2}}>
                    <Typography variant='h3' component={'h3'} className='titleStyle title-color' sx={{pl:1}}>
                            {title}</Typography>
                    <Typography variant='bod2' component={'p'} className={'text-color'} 
                        sx={{pl:1, fontFamily:'Inter',lineHeight:'1.8em', mt:1, mb:2, overflowWrap:true, textOverflow:'ellipsis', overflow:'hidden'}}>
                        {content}
                    </Typography>
                    <Button variant='text' className='btn' disableFocusRipple disableRipple onClick={handleNavigate}>
                            <Typography variant='bod2' component={'p'} className={'normal-text'} 
                            sx={{textTransform:'capitalize', fontFamily:'Inter',lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                            view all
                        </Typography>
                            <img className='right-icon' src='/assets/images/right-arrow.png' alt='right icon'  />
                        </Button>
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={8} xl={8}>
                    <Slider id='footprintSlider' ref={sliderRef} {...settings}>{imageArray && imageArray.length>0 ? getShuffledArr(imageArray) : getShuffledArr(slides)}</Slider>
                    
                    <Grid item className='scroll-btn' sx={{display:'flex', alignItems:'center', marginLeft: isMobile && '-8px !important'}}>
                        <IconButton onClick={slidePrev}>
                            <img src='/assets/images/right-arrow.png' alt='right icon' style={{transform:'rotate(180deg)'}} />
                        </IconButton>
                        <Typography className='txt normal-text' sx={{fontSize:'2em', fontWeight:600, color:'#4F9A41 !important'}}>/</Typography>
                        <IconButton onClick={slideNext}><img src='/assets/images/right-arrow.png' alt='right icon' /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    )

}

export default FootPrint;