import React, {useEffect} from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import Applyo from '../../components/career/Applyonline';

const ApplyOnline = () =>{

    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])


    return (
        <Grid container sx={{width:'100%'}}>
            <Banner img={'/assets/images/applyonline-banner.jpg'} title='' />
            <Applyo />
        </Grid>
    )
}

export default ApplyOnline;